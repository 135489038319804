import React, { useEffect } from "react";
import { Col, Form, Radio, Row } from "antd";

const FormRadio = props => {
  const currentPlatform = localStorage.getItem("platform");

  useEffect(() => {
    if (props.storeValue === undefined && props.value !== undefined && props.updateValue)
      props.updateValue(props.id, props.value);
  }, [props.value]);

  return (
    <Col span={props.rowColsLength === 3 ? 8 : props.rowColsLength === 2 ? 12 : 24}>
      <Row align="middle" justify="space-around" wrap={false} gutter={8}>
        <Col flex="auto">
          <p>{props.label}</p>
        </Col>
        <Col flex="none">
          <Radio.Group
            disabled={props.disabled}
            value={props.storeValue !== undefined ? props.storeValue : props.value}
            onChange={e => props.updateValue && props.updateValue(props.id, e.target.value)}
            block
            key={props.id}
            optionType="button"
            buttonStyle="solid"
            options={props.items}
          />
        </Col>
      </Row>
    </Col>
  );
};

export default FormRadio;
