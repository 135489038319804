import React, { useEffect, useState } from "react";

import { Button, message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import _ from "lodash";
import GraphQlService from "../../../Common/GraphQlService";
import { withTranslation } from "react-i18next";
import DxfEditorStore from "./../../../Other/DxfEditor/DxfEditorStore";
import DxfEditor from "../../../Other/DxfEditor/DxfEditor";

const CollectFormDxfEditor = props => {
  const [currentFile, setCurrentFile] = useState(undefined);
  const [dxfEditorStoreInstance, setDxfEditorStoreInstance] = useState(undefined);

  useEffect(() => {
    setDxfEditorStoreInstance(new DxfEditorStore());
    if (props.values && props.values.value) loadFile(props.values.value);
  }, []);

  const custom = async e => {
    if (!props || (!props.canUpload && props.canUpload === false)) return;

    const graphQlService = new GraphQlService();
    graphQlService
      .post(
        `mutation mutate($file: String, $filename: String, $id: Guid){ formSubmissionUploadFile(file: $file, filename: $filename, id: $id) }`,
        {
          file: await toBase64(e.file),
          filename: e.file.name,
          id: props.submissionId
        }
      )
      .then(r => {
        e.onSuccess(r.data.formSubmissionUploadFile);
        message.success(`${e.file.name} ${props.t("uploaded")}`);

        props.updateValue(props.id, r.data.formSubmissionUploadFile);
        props.save();

        loadFile(r.data.formSubmissionUploadFile);
      });
  };

  const loadFile = url => {
    fetch(`${localStorage.getItem(`host`)}/api/files?path=${url}`, { crossDomain: true })
      .then(res => res.text()) // Gets the response and returns it as a blob
      .then(text => setCurrentFile(text));
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  return (
    <>
      <label>{props.label}</label>
      <br />
      {!currentFile && (
        <Upload
          {...{
            name: "file",
            multiple: false,
            customRequest: custom,
            disabled: props.disabled
          }}
        >
          <Button htmlType="button" icon={<UploadOutlined />} disabled={props.disabled}>
            {props.t("collect.clicktoUpload")}
          </Button>
        </Upload>
      )}
      {currentFile && (
        <div style={{ minHeight: 300 }}>
          <DxfEditor store={dxfEditorStoreInstance} fileContent={currentFile} />
        </div>
      )}
    </>
  );
};

export default withTranslation()(CollectFormDxfEditor);
