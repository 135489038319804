import React, { useEffect } from "react";
import { Checkbox, Form } from "antd";

const FormCheckboxGroup = props => {
  useEffect(() => {}, []);

  useEffect(() => {
    if (props.storeValue === undefined && Array.isArray(props.value) && props.updateValue)
      props.updateValue(props.id, props.value);
  }, [props.value]);

  return (
    <Form.Item label={props.label} style={{ margin: "15px 0px" }} name={props.label}>
      <Checkbox.Group
        disabled={props.disabled}
        value={props.storeValue !== undefined ? props.storeValue : props.value}
        key={props.id}
        options={props.items ? props.items : []}
        onChange={e => {
          props.updateValue && props.updateValue(props.id, e);
        }}
      />
    </Form.Item>
  );
};

export default FormCheckboxGroup;
