import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Form, Input, Popconfirm, Radio, Row, Select, Table } from "antd";
import moment from "moment";
import { observer } from "mobx-react";
import FormBuilderStore from "../../MyForms/FormBuilderStore";
import { DeleteOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import CollectSubmissionStore from "../../Submissions/FormSubmissionStore";
import { withTranslation } from "react-i18next";

const FormSign = props => {
  useEffect(() => {
    if (props.disableInputs && props.value)
      CollectSubmissionStore.disabledInputs = [
        ...CollectSubmissionStore.disabledInputs,
        ...props.disableInputs.map(di => ({ fieldId: di.fieldId, parentSignId: props.id }))
      ];
  }, []);

  useEffect(() => {
    if (props.storeValue === undefined && props.value !== undefined && props.updateValue)
      props.updateValue(props.id, props.value);

    if (props && props.autoUnsignFields && props.store)
      props.store.autoUnassignFields.push({ id: props.id, fields: props.autoUnsignFields.map(x => x.fieldId) });

    setTimeout(() => setCanvasValue(), 500);
  }, [props.value]);

  const setCanvasValue = () => {
    if (!props.value) return;

    let canvas = document.getElementById("sig_" + props.id);
    if (!canvas) {
      setTimeout(() => setCanvasValue(), 500);
      return;
    }

    var value = JSON.parse(props.value || "{}");
    var ctx = canvas.getContext("2d");

    var fontsize = 38;
    do {
      fontsize--;
      ctx.font = fontsize + "px 'Caveat'";
    } while (ctx.measureText(value.user).width > canvas.width);

    ctx.textAlign = "center"; // center text
    ctx.fillText(value.user, canvas.width / 2, canvas.height / 2, canvas.width);

    // Date conditional
    ctx.font = "9px arial";
    ctx.fillText(value.date, canvas.width / 2, canvas.height / 1.6, canvas.width);
  };

  const sign = () => {
    if (props.updateValue) {
      props.updateValue(
        props.id,
        JSON.stringify({
          user: localStorage.getItem(`username`),
          date: moment().format(props.dateFormat ? props.dateFormat : "YYYY-MM-DD HH:mm")
        })
      );
      setCanvasValue();

      if (props.disableInputs)
        CollectSubmissionStore.disabledInputs = [
          ...CollectSubmissionStore.disabledInputs,
          ...props.disableInputs.map(di => ({ fieldId: di.fieldId, parentSignId: props.id }))
        ];
    }
  };

  const unsign = () => {
    if (props.updateValue) {
      props.updateValue(props.id, undefined);
      if (props.onUnSign) props.onUnSign();

      if (props.disableInputs)
        CollectSubmissionStore.disabledInputs = CollectSubmissionStore.disabledInputs.filter(
          x => x.parentSignId !== props.id
        );
    }
  };

  if (!props.value) {
    return (
      <Form.Item label={props.label} style={{ margin: "15px 0px" }}>
        <Button type="default" disabled={props.disabled} onClick={() => sign()}>
          {props.buttonName}
        </Button>
      </Form.Item>
    );
  } else {
    return (
      <Form.Item label={props.label} style={{ margin: "15px 0px" }}>
        <Popconfirm
          title={props.t("collect.annotationTitle")}
          description={props.t("collect.annotation")}
          onConfirm={() => unsign()}
        >
          <canvas id={"sig_" + props.id} style={{ border: "1px solid #efefef" }} />
        </Popconfirm>
      </Form.Item>
    );
  }
};

export const CollectFormSignSettings = observer(props => {
  const inputStyle = { marginLeft: 5, width: "90%" };

  const [newFieldSelected, setNewFieldSelected] = useState({ fieldId: undefined });
  const [allFields, setAllFields] = useState([]);

  useEffect(() => {
    setAllFields(FormBuilderStore.getAllFieldsForDisable());
  }, []);

  return (
    <>
      <Row gutter={4} style={{ marginTop: 5 }}>
        <Col span={6} style={{ textAlign: "right" }}>
          {props.t("collect.buttonName")}
        </Col>
        <Col span={18}>
          <Input
            size="small"
            style={inputStyle}
            value={FormBuilderStore.modifyInputColumn.buttonName}
            onChange={e => props.setFieldValue("buttonName", e.target.value)}
          />
        </Col>
      </Row>
      <Row gutter={4} style={{ marginTop: 5 }}>
        <Col span={6} style={{ textAlign: "right" }}>
          {props.t("collect.dateFormat")}
        </Col>
        <Col span={18}>
          <Input
            defaultValue={"YYYY-MM-DD HH:mm"}
            size="small"
            style={inputStyle}
            value={FormBuilderStore.modifyInputColumn.dateFormat}
            onChange={e => props.setFieldValue("dateFormat", e.target.value)}
          />
        </Col>
      </Row>

      <Divider orientation="left">Fields to disable</Divider>
      <Table
        dataSource={FormBuilderStore.modifyInputColumn.disableInputs || []}
        size="small"
        columns={[
          {
            title: "Field",
            key: "field",
            dataIndex: "fieldId",
            render: (v, record) => allFields.find(x => x.value === v)?.label
          },
          {
            align: "right",
            title: "",
            key: "actions",
            render: (v, record) => [
              <DeleteOutlined
                onClick={() =>
                  (FormBuilderStore.modifyInputColumn.disableInputs = [
                    ...FormBuilderStore.modifyInputColumn.disableInputs.filter(x => x.id !== record.id)
                  ])
                }
              />
            ]
          }
        ]}
        footer={() => (
          <>
            <Row>
              <Col span={18}>
                <Select
                  style={{ width: "100%" }}
                  onChange={e => setNewFieldSelected(v => ({ ...v, fieldId: e }))}
                  options={allFields}
                />
              </Col>
              <Col span={6}>
                <Button
                  type="default"
                  onClick={() =>
                    (FormBuilderStore.modifyInputColumn.disableInputs = [
                      ...(FormBuilderStore.modifyInputColumn.disableInputs || []),
                      { id: uuidv4(), fieldId: newFieldSelected.fieldId }
                    ])
                  }
                >
                  Add field
                </Button>
              </Col>
            </Row>
          </>
        )}
      />

      <Divider orientation="left">Auto unsign fields</Divider>
      <Table
        dataSource={FormBuilderStore.modifyInputColumn.autoUnsignFields || []}
        size="small"
        columns={[
          {
            title: "Field",
            key: "field",
            dataIndex: "fieldId",
            render: (v, record) => allFields.find(x => x.value === v)?.label
          },
          {
            align: "right",
            title: "",
            key: "actions",
            render: (v, record) => [
              <DeleteOutlined
                onClick={() =>
                  (FormBuilderStore.modifyInputColumn.autoUnsignFields = [
                    ...FormBuilderStore.modifyInputColumn.autoUnsignFields.filter(x => x.id !== record.id)
                  ])
                }
              />
            ]
          }
        ]}
        footer={() => (
          <>
            <Row>
              <Col span={18}>
                <Select
                  style={{ width: "100%" }}
                  onChange={e => setNewFieldSelected(v => ({ ...v, fieldId: e }))}
                  options={allFields}
                />
              </Col>
              <Col span={6}>
                <Button
                  type="default"
                  onClick={() =>
                    (FormBuilderStore.modifyInputColumn.autoUnsignFields = [
                      ...(FormBuilderStore.modifyInputColumn.autoUnsignFields || []),
                      { id: uuidv4(), fieldId: newFieldSelected.fieldId }
                    ])
                  }
                >
                  Add field
                </Button>
              </Col>
            </Row>
          </>
        )}
      />
    </>
  );
});

export default withTranslation()(FormSign);
