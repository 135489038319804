import React from "react";

const FormText = (props) => {
  return (
    <p style={{ textAlign: "left" }}>
      <h4
        style={{
          textTransform: "uppercase",
          fontSize: "0.8em",
          color: "#90a8be",
          fontWeight: "bolder",
          textAlign: "left",
        }}
      >
        {props.label}
      </h4>
      {props.storeValue ? props.storeValue : props.value}
    </p>
  );
};

export default FormText;
