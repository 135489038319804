import React, { useEffect, useState } from "react";

import { LeftOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Card, Divider, FloatButton, Form, message, Select, Spin } from "antd";
import { Button as ButtonMobile, Form as FormMobile, SafeArea } from "antd-mobile";
import CollectSubmissionListStore from "../list/CollectSubmissionListStore";
import { Col, Row } from "antd/lib/grid";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { Link, Prompt } from "react-router-dom";
import {
  default as CollectSubmissionStore,
  CollectSubmissionStore as FormGeneratorStoreLocal,
  default as FormSubmissionStore
} from "../FormSubmissionStore";
import {
  default as CollectSubmissionCommonStore,
  default as FormSubmissionCommonStore
} from "./FormSubmissionCommonStore";
import FormSubmissionViewRenderStruct from "./FormSubmissionViewRenderStruct";

import { App } from "@capacitor/app";
import { isEmpty, orderBy } from "lodash";
import CustomNavigationBlocker from "./CustomNavigationBlocker";
const FormSubmissionView = props => {
  const [state, setState] = useState({
    templateId: "",
    storeLoaded: undefined,
    canModify: true,
    viewOnly: false,
    isError: false,
    dupes: []
  });
  const [folderId, setFolderId] = useState(undefined);
  const [store, setStore] = useState(undefined);
  const [form] = Form.useForm();

  const currentPlatform = localStorage.getItem("platform");
  useEffect(() => {
    load();

    return () => {
      App.removeAllListeners();
      if (FormSubmissionStore.autoSaveStatus > 0 && FormSubmissionStore.cameraIsOpen === false) handleSave(false);
    };
  }, []);

  useEffect(() => {
    if (store)
      FormSubmissionCommonStore.updatePdfAnnotatorSelectedClaimValue(FormSubmissionCommonStore.valueToUpdate, store);
  }, [CollectSubmissionCommonStore.valueToUpdate]);

  useEffect(() => {
    if (localStorage.getItem(`platform`) === "web") return;
    if (FormSubmissionStore.cameraIsOpen === true) return;

    App.removeAllListeners();
    if (store) {
      App.addListener("appStateChange", ({ isActive }) => {
        if (!isActive && FormSubmissionStore.autoSaveStatus > 0) FormSubmissionStore.autoSaveStatus = 100;
      });
    }
  }, [store]);

  useEffect(() => {
    if (FormSubmissionStore.cameraIsOpen === true) return;
    if (
      !CollectSubmissionCommonStore.saveClicked ||
      !store ||
      store.submissionId === CollectSubmissionCommonStore.saveClicked
    )
      return;
    handleSave();
  }, [CollectSubmissionCommonStore.saveClicked]);

  useEffect(() => {
    if (FormSubmissionStore.cameraIsOpen === true) return;
    if (FormSubmissionStore.autoSaveStatus === 100) handleSave(true);
  }, [FormSubmissionStore.autoSaveStatus]);

  const load = async () => {
    if (props.submissionId) {
      let store = new FormGeneratorStoreLocal();
      store.reset();
      await store.loadSubmission(props.submissionId);
      setStore(store);
    } else {
      CollectSubmissionCommonStore.saveClicked = undefined;
      let store = CollectSubmissionStore;

      store.reset();
      if (props.match.params.containerId === "new") {
        store.init().then(() => {
          const params = new URLSearchParams(props.location.search);
          const folderId = params.get("folderId");
          const goBackLocation = params.get("l");
          if (folderId != null) setFolderId(folderId);

          if (window.location.hash.includes("#p="))
            store.create(window.location.hash.replace("#p=", ""), props.history, folderId, goBackLocation);

          // NOTE: we should make this one work, it should follow the path to the next page when the template is chosen
          /*           const templatesCount = store?.templates.filter(t => !t.hubOnly).length;


          if (templatesCount === 1) {
            const e = store?.templates[0]?.id;
            const chosenTemplate = store.templates.find(x => x.id === e);
            setState(v => ({ ...v, template: chosenTemplate }));


            const fId = !!chosenTemplate.hubOnly ? "00000000-0000-0000-0000-000000000000" : folderId; // System folder id if the template should be hub only
            window.history.pushState(
              null,
              "",
              `${window.location.pathname}${folderId ? `?folderId=${fId}` : ""}#p=${e}`
            );
          } */
          store.isLoading = false;
        });
      } else await store.loadSubmission(props.match.params.containerId);
      setStore(store);
    }

    if (props.canModify !== undefined) setState(v => ({ ...v, canModify: props.canModify }));
    if (window.location.search.includes("?view=true") || props.viewOnly === true)
      setState(v => ({ ...v, viewOnly: true, canModify: false }));
  };

  const setDefaultValues = structure => {
    if (!structure.rows) return;

    structure.rows.forEach(row => {
      if (row.cols) {
        row.cols.forEach(col => {
          // = = = Handle collapse

          if (col.type === "collapse") {
            if (col.items)
              col.items.forEach(collapseItem => {
                if (collapseItem.structure) setDefaultValues(collapseItem.structure);
              });
          } else {
            // = = = Handle input
            if (col.value) {
              var existingValueSet = CollectSubmissionStore.values.find(x => x.id === col.id);
              if (!existingValueSet) FormSubmissionStore.updateValue(col.id, col.value);

              if (col.type === "select" && col.mode === null && Array.isArray(col.value) === true)
                FormSubmissionStore.updateValue(col.id, col.value[0]);
            }
          }
        });
      }
    });
  };

  const checkRequiredFields = (structure, canContinue) => {
    if (!structure.rows) return false;

    structure.rows.forEach(row => {
      if (row.cols) {
        row.cols.forEach(col => {
          // = = = Handle collapse
          if (col.type === "collapse") {
            if (col.items)
              col.items.forEach(collapseItem => {
                if (collapseItem.structure) canContinue = checkRequiredFields(collapseItem.structure, canContinue);
              });
          } else {
            // = = = Handle image annotator
            if (col.type === "image-annotator" && col.requireClaim === true) {
              var value = FormSubmissionStore.values.find(x => x.id === col.id);
              if (!value || isEmpty(value?.value) || isEmpty(value?.value.claims)) {
                canContinue = false;
                message.warning(`${props.t("assets.required")}: ${props.t("assets.requiredImageAnnotator")}`);
              }
            }

            // = = = Handle input
            if (col.isRequired) {
              var value = FormSubmissionStore.values.find(x => x.id === col.id);
              if (!value || isEmpty(value?.value.toString())) {
                canContinue = false;
                message.warning(`${props.t("assets.required")}: ${col?.label}`);
              }
            }
          }
        });
      }
    });
    return canContinue;
  };

  const handleSave = async (redirect = false) => {
    if (FormSubmissionStore.cameraIsOpen === true) return;

    CollectSubmissionCommonStore.saveClicked = store.submissionId;
    FormSubmissionStore.autoSaveStatus = 0;

    setDefaultValues(store.structure);
    const checkRequiredFieldsResponse = checkRequiredFields(store.structure, true);
    if (checkRequiredFieldsResponse === false) return;

    if (!store) return;

    const updateResponse = await store.update(redirect ? props.t("collect.submissionUpdated") : undefined);
    if (updateResponse === false) return;

    if (store.autoSaveTimer) clearTimeout(store.autoSaveTimer);
    store.resetNotSaved();

    if (redirect) {
      setTimeout(() => {
        const { history } = props;
        const params = new URLSearchParams(props.location.search);
        const goBackLocation = params.get("l");

        if (!!goBackLocation) {
          history.push(goBackLocation);
        } else {
          history.push("/collect/entries");
        }
      }, 1000);
    }
  };

  if (!store) return <Spin spinning={true} />;

  return (
    <>
      {/* {`not saved? ${FormSubmissionStore.notSaved}`} */}
      <CustomNavigationBlocker shouldBlockNavigation={FormSubmissionStore.notSaved} />
      <Row>
        <Col
          xs={24}
          sm={24}
          md={{
            span: currentPlatform === "mobile" ? 24 : props.isParent === true ? 24 : 20,
            offset: currentPlatform === "mobile" ? 0 : props.isParent === true ? 0 : 2
          }}
        >
          <Spin spinning={store.isLoading}>
            {!store.submissionId && currentPlatform !== "mobile" && (
              <Card size="small" title={props.t("collect.selectTemplate")}>
                <Form.Item style={{ margin: 20 }}>
                  <Select
                    value={state?.template?.id}
                    onChange={e => {
                      const chosenTemplate = store.templates.find(x => x.id === e);
                      setState(v => ({ ...v, template: chosenTemplate }));

                      const fId = !!chosenTemplate.hubOnly ? "00000000-0000-0000-0000-000000000000" : folderId; // System folder id if the template should be hub only
                      window.history.pushState(
                        null,
                        "",
                        `${window.location.pathname}${folderId ? `?folderId=${fId}` : ""}#p=${e}`
                      );
                    }}
                  >
                    {orderBy(store.templates, "name")
                      .filter(t => !t.hubOnly)
                      .map(template => (
                        <Select.Option value={template.id} key={template.id}>
                          {template.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={!state?.template?.id}
                    className="pendo-addentry-guide"
                    onClick={() => {
                      CollectSubmissionStore.setTemplate(state.template);

                      store.create(
                        state.template.id,
                        props.history,
                        state.template.hubOnly ? "00000000-0000-0000-0000-000000000000" : folderId
                      );
                    }}
                    style={{ margin: "10px 20px 0px 20px" }}
                  >
                    {props.t("collect.add")}
                  </Button>
                </Form.Item>
              </Card>
            )}
            {!store.submissionId && currentPlatform === "mobile" && (
              <FormMobile className="mobile-entries" layout="vertical">
                <FormMobile.Header style={{ "--header-font-size": 100 }}>
                  <SafeArea position="top" />
                  <Link to={"/collect/entries"} style={{ color: "#000" }}>
                    <Button type="text" size="small">
                      <LeftOutlined />
                    </Button>
                  </Link>
                  <span
                    style={{
                      color: "#000",
                      textTransform: "uppercase",
                      fontWeight: 400,
                      fontSize: "16px",
                      marginTop: 4
                    }}
                  >
                    {props.t("collect.selectForm")}
                  </span>
                </FormMobile.Header>
                {orderBy(store.templates, "name").map(template => (
                  <Card
                    size="small"
                    key={template.id}
                    onClick={() => store.create(template.id, props.history, folderId)}
                    style={{ marginTop: 5 }}
                  >
                    {template.name}
                  </Card>
                ))}
              </FormMobile>
            )}
            {store.submissionId && store.structure && (
              <div style={{ margin: 10 }}>
                {/*                 {currentPlatform === "web" && (
                  <h3 style={{ textAlign: "left" }} className="page-title">
                    {props.t("collect.submission")} {store.template.name}
                  </h3>
                )} */}
                {currentPlatform !== "mobile" && (
                  <Card style={{ padding: 12 }}>
                    {store.values && (
                      <Form
                        form={form}
                        layout="vertical"
                        // onValuesChange={(c) => {
                        //   console.log('value changed', c);
                        //   handleFormChange();
                        // }}
                        onFormFinish={() => {
                          console.log("on form finish");
                          FormSubmissionStore.notSaved = false;
                        }}
                      >
                        <FormSubmissionViewRenderStruct store={store} structure={store.structure} state={state} />
                      </Form>
                    )}
                  </Card>
                )}
                {currentPlatform === "mobile" && (
                  <>
                    {store.values && !props.isParent && (
                      <FormMobile
                        className="mobile-entries"
                        layout="vertical"
                        // onFieldsChange={(changedFields, allFields) => console.log('onFieldsChange', changedFields, allFields)}
                        // onValuesChange={changedValue => {
                        //   console.log('values changed', changedValue)
                        //   handleFormChange();
                        //   store.updateValue(Object.keys(changedValue)[0], Object.values(changedValue)[0]);
                        // }}
                      >
                        <FormMobile.Header style={{ "--header-font-size": 100 }}>
                          <SafeArea position="top" />
                          <Link to={"/collect/entries"} style={{ color: "#000" }}>
                            <Button type="text" size="small">
                              <LeftOutlined />
                            </Button>
                          </Link>
                          <span
                            style={{
                              color: "#000",
                              textTransform: "uppercase",
                              fontWeight: 400,
                              fontSize: "16px",
                              marginTop: 4
                            }}
                          >
                            {store.template.name}
                          </span>
                          <Divider />
                        </FormMobile.Header>
                        <FormSubmissionViewRenderStruct store={store} structure={store.structure} state={state} />
                      </FormMobile>
                    )}

                    {store.values && props.isParent && (
                      <FormSubmissionViewRenderStruct store={store} structure={store.structure} state={state} />
                    )}

                    {state.viewOnly === false && (
                      <>
                        {state.isError && (
                          <h4 style={{ color: "#ec547a", marginBottom: 20 }}>{props.t("collect.identifierWarning")}</h4>
                        )}
                        {currentPlatform === "mobile" && !props.isParent && (
                          <div
                            style={{
                              margin: "30px 0px",
                              position: "fixed",
                              bottom: 0,
                              left: 0,
                              right: 0,
                              margin: 0,
                              paddingRight: 10,
                              paddingLeft: 10,
                              paddingBottom: 10,
                              paddingTop: 30,
                              width: "100%",
                              backgroundColor: "red",
                              zIndex: 1001,
                              background:
                                "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0px, rgba(255, 255, 255, 1) 30px)"
                            }}
                          >
                            <ButtonMobile
                              block
                              disabled={!store.validityStatus}
                              color="primary"
                              loading={FormSubmissionStore.isLoading}
                              fill="solid"
                              onClick={async () => {
                                handleSave(true);
                              }}
                            >
                              {props.t("collect.save")}
                            </ButtonMobile>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </Spin>
        </Col>
      </Row>

      {store.submissionId &&
        store.structure &&
        state.viewOnly === false &&
        currentPlatform !== "mobile" &&
        !props.isParent && (
          <FloatButton.Group
            shape="square"
            style={{
              right: 60
            }}
          >
            <FloatButton
              icon={<SaveOutlined />}
              onClick={() => handleSave(true)}
              tooltip={`${props.t("collect.save")}`}
              loading={FormSubmissionStore.isLoading}
              disabled={store.saveDisabled()}
            />
            <FloatButton.BackTop
              visibilityHeight={0}
              onClick={() => {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
              }}
            />
          </FloatButton.Group>
        )}
    </>
  );
};

export default withTranslation()(observer(FormSubmissionView));
