import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { computed } from "mobx";
import { useRouteMatch } from "react-router-dom";

import BusinessProcessManagementStore from "../../../Enable/BusinessProcessManagement/BusinessProcessManagementStore";
import RiskAssessmentManagementStore from "../../../Enable/RiskAssessmentManagement/RiskAssessmentManagementStore";
import Store from "../../../Automate/Automate/Workflows/Store";
import FormBuilderStore from "../../../Collect/MyForms/FormBuilderStore";
import AutomateWorkflowTemplateStore from "../../../Automate/Automate/Templates/AutomateWorkflowTemplateStore";
import CollectSubmissionStore from "../../../Collect/Submissions/FormSubmissionStore";
import CollectSubmissionListStore from "../../../Collect/Submissions/list/CollectSubmissionListStore";
import AppHeader from "../organism/AppHeader";
import SubHeader from "../organism/SubHeader";
import useCurrentRoute from "../hooks/useCurrentRoute";
import { getStoreTitle } from "../constant";

const BaseLayoutHeader = ({ urlParsed, t, user, switchTheme, toggleShowSearchDrawer, urlQuery, pageTitle }) => {
  const [currentNameTitle, setCurrentNameTitle] = useState(null);
  const match = useRouteMatch();
  const { path } = match;
  const currentRoute = useCurrentRoute(path);

  useEffect(() => {
    if (currentRoute?.handle?.store) {
      const storeKey = currentRoute.handle.store;
      const title = getStoreTitle(storeKey);
      if (title) setCurrentNameTitle(title);
    }
  }, [
    currentRoute,
    Store.isLoading,
    Store.workflow?.name,
    CollectSubmissionStore.isLoading,
    CollectSubmissionStore.template?.name,
    AutomateWorkflowTemplateStore.isLoading,
    AutomateWorkflowTemplateStore.workflow?.name,
    FormBuilderStore?.name,
    FormBuilderStore.isLoading,
    RiskAssessmentManagementStore.isLoading,
    BusinessProcessManagementStore.isLoading,
    CollectSubmissionListStore.isLoading
  ]);

  return (
    <>
      <AppHeader
        toggleShowSearchDrawer={toggleShowSearchDrawer}
        urlQuery={urlQuery}
        user={user}
        pageTitle={pageTitle}
        switchTheme={switchTheme}
        urlParsed={urlParsed}
      />
      <SubHeader pageTitle={pageTitle} currentNameTitle={currentNameTitle || t(pageTitle)} />
    </>
  );
};

export default withTranslation()(observer(BaseLayoutHeader));
