import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import SearchComponent from "../../Common/Search/SearchComponent";
import ViewGeneratorStore from "../ViewGeneratorStore";

const ViewGeneratorSearch = ({ t, urlQuery, history, page, variant, customSearchByJql }) => {
  if (!ViewGeneratorStore.hubConfig) return;
  return (
    <SearchComponent
      // showSavedSearchesFromPages={ViewGeneratorStore.hubConfig.}
      history={history}
      urlQuery={urlQuery}
      page={page}
      // jqlFields={ViewGeneratorStore.assetDefinition.fields}
      fields={ViewGeneratorStore.assetDefinition.fields}
      loadDefault={() => {
        ViewGeneratorStore.basicSearchValue = undefined;
        ViewGeneratorStore.advanceSearchDrawer.jql = undefined;
        ViewGeneratorStore.tableConfig.page = 1;
        ViewGeneratorStore.loadData();
      }}
      searchByValue={e => {
        ViewGeneratorStore.basicSearchValue = e;
        ViewGeneratorStore.tableConfig.page = 1;
        ViewGeneratorStore.loadData();
      }}
      searchByJql={() => ViewGeneratorStore.loadData()}
      jqlQueryUpdated={v => {
        ViewGeneratorStore.tableConfig.page = 1;
        ViewGeneratorStore.advanceSearchDrawer.jql = v;
      }}
      variant={variant}
      toggleShowSearchDrawer={() =>
        (ViewGeneratorStore.advanceSearchDrawer = { ...ViewGeneratorStore.advanceSearchDrawer, isVisible: false })
      }
    />
  );
};

export default withTranslation()(observer(ViewGeneratorSearch));
