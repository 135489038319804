import { makeAutoObservable } from "mobx";

export class CollectSubmissionCommonStore {
  valueToUpdate = undefined;
  mapUpdatePoints = undefined;

  saveClicked = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  updatePdfAnnotatorSelectedClaimValue = (newValue, store, structure) => {
    if (!structure) structure = store.structure;

    structure.rows.forEach(row => {
      row.cols.forEach(col => {
        if (col.type === "pdf-annotator-selected-claim")
          store.updateValue(col.id, newValue === "unclaim" ? undefined : newValue);

        if (col.type === "collapse" && col.items) {
          col.items.forEach(item => {
            if (item.structure) this.updatePdfAnnotatorSelectedClaimValue(newValue, store, item.structure);
          });
        }
      });
    });
  };
}

export default new CollectSubmissionCommonStore();
