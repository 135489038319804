import React from "react";
export default {
  //conditions
  NoraChat: React.lazy(() => import("./Common/Editors/Nora/NoraChat")),
  CompareAssetsPage: React.lazy(() => import("./Common/CompareAssets/CompareAssetsPage")),
  GetSide: React.lazy(() => import("./Common/Layout/molecules/GetSide")),
  LoginProcore: React.lazy(() => import("./Auth/LoginProcore")),
  AdvancedSearchButton: React.lazy(() => import("./Common/Layout/molecules/AdvancedSearchButton")),
  TasksSearch: React.lazy(() => import("./WorflowRiskControl/Tasks/components/TasksSearch")),
  DocumentsSearch: React.lazy(() => import("./WorflowRiskControl/Documents/components/DocumentsSearch")),
  CollectSubmissionListDrawer: React.lazy(() => import("./Collect/Submissions/list/CollectSubmissionListDrawer")),
  FormBuilderListDrawer: React.lazy(() => import("./Collect/MyForms/list/FormBuilderListDrawer")),
  FormBuilderTemplateDrawer: React.lazy(() => import("./Collect/MyForms/form/FormBuilderTemplateDrawer")),
  AssetMatching: React.lazy(() => import("./Common/Layout/molecules/AssetMatching")),
  ProcessMatching: React.lazy(() => import("./Common/Layout/molecules/ProcessMatching")),
  NotificationsHeaderSide: React.lazy(() => import("./Common/Notifications/NotificationsHeaderSide")),
  ProgramDrawer: React.lazy(() => import("./WorflowRiskControl/Tasks/ProgramDrawer")),
  DocumentsListDrawer: React.lazy(() => import("./WorflowRiskControl/Documents/DocumentsListDrawer")),
  // Pages - main
  AssetsPage: React.lazy(() => import("./Pages/Assets/AssetsPage")),
  AssetsAnalysisPage: React.lazy(() => import("./Pages/AssetsAnalysis/AssetsAnalysisPage")),
  HomePage: React.lazy(() => import("./Home/HomePage")),

  // Analyze
  AnalyzeDigitalTwins: React.lazy(() => import("./Analyze/DigitalTwins/AnalyzeDigitalTwins")),
  DashboardsPage: React.lazy(() => import("./Analyze/Dashboards/DashboardsPage")),
  // DashboardsPage: DashboardsPage,
  SubcontractorsPage: React.lazy(() => import("./Analyze/Subcontractors/SubcontractorsPage")),

  // Workflow Risk Control
  DocumentsPage: React.lazy(() => import("./WorflowRiskControl/Documents/DocumentsPage")),
  ConsentPage: React.lazy(() => import("./WorflowRiskControl/Consents/ConsentPage")),
  TasksPage: React.lazy(() => import("./WorflowRiskControl/Tasks/TasksPage")),
  CommentList: React.lazy(() => import("./WorflowRiskControl/Comments/CommentList")),

  // Enable
  BusinessProcessManagementListPage: React.lazy(() =>
    import("./Enable/BusinessProcessManagement/List/BusinessProcessManagementListPage")
  ),
  BusinessProcessManagementPage: React.lazy(() =>
    import("./Enable/BusinessProcessManagement/View/BusinessProcessManagementPage")
  ),
  BusinessProcessLessonLearnPage: React.lazy(() =>
    import("./Enable/BusinessProcessManagement/View/BusinessProcessLessonLearnPage")
  ),
  LessonLearnList: React.lazy(() => import("./Enable/BusinessProcessManagement/LessonLearn/List/LessonLearnList")),
  LessonLearnPage: React.lazy(() => import("./Enable/BusinessProcessManagement/LessonLearn/LessonLearnPage")),
  EnableCorporateRepository: React.lazy(() =>
    import("./Enable/BusinessProcessManagement/CorporateRepository/CorporateRepository")
  ),
  EnableCorporateRepositoryPreview: React.lazy(() =>
    import("./Enable/BusinessProcessManagement/CorporateRepository/CorporateRepositoryPreview")
  ),

  // Risk Assessment Management
  RiskAssessmentManagementListPage: React.lazy(() =>
    import("./Enable/RiskAssessmentManagement/List/RiskAssessmentManagementListPage")
  ),
  RiskAssessmentManagementPage: React.lazy(() =>
    import("./Enable/RiskAssessmentManagement/View/RiskAssessmentManagementPage")
  ),

  // Collect
  FormBuilderList: React.lazy(() => import("./Collect/MyForms/list/FormBuilderList")),
  FormBuilder: React.lazy(() => import("./Collect/MyForms/form/FormBuilder")),
  FormSubmissionList: React.lazy(() => import("./Collect/Submissions/list/FormSubmissionList")),
  CollectSubmissionsList: React.lazy(() => import("./Collect/Submissions/list-v2/CollectSubmissionsList")),
  CollectSubmissionHubView: React.lazy(() => import("./Collect/Submissions/view-v2/CollectSubmissionHubView")),
  FormSubmissionView: React.lazy(() => import("./Collect/Submissions/view/FormSubmissionView")),
  CollectAnalytics: React.lazy(() => import("./Collect/Analytics/CollectAnalytics")),

  // Automate
  AutomateWorkflowsList: React.lazy(() => import("./Automate/Automate/Workflows/List")),
  AutomateWorkflowsExecutionsList: React.lazy(() =>
    import("./Automate/Automate/WorkflowsExecutions/AutomateExecutionsList")
  ),
  AutomateWorkflowsView: React.lazy(() => import("./Automate/Automate/Workflows/View")),
  AutomateWorkflowTemplateList: React.lazy(() => import("./Automate/Automate/Templates/AutomateWorkflowTemplateList")),
  AutomateWorkflowTemplateView: React.lazy(() => import("./Automate/Automate/Templates/AutomateWorkflowTemplateView")),
  ExtractDocumentTree: React.lazy(() => import("./Automate/Automate/ExtractDocumentTree")),

  // Settings
  AssetsDefinitionsList: React.lazy(() => import("./Settings/AssetsDefinition/AssetsDefinitionList")),
  EvidenceManagerList: React.lazy(() => import("./Settings/EvidenceManager/List")),
  ProjectList: React.lazy(() => import("./Settings/Projects/List")),
  ProjectView: React.lazy(() => import("./Settings/Projects/ProjectView")),
  TenantsList: React.lazy(() => import("./Settings/Tenants/List")),
  TenantsView: React.lazy(() => import("./Settings/Tenants/View")),

  // Auth
  Login: React.lazy(() => import("./Auth/Login")),
  Register: React.lazy(() => import("./Auth/Register")),
  ResetPassword: React.lazy(() => import("./Auth/ResetPassword")),
  Activate: React.lazy(() => import("./Auth/Activate")),
  LoginProcore: React.lazy(() => import("./Auth/LoginProcore")),

  // Utilities
  InitDemo: React.lazy(() => import("./Other/BpmnSvgGenerator/InitDemo")),
  BowtieSvgGenerator: React.lazy(() => import("./Other/BowtieSvgGenerator/BowtieSvgGenerator")),
  QueryBuilderDev: React.lazy(() => import("./Other/QueryBuilderDev")),
  DocumentScannerComponent: React.lazy(() => import("./Scanner/DocumentScannerComponent")),
  DxfEditor: React.lazy(() => import("./Other/DxfEditor/DxfEditor")),
  CompareAssetsPage: React.lazy(() => import("./Common/CompareAssets/CompareAssetsPage")),

  // Common - Inne komponenty wspólne
  NotificationComponent: React.lazy(() => import("./Common/Notifications/NotificationComponent")),
  PdfAnnotator: React.lazy(() => import("./Collect/common/pdf-annotator/PdfAnnotator")),
  ImageAnnotatorComponent: React.lazy(() => import("./Common/ImageAnnotator/ImageAnnotatorComponent")),
  ViewBuilder: React.lazy(() => import("./ViewBuilder/ViewBuilder")),
  ViewGenerator: React.lazy(() => import("./ViewGenerator/ViewGenerator")),
  EmptyPage: React.lazy(() => import("./EmptyPage"))
};
