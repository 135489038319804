import React from "react";
import { observer } from "mobx-react";

import _ from "lodash";
import { Popover, Row, Button, Drawer, Divider } from "antd";
import {
  PlusOutlined,
  SettingOutlined,
  ClearOutlined,
  DeleteOutlined,
  EditOutlined,
  InsertRowBelowOutlined,
  InsertRowLeftOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined
} from "@ant-design/icons";
import ButtonGroup from "antd/lib/button/button-group";
import FormBuilderStore from "../../FormBuilderStore";
import FormBuilderColumn from "../cols/FormBuillderColumn";

import { withTranslation } from "react-i18next";
import BookmarksStore from "../../../../Common/Bookmarks/BookmarksStore";
import { themeConfig } from "../../../../Common/Layout/config";

const ColsButtons = observer(({ col, row, rowIndex, colIndex, store, t, onCloseEditDrawer, currentTheme }) => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    <h4 style={{ marginBottom: 5, color: currentTheme === "dark" && "white" }}>
      {`${t("collect.column")} ${colIndex + 1}`} - {col.label}
    </h4>
    <Button.Group style={{ marginBottom: 20 }}>
      <Button
        type="primary"
        size="small"
        icon={<SettingOutlined />}
        disabled={col.type ? false : true}
        onClick={() => {
          store.modifyInputColumn = col;
          onCloseEditDrawer();
        }}
      >
        {t("collect.editColumn")}
      </Button>
      <Button
        type="primary"
        size="small"
        icon={<ClearOutlined />}
        disabled={col.type ? false : true}
        onClick={() => store.clearInput(col.parentId, rowIndex, colIndex)}
      >
        {t("collect.clearInput")}
      </Button>
      <Button
        type="primary"
        size="small"
        style={{ borderRadius: "0px 3px 3px 0px" }}
        icon={<DeleteOutlined />}
        disabled={row.cols.length <= 1}
        onClick={() => store.removeColumn(col.parentId, rowIndex, colIndex)}
        danger
      >
        {t("collect.removeColumn")}
      </Button>
      <br />
    </Button.Group>
  </div>
));

class FormBuilderRow extends React.Component {
  store = FormBuilderStore;
  currentPlatform = localStorage.getItem("platform");
  currentTheme = themeConfig().currentTheme;

  state = {
    visibleEditRowDrawer: false
  };

  renderColumns() {
    let cols = [];
    _.forEach(this.props.row.cols, (col, colIndex) => {
      cols.push(<FormBuilderColumn key={colIndex} col={col} colIndex={colIndex} rowIndex={this.props.rowIndex} />);
    });
    return cols;
  }

  renderColumnsButtons() {
    let colBtns = [];
    _.forEach(this.props.row.cols, (col, colIndex) => {
      colBtns.push(
        <ColsButtons
          key={colIndex}
          col={col}
          row={this.props.row}
          colIndex={colIndex}
          rowIndex={this.props.rowIndex}
          store={this.store}
          onCloseEditDrawer={this.onCloseEditRowDrawer}
          t={this.props.t}
          currentPlatform={this.currentPlatform}
          currentTheme={this.currentTheme}
        />
      );
    });
    return colBtns;
  }

  showEditRowDrawer = () => {
    this.setState({ visibleEditRowDrawer: true });
  };

  onCloseEditRowDrawer = () => {
    this.setState({ visibleEditRowDrawer: false });
  };

  render() {
    return (
      <>
        {this.currentPlatform === "web" && (
          <Popover
            content={
              <div>
                <Button type="text" icon={<EditOutlined />} onClick={() => this.showEditRowDrawer()}>
                  {this.props.t("collect.edit")}
                </Button>
              </div>
            }
            trigger={["click", "hover"]}
          >
            <div>
              <Row gutter={this.props.row?.gutter ? this.props.row.gutter : 6} style={this.props.row.style}>
                {this.renderColumns()}
              </Row>
            </div>
          </Popover>
        )}

        <Drawer
          onClose={this.onCloseEditRowDrawer}
          closable={false}
          open={this.state.visibleEditRowDrawer}
          destroyOnClose={true}
          width="40%"
          height="100px"
          style={!BookmarksStore.showBookmarks ? { paddingTop: 48 } : { paddingTop: 48, marginTop: 35 }}
          maskStyle={{ backgroundColor: "transparent" }}
        >
          <h3 style={{ marginBottom: -10, color: this.currentTheme === "dark" && "white" }}>
            <InsertRowBelowOutlined style={{ marginRight: 10 }} />
            {this.props.t("collect.rowSettings")}
          </h3>
          <Divider />
          <Button.Group>
            <Button
              size="small"
              type="primary"
              icon={<SettingOutlined />}
              onClick={() => {
                this.store.visibleModal = true;
                this.store.modifyRow(this.props.parentId, this.props.rowIndex);
                this.onCloseEditRowDrawer();
              }}
            >
              {this.props.t("collect.editRowGutter")}
            </Button>
            <Button
              size="small"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => this.store.addNewRow(this.props.parentId, this.props.rowIndex)}
            >
              {this.props.t("collect.addNewRow")}
            </Button>
            <Button
              size="small"
              type="primary"
              icon={<PlusOutlined />}
              disabled={this.props.row.cols.length < 3 ? false : true}
              onClick={() => this.store.addNewColumn(this.props.parentId, this.props.rowIndex)}
            >
              {this.props.t("collect.addColumn")}
            </Button>
            <Button
              type="primary"
              size="small"
              style={{ borderRadius: "0px 3px 3px 0px" }}
              icon={<DeleteOutlined />}
              onClick={() => {
                this.store.removeRow(this.props.parentId, this.props.rowIndex);
              }}
              danger
            >
              {this.props.t("collect.removeRow")}
            </Button>
          </Button.Group>

          <div style={{ textAlign: "center", marginTop: 5 }}>
            <Button.Group>
              <Button
                size="small"
                type="primary"
                icon={<ArrowUpOutlined />}
                disabled={this.props.rowIndex === 0}
                onClick={() => this.store.moveRow(this.props.structure, this.props.rowIndex, this.props.rowIndex - 1)}
              >
                {this.props.t("collect.moveRowUp")}
              </Button>
              <Button
                size="small"
                type="primary"
                icon={<ArrowDownOutlined />}
                disabled={this.props.structure.rows.length === this.props.rowIndex + 1}
                onClick={() => this.store.moveRow(this.props.structure, this.props.rowIndex, this.props.rowIndex + 1)}
              >
                {this.props.t("collect.moveRowDown")}
              </Button>
            </Button.Group>
          </div>

          <Divider />

          <h3 style={{ marginBottom: -10, color: this.currentTheme === "dark" && "white" }}>
            <InsertRowLeftOutlined style={{ marginRight: 10 }} />
            {this.props.t("collect.columnSettings")}
          </h3>
          <Divider />
          {this.renderColumnsButtons()}
        </Drawer>
      </>
    );
  }
}

export default withTranslation()(observer(FormBuilderRow));
