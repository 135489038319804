import { gql } from "@apollo/client";
import * as _ from "lodash";
import { makeAutoObservable, toJS } from "mobx";
import BackendService from "../BackendService";
import GraphQlService from "../GraphQlService";
import { logout } from "./Logout";
import NotificationComponentStore from "../../Common/Notifications/NotificationComponentStore";

class UserStore {
  graphQlService = new GraphQlService();
  backendService = new BackendService(``);
  securityAccess = [];

  projects = undefined;
  user = undefined;
  languages = [
    {
      code: "en",
      name: "English"
    },
    {
      code: "es",
      name: "Español"
    },
    {
      code: "pl",
      name: "Polski"
    }
  ];

  constructor() {
    makeAutoObservable(this);
  }

  loadUserAccess = () => {
    return new Promise((resolve, reject) => {
      this.graphQlService
        .client()
        .query({
          query: gql`
            {
              currentUser {
                id
                username
                email
                version
                projectId
                tenantId
                securityAccess
              }
            }
          `
        })
        .then(
          r => {
            var user = r.data.currentUser;
            if (!user) return resolve();

            localStorage.setItem(`username`, user.username);
            localStorage.setItem(`email`, user.email);
            localStorage.setItem(`isSuperUser`, user.version === -1 ? true : false);
            localStorage.setItem(`tenantId`, user.tenantId);
            localStorage.setItem(`projectId`, user.projectId);
            this.user = user;
            if (user.securityAccess) this.securityAccess = user.securityAccess.split(",");

            return resolve();
          },
          e => {
            if (e && e.message && e.message.includes("You are not authorized to run this query.")) return logout();
          }
        );
    });
  };

  hasAccess = path => {
    var isSuperUser = JSON.parse(localStorage.getItem(`isSuperUser`));

    if (isSuperUser) return true;
    console.log(
      "this.securityAccess.filter(x => x && x.toLowerCase() === path.toLowerCase()).length > 0",
      !this.securityAccess
    );
    if (!this.securityAccess || _.isUndefined(path)) return false;

    return this.securityAccess.filter(x => x && x.toLowerCase() === path.toLowerCase()).length > 0;
  };

  loadProjects = () => {
    NotificationComponentStore.updateNotifications();

    if (localStorage.getItem(`plan`) === "bas" && localStorage.getItem(`isSuperUser`) === false) return;

    this.graphQlService
      .get(`{ userTenants(email: "${localStorage.getItem(`email`)}") { id value projects { id value } } }`)
      .then(r => {
        this.projects = r.data.userTenants;
      });
  };

  switchProject = (tenant, project) => {
    return this.graphQlService
      .post(
        `mutation switch($tenantId: Guid, $projectId: Guid) { projectSwitch(tenantId: $tenantId, projectId: $projectId) }`,
        { tenantId: tenant.id, projectId: project.id }
      )
      .then(r => {
        var json = JSON.parse(r.data.projectSwitch);
        localStorage.setItem(`jwtToken`, json.jwt);
        return true;
      });
  };
}

export default new UserStore();
