import React, { useCallback, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import * as pdfjsLib from "pdfjs-dist/build/pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import PDFJSAnnotate from "pdfjs-annotate";

import { AnnotationFactory } from "annotpdf";
import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  List,
  Popover,
  Row,
  Tooltip,
  Drawer,
  Pagination,
  Input,
  Upload,
  message,
  Spin,
  Dropdown,
  Modal,
  Popconfirm
} from "antd";
import PdfAnnotatorStore from "./PdfAnnotatorStore";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { AnnotationIcon, CircleIcon, PolygonIcon, RectangleIcon } from "../../../Other/DxfEditor/DxfEditorIcons";
import { HuePicker } from "react-color";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  LeftOutlined,
  MonitorOutlined,
  RightOutlined,
  SaveOutlined,
  StarOutlined,
  UnorderedListOutlined,
  ZoomInOutlined,
  ZoomOutOutlined
} from "@ant-design/icons";
import GraphQlService from "../../../Common/GraphQlService";
import { useRouteMatch } from "react-router-dom";
import { toJS } from "mobx";
import { cloneDeep, reduce } from "lodash";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PdfAnnotatorPolygonDrawer from "./shapes/PdfAnnotatorPolygonDrawer";

const PdfAnnotator = ({
  url = "api/svgs/formSubmission/f1094ad7-1390-41cf-f7e1-08dbfd6eb5a2.pdf",
  submissionId,
  allowClaim = false,
  allowModify = false,
  currentAnnotations,
  onAnnotationsUpdated,
  onObjectClaimed,
  claimTextContent = undefined
}) => {
  const [numPages, setNumPages] = useState(null);

  const [annotations, setAnnotations] = useState([]);
  const [annotationsDrawerOpen, setAnnotationsDraweOpen] = useState(false);
  const [annotationsDrawerSearchValue, setAnnotationsDrawerSearchValue] = useState();

  const [mode, setMode] = useState("preview");
  const route = useRouteMatch();

  const [pinchInScale, setPinchInScale] = useState();

  const [toolbarExpanded, setToolbarExpanded] = useState(false);

  useEffect(() => {
    PdfAnnotatorStore.submissionId = submissionId;
    PdfAnnotatorStore.claimTextContent = claimTextContent;

    //
    if (!url) return;
    // if (localStorage.getItem(`platform`) === "mobile") { setMode(`edit`); }

    fetch(`${localStorage.getItem(`host`)}/api/files?path=${url}`, { crossDomain: true })
      .then(res => res.blob()) // Gets the response and returns it as a blob
      .then(text => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(text);
          reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
          };
          reader.onerror = reject;
        });
      })
      .then(async base64 => {
        let pdfFactory = new AnnotationFactory(
          PdfAnnotatorStore.base64ToUint8Array(base64.replace("data:application/pdf;base64,", ""))
        );
        if (currentAnnotations) {
          PdfAnnotatorStore.annotations = currentAnnotations;
          for (let i = 0; i < currentAnnotations.length; i++)
            pdfFactory = await PdfAnnotatorStore.finishAnnotationElement(
              currentAnnotations[i].type,
              cloneDeep(currentAnnotations[i]),
              pdfFactory
            );
          if (!pdfFactory) return;
        } else PdfAnnotatorStore.annotations = [];

        PdfAnnotatorStore.pdfInput = pdfFactory;
        PdfAnnotatorStore.isLoading = false;
      });

    localStorage.removeItem(`pdf-annot-current`);
    localStorage.removeItem(`pdf-annot-body-current`);
  }, []);

  useEffect(() => {
    if (onObjectClaimed && PdfAnnotatorStore.lastClaimedObject) onObjectClaimed(PdfAnnotatorStore.lastClaimedObject);
  }, [PdfAnnotatorStore.lastClaimedObject]);

  useEffect(() => {
    if (!PdfAnnotatorStore.pdfFactory) return;
    //

    async function process() {
      const annots = await PdfAnnotatorStore.pdfFactory.getAnnotations();
      setAnnotations(annots[PdfAnnotatorStore.page - 1]);
      recreaseEditCanvas();
    }
    process();
  }, [PdfAnnotatorStore.pdfFactory]);

  useEffect(() => {
    if (!PdfAnnotatorStore.scale) return;
    //
    if (transformComponentRef && transformComponentRef.current) {
      // transformComponentRef.current.resetTransform();

      setTimeout(() => {
        transformComponentRef.current.setTransform(
          PdfAnnotatorStore.movementState.positionX,
          PdfAnnotatorStore.movementState.positionY,
          1,
          0.5
        );
        PdfAnnotatorStore.preventClaim = false;
        recreaseEditCanvas();
      }, 300);
    }
  }, [PdfAnnotatorStore.scale]);

  useEffect(() => {
    if (!PdfAnnotatorStore.pdfInput) return;
    //
    const element = document.querySelector("div#pdf-editor-container");
    element.addEventListener("scroll", event => {
      if (PdfAnnotatorStore.scrollDetectionEnabled) {
        localStorage.setItem(
          `pdf-annot-current`,
          JSON.stringify({ scrollLeft: event.target.scrollLeft, scrollTop: event.target.scrollTop })
        );
      }
    });
    const body = document.getElementById(`body`);
    body.addEventListener("scroll", event => {
      if (PdfAnnotatorStore.scrollDetectionEnabled) {
        localStorage.setItem(
          `pdf-annot-body-current`,
          JSON.stringify({ scrollLeft: event.target.scrollLeft, scrollTop: event.target.scrollTop })
        );
      }
    });
    if (onAnnotationsUpdated && PdfAnnotatorStore.annotations !== undefined)
      onAnnotationsUpdated(PdfAnnotatorStore.annotations);
  }, [PdfAnnotatorStore.pdfInput]);

  useEffect(() => {
    if (!mode || !PdfAnnotatorStore.pdfInput) return;
    if (mode !== "claim") PdfAnnotatorStore.editorSelectedObjects = [];
    //
    recreaseEditCanvas();
  }, [mode]);

  const recreaseEditCanvas = () => {
    //
    var annotElement = document.getElementById("react-pdf-annot");
    if (annotElement) annotElement.remove();

    var canvases = document.getElementsByTagName("canvas");
    let canvas = undefined;
    for (var i = 0, length = canvases.length; i < length; i++) {
      if (!canvas && canvases[i].className.indexOf("react-pdf") >= 0) canvas = canvases[i];
    }
    //
    if (!canvas) return;

    PdfAnnotatorStore.canvas = document.createElement("canvas");
    PdfAnnotatorStore.canvas.setAttribute("id", "react-pdf-annot");
    PdfAnnotatorStore.canvas.setAttribute(
      "style",
      `position: absolute; top: 0px; left: 0px; z-index: ${mode === "preview" ? -1 : 999};`
    );

    //set dimensions
    PdfAnnotatorStore.canvas.width = +canvas.style.width.replace("px", "");
    PdfAnnotatorStore.canvas.height = +canvas.style.height.replace("px", "");

    var body = document.getElementsByClassName("react-pdf__Page")[0];
    body.appendChild(PdfAnnotatorStore.canvas);
    PdfAnnotatorStore.canvasContext = PdfAnnotatorStore.canvas.getContext("2d");

    PdfAnnotatorStore.editorPageElement = document.getElementById("react-pdf-annot");
    if (mode === "edit") PdfAnnotatorStore.editorAvailable = true;
  };

  const onDocumentLoadSuccess = async data => {
    //
    PdfAnnotatorStore.originalPdfdata = data;
    setNumPages(data.numPages);
    if (PdfAnnotatorStore.page > data.numPages) PdfAnnotatorStore.page = 1;

    const d = await data.getData();
    PdfAnnotatorStore.pdfFactory = new AnnotationFactory(d);
    PdfAnnotatorStore.editorAvailable = false;
    // PdfAnnotatorStore.pdfZoomScale = PdfAnnotatorStore.scale;

    setTimeout(() => {
      if (localStorage.getItem(`platform`) === "mobile" && allowClaim) setMode(`claim`);
    }, 1000);
  };

  const onPointerUp = async (event, page) => {
    //
    if (localStorage.getItem(`platform`) === "web") return;

    if (mode !== "claim") return;
    if (PdfAnnotatorStore.preventClaim) return;

    //
    if (event.type === "pointerup") {
      // is mobile claim?
      //
      if (allowClaim) PdfAnnotatorStore.detectObjectClick(event, page);
    }
  };

  const getFilteredAnnotations = () => {
    if (!annotationsDrawerSearchValue) return annotations;
    return annotations.filter(x => x.id && x.id.toLowerCase().includes(annotationsDrawerSearchValue.toLowerCase()));
  };

  const transformComponentRef = useRef(null);

  return (
    <div style={{ marginTop: 10, marginLeft: "-10px", marginRight: "-10px" }} id="pdf-annotator-div">
      {route.path === "/utils/pdf" && <div style={{ marginTop: 100 }}></div>}
      {PdfAnnotatorStore.annotationCreator && (
        <Card size="small" title="Config">
          <Row gutter={12}>
            <Col span={12}>
              Color:
              <HuePicker
                width="100%"
                color={PdfAnnotatorStore.annotationCreator.hexColor}
                onChange={e => (PdfAnnotatorStore.annotationCreator.hexColor = e.hex)}
              />
            </Col>
            <Col span={12}>
              Id:
              <Input
                style={{ width: "100%" }}
                defaultValue={`Beawre ${PdfAnnotatorStore.annotationCreator.type} ${new Date().getTime()}`}
                value={PdfAnnotatorStore.annotationCreator.id}
                onChange={e => (PdfAnnotatorStore.annotationCreator.id = e.target.value)}
              />
            </Col>
          </Row>
        </Card>
      )}

      {PdfAnnotatorStore.pdfInput && (
        <div style={{ position: "relative" }}>
          <Card style={{ padding: 4 }}>
            {!annotationsDrawerOpen && (
              <div
                style={{
                  backgroundColor: "#efefef",
                  border: "1px solid #cecece",
                  position: "absolute",
                  top: 10,
                  right: 20,
                  zIndex: 10000
                }}
                id="annotator-buttons"
              >
                {toolbarExpanded && (
                  <>
                    <Button
                      type="text"
                      size="large"
                      icon={<RightOutlined />}
                      onClick={() => setToolbarExpanded(false)}
                    />
                    <Divider type="vertical" />

                    {!PdfAnnotatorStore.annotationCreator && (
                      <>
                        {mode === "edit" && allowModify === true && localStorage.getItem(`platform`) === "web" && (
                          <>
                            <Tooltip title="Circle" placement="top">
                              <Button
                                type="text"
                                size="large"
                                icon={<CircleIcon />}
                                onClick={() => PdfAnnotatorStore.startAnnotation("circle")}
                              />
                            </Tooltip>
                            <Tooltip title="Rectangle" placement="top">
                              <Button
                                type="text"
                                size="large"
                                icon={<RectangleIcon />}
                                onClick={() => PdfAnnotatorStore.startAnnotation("square")}
                              />
                            </Tooltip>
                            <Tooltip title="Polygon" placement="top">
                              <Button
                                type="text"
                                size="large"
                                icon={<PolygonIcon />}
                                onClick={() => PdfAnnotatorStore.startAnnotation("polygon")}
                              />
                            </Tooltip>
                            <Divider type="vertical" />
                          </>
                        )}
                      </>
                    )}
                    {PdfAnnotatorStore.annotationCreator && (
                      <>
                        <Tooltip title="Cancel" placement="top">
                          <Button
                            type="text"
                            size="large"
                            icon={<CloseOutlined />}
                            style={{ color: "red" }}
                            onClick={() => (PdfAnnotatorStore.annotationCreator = null)}
                          />
                        </Tooltip>
                        {PdfAnnotatorStore.annotationCreator.type === "polygon" && (
                          <Tooltip title="Finish" placement="top">
                            <Button
                              type="text"
                              size="large"
                              icon={<CheckOutlined />}
                              style={{ color: "green" }}
                              onClick={async () => {
                                const viewport = await PdfAnnotatorStore.getViewport(PdfAnnotatorPolygonDrawer.page);
                                var input = PdfAnnotatorPolygonDrawer.prepareAnnotationData({}, viewport);
                                //

                                PdfAnnotatorStore.finishAnnotation(input);
                              }}
                            />
                          </Tooltip>
                        )}
                        <Divider type="vertical" />
                      </>
                    )}

                    {mode !== "preview" && (
                      <Tooltip title="Preview mode" placement="top">
                        <Button type="text" size="large" icon={<EyeOutlined />} onClick={() => setMode("preview")} />
                      </Tooltip>
                    )}

                    {mode !== "claim" && allowClaim && (
                      <Tooltip title="Claim" placement="top">
                        <Button type="text" size="large" icon={<StarOutlined />} onClick={() => setMode("claim")} />
                      </Tooltip>
                    )}

                    {localStorage.getItem(`platform`) === "web" && (
                      <>
                        {allowModify === true && mode !== "edit" && (
                          <Tooltip title={"Edit Mode"} placement="top">
                            <Button type="text" size="large" icon={<EditOutlined />} onClick={() => setMode("edit")} />
                          </Tooltip>
                        )}
                      </>
                    )}

                    {localStorage.getItem(`platform`) === "web" && (
                      <Tooltip title="Download" placement="top">
                        <Button
                          type="text"
                          size="large"
                          icon={<DownloadOutlined />}
                          onClick={() => PdfAnnotatorStore.pdfFactory.download()}
                        />
                      </Tooltip>
                    )}

                    <Divider type="vertical" />

                    <Tooltip title="Zoom in" placement="top">
                      <Button
                        type="text"
                        size="large"
                        icon={<ZoomInOutlined />}
                        onClick={() => PdfAnnotatorStore.setScale((PdfAnnotatorStore.scale += 0.2))}
                      />
                    </Tooltip>

                    <Dropdown
                      placement="bottomRight"
                      trigger={"click"}
                      overlayStyle={{ paddingRight: 50 }}
                      menu={{
                        items: [0.2, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 6, 7, 8, 9, 10].map(opt => ({
                          key: `${opt}`,
                          label: `${opt * 100}%`
                        })),
                        onClick: e => PdfAnnotatorStore.setScale(+e.key)
                      }}
                    >
                      <Button type="text" size="large" icon={<MonitorOutlined />} />
                    </Dropdown>

                    <Tooltip title="Zoom out" placement="top">
                      <Button
                        type="text"
                        size="large"
                        icon={<ZoomOutOutlined />}
                        onClick={() => PdfAnnotatorStore.setScale((PdfAnnotatorStore.scale -= 0.2))}
                      />
                    </Tooltip>
                  </>
                )}
                {!toolbarExpanded && (
                  <Button type="text" size="large" icon={<LeftOutlined />} onClick={() => setToolbarExpanded(true)} />
                )}

                <Divider type="vertical" />
                <Tooltip title="Show / Hide Annotations" placement="top">
                  <Button
                    type="text"
                    size="large"
                    icon={<UnorderedListOutlined />}
                    onClick={() => setAnnotationsDraweOpen(!annotationsDrawerOpen)}
                  />
                </Tooltip>
              </div>
            )}

            {(window.location.href.includes(`localhost:3000`) || window.location.href.includes(`develop.`)) && (
              <div style={{ marginLeft: 20 }}>
                {PdfAnnotatorStore.scale} / {PdfAnnotatorStore.pdfZoomScale} / {PdfAnnotatorStore.scaleDiff} /{" "}
                {PdfAnnotatorStore.preventClaim}
                <br />
                Pan: {JSON.stringify(PdfAnnotatorStore.panningState)}
                <br />
                Move: {JSON.stringify(PdfAnnotatorStore.movementState)}
              </div>
            )}

            <Spin spinning={PdfAnnotatorStore.isLoading}>
              {PdfAnnotatorStore.pdfInput && (
                <TransformWrapper
                  disabled={PdfAnnotatorStore.annotationCreator !== undefined}
                  // = = = = = = = = = = ZOOM = = = = = = = = = =
                  // onPinchingStart={(ref, event) => {
                  //
                  //   PdfAnnotatorStore.scaleDiff = 0;
                  // }}

                  onPinching={(ref, event) => {
                    PdfAnnotatorStore.preventClaim = true;

                    if (ref.state.scale > 1) PdfAnnotatorStore.scaleDiff = ref.state.scale - 1;
                    else {
                      let scaleDiff = PdfAnnotatorStore.scale / 100;
                      PdfAnnotatorStore.scaleDiff = -((1 - ref.state.scale) * scaleDiff * 100);
                    }
                    PdfAnnotatorStore.pdfZoomScale = ref.state.scale;
                  }}
                  onPinchingStop={(ref, event) => {
                    if (ref.state.scale > 1) {
                      PdfAnnotatorStore.movementState.positionX = ref.state.positionX / ref.state.scale;
                      PdfAnnotatorStore.movementState.positionY = ref.state.positionY / ref.state.scale;
                    } else {
                      PdfAnnotatorStore.movementState.positionX = ref.state.positionX * ref.state.scale;
                      PdfAnnotatorStore.movementState.positionY = ref.state.positionY * ref.state.scale;
                    }
                    ref.resetTransform();
                    PdfAnnotatorStore.setScale(PdfAnnotatorStore.scale + PdfAnnotatorStore.scaleDiff);
                  }}
                  // = = = = = = = = = = MOVE = = = = = = = = = =
                  // onPanningStart={(r, e) => {
                  // }}
                  onPanning={(ref, e) => {
                    PdfAnnotatorStore.preventClaim = true;
                    PdfAnnotatorStore.panningState = ref.state;
                  }}
                  onPanningStop={(ref, e) => {
                    if (!PdfAnnotatorStore.panningState || !PdfAnnotatorStore.preventClaim) return;

                    PdfAnnotatorStore.movementState = ref.state;
                    ref.setTransform(
                      PdfAnnotatorStore.panningState.positionX,
                      PdfAnnotatorStore.panningState.positionY,
                      PdfAnnotatorStore.panningState.scale
                    );
                    setTimeout(() => (PdfAnnotatorStore.preventClaim = false), 400);
                  }}
                  // = = = = = = = = = = OTHER = = = = = = = = = =
                  ref={transformComponentRef}
                  initialScale={1}
                  panning={{ disabled: false, lockAxisX: false, lockAxisY: false }}
                  wheel={{ disabled: true }}
                  doubleClick={{ disabled: true }}
                  centerOnInit={false}
                  centerZoomedOut={false}
                  limitToBounds={false}
                >
                  {utils => (
                    <React.Fragment>
                      {/* <Controls {...utils} /> */}
                      <TransformComponent>
                        <div style={{ maxHeight: window.innerHeight * 0.8 }} id="pdf-editor-container">
                          {/* overflowX: "auto" */}

                          <Document
                            style={{ padding: 10 }}
                            key="pdf-editor"
                            file={PdfAnnotatorStore.pdfInput}
                            onLoadSuccess={d => onDocumentLoadSuccess(d)}
                          >
                            <Page
                              key={`pdf-editor-page-${PdfAnnotatorStore.page}`}
                              pageNumber={PdfAnnotatorStore.page}
                              scale={PdfAnnotatorStore.scale}
                              width={window.innerWidth * 0.55}
                              height={500}
                              onClick={async (event, page) => {
                                if (mode !== "claim") {
                                  var clickedElements = await PdfAnnotatorStore.detectObjectClick(event, page, true);
                                  if (!clickedElements) return;
                                  if (mode === "edit" || clickedElements.annot.id.includes(`claimed`)) {
                                    PdfAnnotatorStore.selectedAnnotation = { event, page, clickedElements };
                                  }
                                  return;
                                }

                                if (localStorage.getItem(`platform`) === "mobile") return;
                                if (!PdfAnnotatorStore.editorPageElement) return;
                                if (PdfAnnotatorStore.annotationCreator) return;
                                if (mode !== "claim") return;

                                if (allowClaim) await PdfAnnotatorStore.detectObjectClick(event, page);
                              }}
                              onMouseDown={async (event, page) => {
                                if (localStorage.getItem(`platform`) === "mobile") return;
                                if (PdfAnnotatorStore.editorPaused) return;
                                if (!PdfAnnotatorStore.editorPageElement) return;

                                PdfAnnotatorStore.onMouseDown(event, page);
                              }}
                              onMouseUp={async (event, page) => {
                                if (localStorage.getItem(`platform`) === "mobile") return;
                                if (PdfAnnotatorStore.editorPaused) return;
                                if (!PdfAnnotatorStore.editorPageElement) return;

                                PdfAnnotatorStore.onMouseUp(event, page);
                              }}
                              onMouseMove={async (event, page) => {
                                if (localStorage.getItem(`platform`) === "mobile") return;
                                if (PdfAnnotatorStore.editorPaused) return;
                                if (!PdfAnnotatorStore.editorPageElement) return;

                                PdfAnnotatorStore.onMouseMove(event);
                              }}
                              onPointerDown={async (event, page) => {
                                if (localStorage.getItem(`platform`) === "web") return;
                                //
                                // PdfAnnotatorStore.pointersEvCache.push(event);

                                // await PdfAnnotatorStore.onMouseDown(event, page);
                              }}
                              // onPointerMove={async (event, page) => {
                              //   if (localStorage.getItem(`platform`) === 'web') return;
                              //   const index = PdfAnnotatorStore.pointersEvCache.findIndex(
                              //     (cachedEv) => cachedEv.pointerId === event.pointerId,
                              //   );
                              //   PdfAnnotatorStore.pointersEvCache[index] = event;
                              //   //

                              //   if (PdfAnnotatorStore.pointersEvCache.length === 2) {
                              //     const curDiff = Math.abs(PdfAnnotatorStore.pointersEvCache[0].clientX - PdfAnnotatorStore.pointersEvCache[1].clientX);
                              //
                              //     setPinchInScale([curDiff, PdfAnnotatorStore.pointersPrevDiff])
                              //     PdfAnnotatorStore.pointersPrevDiff = curDiff;
                              //   }
                              // }}

                              onPointerUp={(event, page) => onPointerUp(event, page)}
                              onPointerCancel={(event, page) => onPointerUp(event, page)}
                              onPointerLeave={(event, page) => onPointerUp(event, page)}
                              onPointerOut={(event, page) => onPointerUp(event, page)}
                            />
                          </Document>
                        </div>
                      </TransformComponent>
                    </React.Fragment>
                  )}
                </TransformWrapper>
              )}
            </Spin>
            <Pagination
              current={PdfAnnotatorStore.page}
              total={numPages}
              pageSize={1}
              onChange={pageIndex => PdfAnnotatorStore.setPage(pageIndex)}
            />
          </Card>
        </div>
      )}

      <Drawer
        title={t("common.selections")}
        open={annotationsDrawerOpen}
        onClose={() => setAnnotationsDraweOpen(false)}
        size="large"
        style={{ zIndex: 12000, marginTop: 50 }}
        contentWrapperStyle={{ height: "92%" }}
      >
        <Input
          placeholder={t("common.search")}
          value={annotationsDrawerSearchValue}
          onChange={e => setAnnotationsDrawerSearchValue(e.target.value)}
        />
        <List
          size="small"
          locale={{ emptyText: t("common.noDataEntry") }}
          dataSource={getFilteredAnnotations()}
          renderItem={item => (
            <List.Item
              actions={[
                <>
                  {!item.id.includes(`claimed`) && mode === "claim" && (
                    <Button
                      icon={<StarOutlined />}
                      size="small"
                      type={
                        PdfAnnotatorStore.editorSelectedObjects.filter(x => x.annot.id === item.id).length > 0
                          ? "primary"
                          : "default"
                      }
                      onClick={async () => {
                        PdfAnnotatorStore.editorSelectedObjects.push({
                          type: item.type.replace("/", "").toLowerCase(),
                          annot: item
                        });
                        PdfAnnotatorStore.pdfInput = await PdfAnnotatorStore.collectClaimSelectedAnnotations();
                      }}
                    />
                  )}
                </>,
                <>
                  {!item.id.includes(`claimed`) && (
                    <Button
                      icon={<DeleteOutlined />}
                      size="small"
                      onClick={() => PdfAnnotatorStore.deleteAnnotation(item)}
                    />
                  )}
                </>
              ]}
            >
              {item.id} ({item.type.replace("/", "").toLowerCase()})
            </List.Item>
          )}
        />
      </Drawer>

      {PdfAnnotatorStore.selectedAnnotation && (
        <Modal
          open={true}
          title={`Annotation: ${PdfAnnotatorStore.selectedAnnotation.clickedElements.annot.id}`}
          width={"60%"}
          footer={null}
          onCancel={() => (PdfAnnotatorStore.selectedAnnotation = undefined)}
        >
          <Divider />
          <div style={{ textAlign: "center" }}>
            {PdfAnnotatorStore.selectedAnnotation.clickedElements.annot.id.includes("-claimed") && (
              <Popconfirm
                title="Unclaim"
                description="Area you sure?"
                onConfirm={async () => {
                  PdfAnnotatorStore.pdfInput = await PdfAnnotatorStore.collectUnClaimSelectedAnnotations(
                    PdfAnnotatorStore.selectedAnnotation.clickedElements.annot.id
                  );
                  PdfAnnotatorStore.selectedAnnotation = undefined;
                }}
              >
                <Button
                  size="large"
                  icon={
                    <>
                      <DeleteOutlined style={{ color: "red" }} />
                      <StarOutlined style={{ color: "red" }} />
                    </>
                  }
                />
              </Popconfirm>
            )}
            {mode === "edit" && (
              <Popconfirm
                title="Delete"
                description="Area you sure?"
                onConfirm={async () => {
                  await PdfAnnotatorStore.deleteAnnotation(PdfAnnotatorStore.selectedAnnotation.clickedElements.annot);
                  PdfAnnotatorStore.selectedAnnotation = undefined;
                }}
              >
                <Button style={{ marginLeft: 30 }} size="large" icon={<DeleteOutlined style={{ color: "red" }} />} />
              </Popconfirm>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};
export default withTranslation()(observer(PdfAnnotator));
