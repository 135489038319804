import { makeAutoObservable } from "mobx";
import { Graphics, Text } from "pixi.js";
import DxfEditorStore from "../DxfEditorStore";

class DxfEditorDrawArcStore {
    constructor() {
        makeAutoObservable(this);
    }

    parseFromDxf = (entity) => {
        var object = {
            hexColor: entity.hexColor,
            center: entity.center, radius: entity.radius, endAngle: entity.endAngle, startAngle: entity.startAngle
        };
        return object;
    }

    draw = (entity) => {
        if (!entity.hexColor) entity.hexColor = "#ffffff"
        entity.hexColor = entity.hexColor.replace('#', '0x');

        const shape = new Graphics();
        shape.lineStyle(2, entity.hexColor);
        shape.arc(entity.center.x, -entity.center.y, entity.radius, -entity.endAngle, -entity.startAngle, false);
        return shape;
    }

    exportToDxf = (store, results, entity) => {
        results = store.exportInsert(results, 0, "ARC");
        if (entity.handle) results = store.exportInsert(results, 5, entity.handle);
        if (entity.ownerHandle) results = store.exportInsert(results, 330, entity.ownerHandle);
        results = store.exportInsert(results, 100, "AcDbEntity");
        if (entity.layer) results = store.exportInsert(results, 8, entity.layer);
        if (entity.colorIndex) results = store.exportInsert(results, 62, entity.colorIndex);
        results = store.exportInsert(results, 100, "AcDbCircle");
        if (entity.center) {
            results = store.exportInsert(results, 10, entity.center.x);
            results = store.exportInsert(results, 20, entity.center.y);
            results = store.exportInsert(results, 30, entity.center.z);
        }
        if (entity.radius) results = store.exportInsert(results, 40, entity.radius);
        results = store.exportInsert(results, 100, "AcDbArc");
        results = store.exportInsert(results, 50, entity.startAngle / (Math.PI / 180));
        results = store.exportInsert(results, 51, entity.endAngle / (Math.PI / 180));
        return results;
    }
}
export default new DxfEditorDrawArcStore();