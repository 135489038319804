import {
  CustomerServiceOutlined,
  GlobalOutlined,
  IssuesCloseOutlined,
  LinkOutlined,
  LogoutOutlined,
  PlusOutlined,
  QuestionOutlined
} from "@ant-design/icons";
import { List, Modal, Space, Button } from "antd";
import i18n from "i18next";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import UserStore from "./../Users/UserStore";
import { logout } from "./../Users/Logout";
import ReportIssue from "../Support/ReportIssue";
import dayjs from "dayjs";
import moment from "moment";
import { observer } from "mobx-react";

import { locale as devExpressLocale } from "devextreme/localization";
import WrapperSelectorProject from "../../components/projectsMenuMobile/WrapperSelectorProject.jsx";
import SelectProject from "../../components/projectsMenuMobile/SelectProject.jsx";

const MainMenuMobile = ({ setDrawerVisible, t }) => {
  const [languageList, setLanguageList] = useState(false);
  const history = useHistory();

  return (
    <List>
      {/* <Link to="/collect/entry/new">
        <List.Item>
          <List.Item.Meta avatar={<PlusOutlined />} title={t("mobileMenu.addNewEntry")} onClick={() => setDrawerVisible(false)} />
        </List.Item>
      </Link> */}
      <List.Item>
        <Link to="/collect/entries">
          <List.Item.Meta
            avatar={<LinkOutlined />}
            title={t("mobileMenu.entries")}
            onClick={() => setDrawerVisible(false)}
          />
        </Link>
      </List.Item>
      <Space />
      <List.Item onClick={() => setLanguageList(!languageList)}>
        <List.Item.Meta avatar={<GlobalOutlined />} title={t("userInfo.language")} />
      </List.Item>
      {languageList && (
        <List style={{ paddingLeft: 10 }}>
          {UserStore.languages.map(({ code, name }) => (
            <List.Item
              key="change-language-mobile"
              onClick={() => {
                localStorage.setItem(`language`, code);
                window.location.reload();
              }}
              style={{ padding: 0, margin: "5px 0px 0px 0px" }}
            >
              {name}
            </List.Item>
          ))}
        </List>
      )}
      <SelectProject />
      <List.Item>
        <List.Item.Meta
          avatar={<CustomerServiceOutlined />}
          title={t("mobileMenu.submitIssue")}
          onClick={() => {
            const modal = Modal.info();
            modal.update({
              title: t("mobileMenu.submitIssue"),
              content: <ReportIssue />,
              footer: null,
              closable: true
            });
          }}
        />
      </List.Item>
      <List.Item>
        <List.Item.Meta
          avatar={<LogoutOutlined />}
          danger={`true`}
          onClick={() => logout(history)}
          title={t("userInfo.logout")}
        />
      </List.Item>
      {/*       <Link to="/documentscanner">
        <List.Item>
          <List.Item.Meta avatar={<LinkOutlined />} title="Document Scanner" />
        </List.Item>
      </Link> */}
    </List>
  );
};

export default withTranslation()(observer(MainMenuMobile));
