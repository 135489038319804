import React, { useState, useEffect } from "react";
import { Button } from "antd-mobile";
import { UploadOutlined } from "@ant-design/icons";

import GraphQlService from "../../../Common/GraphQlService";
import ConfigStore from "../../../Common/ConfigStore";
import { withTranslation } from "react-i18next";
import { Camera, CameraResultType } from "@capacitor/camera";
import PdfAnnotator from "../pdf-annotator/PdfAnnotator";
import { useRouteMatch } from "react-router-dom";
import { Spin } from "antd";
import CollectSubmissionStore from "../../Submissions/FormSubmissionStore";
import ImageAnnotatorComponent from "../../../Common/ImageAnnotator/ImageAnnotatorComponent";
import CollectSubmissionCommonStore from "../../Submissions/view/FormSubmissionCommonStore";
import { Toast } from "antd-mobile";

const FormFileUploadMobile = props => {
  const graphQlService = new GraphQlService();
  const [isLoading, setIsLoading] = useState(false);
  const [currentFile, setCurrentFile] = useState(undefined);
  const routerMatch = useRouteMatch();

  useEffect(() => {
    if (props.values && props.values.value) setCurrentFile(props.values.value);
    setIsLoading(false);
  }, [props.values]);

  const onAnnotationsUpdated = annotations => {
    //
    props.updateValue(props.id, props.values.value, { annotations });
  };

  const selectImageFromGallery = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: true,
        resultType: CameraResultType.Base64
      });

      if (image) {
        //
        const selectedImage = "data:image/jpeg;base64," + image.base64String;

        setIsLoading(true);
        setCurrentFile(undefined);
        const response = await graphQlService.post(
          `mutation mutate($file: String, $filename: String, $id: Guid) {
            formSubmissionUploadFile(file: $file, filename: $filename, id: $id)
          }`,
          {
            file: selectedImage,
            filename: `image_${Date.now()}.jpg`,
            id: props.submissionId
          }
        );

        const uploadedFile = response.data.formSubmissionUploadFile;
        props.updateValue(props.id, uploadedFile, props.values ? props.values.payload : undefined);
        setCurrentFile(uploadedFile);
      } else {
        Toast.show({ icon: "fail", content: "No image selected from the gallery." });
      }
    } catch (error) {
      Toast.show({ icon: "fail", content: "Image upload failed" });
      console.error("Error uploading image:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <label>
          {props.label} {props.isRequired && <span style={{ color: "red", marginLeft: 2 }}>(*)</span>}
        </label>
        <br />
        {!currentFile && (
          <Button icon={<UploadOutlined />} onClick={selectImageFromGallery} disabled={isLoading || props.viewOnly}>
            {props.t("collect.clicktoUpload")}
          </Button>
        )}
        {currentFile && (
          <div>
            {currentFile.includes(".pdf") && (
              <div style={{ minHeight: 300 }}>
                <PdfAnnotator
                  url={currentFile}
                  submissionId={props.submissionId}
                  allowModify={routerMatch.params.containerId === props.submissionId}
                  allowClaim={routerMatch.params.containerId !== props.submissionId}
                  currentAnnotations={props.values?.payload?.annotations || []}
                  onAnnotationsUpdated={onAnnotationsUpdated}
                  claimTextContent={CollectSubmissionStore.pdfAnnotatorClaimContent}
                  onObjectClaimed={v => (CollectSubmissionCommonStore.valueToUpdate = v)}
                />
              </div>
            )}

            {!currentFile.includes(".pdf") && (
              <img
                src={ConfigStore.backendUrlHost + "/" + currentFile}
                alt="Uploaded image"
                style={{ maxWidth: "100%", maxHeight: "300px" }}
              />
            )}
          </div>
        )}
      </Spin>
    </>
  );
};

export default withTranslation()(FormFileUploadMobile);
