import React, { useEffect, useState } from "react";

import { Col, Row, Button } from "antd";
import { CloseOutlined, CloseCircleFilled } from "@ant-design/icons";
import * as _ from "lodash";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import SearchComponentStore from "../../Common/Search/SearchComponentStore";
import DocumentsStore from "./../../WorflowRiskControl/Documents/DocumentsStore";
import TasksStore from "./../../WorflowRiskControl/Tasks/TasksStore";
import { withRouter } from "react-router-dom";
import CommentStore from "../../WorflowRiskControl/Comments/CommentStore";
import { themeConfig } from "./config";

const SearchFiltersTags = ({ toggleShowSearchDrawer, history, match, t }) => {
  const documentsStore = DocumentsStore;
  const searchStore = SearchComponentStore;
  const tasksStore = TasksStore;
  const commentStore = CommentStore;
  const { currentTheme } = themeConfig();

  const [queryBuilderString, setQueryBuilderString] = useState(undefined);
  const path = history.location.pathname;

  useEffect(() => {
    if (!_.isEmpty(window.location.search)) {
      if (((path.includes("/program") && tasksStore.loading) || (path.includes("/comments") && commentStore.isLoading) || ((path.includes("/documents") || path.includes("/general") || path.includes("/workload") || path.includes("/performance") || path.includes("/subcontractors")) && documentsStore.loading)) && (!_.isEqual(searchStore.queryBuilderString, queryBuilderString))) {
        setQueryBuilderString(searchStore.queryBuilderString)
      }
    }
  }, [searchStore.queryBuilderString, documentsStore.loading, tasksStore.loading, commentStore.isLoading, path, queryBuilderString]);

  let currentSearch = ((path.includes("/program") && !tasksStore.loading) || (path.includes("/comments") && !commentStore.isLoading) || ((path.includes("/documents") || path.includes("/general") || path.includes("/workload") || path.includes("/performance") || path.includes("/subcontractors")) && !documentsStore.loading)) && queryBuilderString;

  let statements = currentSearch ?
    currentSearch
      .slice(1, -1)
      .split(/AND | OR |\s?NOT/)
      .filter((word) => word.includes("=") || word.includes("Like") || word.includes("<") || word.includes(">") || word.includes("Contains") || word.includes("Is Resolved") || word.includes("NOT is resolved"))
      .map((statement) => statement.trim())
    : [];


  if (statements.length > 0) {
    const between = /\w*\s\w*\s\d*\.\d*\.\d*\s\w*\s\d*\.\d*\.\d*/gi;

    for (const match of queryBuilderString.matchAll(between)) {
      statements.push(match[0]);
    }

    statements = statements.map((statement) => {
      let st = statement
      if (statement[0] === '(') return statement.slice(1, statement.length)
      if (statement[statement.length - 1] === ')' && statement[0] !== '(') return statement.slice(0, statement.length - 1)
      if (statement[statement.length - 1] === ')' && statement[statement.length - 2] === ')') return statement.slice(0, statement.length - 2)
      return st
    })

    statements
      .filter((statement, i) => statements.indexOf(statement) === i)
      .forEach((statement) => {
        currentSearch = currentSearch.replaceAll(statement, `<p style="border-radius: 10px; background-color: #fed736; margin: 0px 3px; padding: 0px 15px; display: inline-block; height: 23px; cursor: pointer;">${statement.replace(/[()]/g, "")}</p>`);
      });

    currentSearch = currentSearch.slice(1, -1).replace(/[(]/g, '&nbsp;<span>').replace(/[)]/g, '</span>&nbsp;');
    const conjunctions = ["AND", "OR", "NOT"];

    conjunctions.forEach(conjunction => {
      currentSearch = currentSearch.replaceAll(conjunction, `<i>&nbsp;${conjunction}&nbsp;</i>`);
    });

    const sliceStatementByOperator = (statement, operator) => statement.slice(statement.indexOf(operator), statement.length).trim();
    const operator = (str) => str.substring(0, str.indexOf(' ') + 1);
    const value = (str) => str.substring(str.indexOf(' ') + 1);

    statements.map((statement) => {
      if (statement.includes("=")) {
        return sliceStatementByOperator(statement, "=");
      }
      else if (statement.includes("<")) {
        return sliceStatementByOperator(statement, "<");
      }
      else if (statement.includes(">")) {
        return sliceStatementByOperator(statement, ">");
      }
      else if (statement.includes("Like")) {
        return sliceStatementByOperator(statement, "Like");
      }
      else if (statement.includes("Contains")) {
        return sliceStatementByOperator(statement, "Contains");
      }
      else if (statement.includes("BETWEEN")) {
        return sliceStatementByOperator(statement, "BETWEEN").replace("AND", "<i>&nbsp;AND&nbsp;</i>");
      } else {
        return statement
      }
    }).forEach(slicedStatement => {
      currentSearch = currentSearch.replace(slicedStatement, `${operator(slicedStatement)}<b>${value(slicedStatement)}</b>`);
    });

    const subtractLight = (color, amount) => {
      let cc = parseInt(color, 16) - amount;
      let c = (cc < 0) ? 0 : (cc);
      c = (c.toString(16).length > 1) ? c.toString(16) : `0${c.toString(16)}`;
      return c;
    }

    const darken = (color, amount) => {
      color = (color.indexOf("#") >= 0) ? color.substring(1, color.length) : color;
      amount = parseInt((255 * amount) / 100);
      return color = `#${subtractLight(color.substring(0, 2), amount)}${subtractLight(color.substring(2, 4), amount)}${subtractLight(color.substring(4, 6), amount)}`;
    }

    const currentSearchGroups = currentSearch.match(/<span>/g);

    currentSearchGroups && currentSearchGroups.forEach((span, i) => {
      currentSearch =
        currentSearch.replace(
          span,
          `<span 
              style="
                display:flex; 
                align-content: center; 
                align-items: center; 
                flex-wrap: wrap; 
                background-color: ${i > 0 ? darken("#fefefe", (i + 1) * 5) : "transparent"}; 
                padding: 4px  ${i > 0 ? "10px" : "0px"}; 
                border-radius: 15px; 
                height: 25px";
            >`)
    });
  }

  const ClearFilters = () => {
    const [isHovered, setIshovered] = useState(false);

    const projectName = match.params.projectName

    return (
      <div
        onMouseOver={() => setIshovered(true)}
        onMouseLeave={() => setIshovered(false)}
      >
        {isHovered ?
          <Button type="text" size="small"
            className="tag-animation"
            style={{ color: "#f7f8f9", backgroundColor: "#333", borderRadius: 50, display: "flex", justifyContent: "space-around", alignItems: "center" }}
            onClick={() => {
              var fields = searchStore.fields;
              searchStore.fields = undefined;
              searchStore.searchValue = undefined;
              searchStore.queryBuilderJson = undefined;
              setTimeout(() => {
                searchStore.fields = fields;
                history.push({
                  pathname: `${searchStore.page.includes(":projectName?") ? (searchStore.page[0] !== '/' ? `/${projectName}/${searchStore.page.substring(searchStore.page.indexOf('?') + 2)}` : `/${projectName}${searchStore.page.substring(searchStore.page.indexOf('?') + 1)}`) : searchStore.page}`,
                });
                window.location.reload();
              }, 100);
            }}
          ><CloseOutlined style={{ fontSize: 10 }} /><span style={{ marginTop: -2 }}>{t("buttons.resetFilters")}</span></Button> :
          <CloseCircleFilled style={{ fontSize: 16, color: currentTheme === "dark" && "white"  }} />
        }
      </div>
    );
  }

  return (
    <Col>
      <Row style={{ margin: "-16px 0px 8px 24px" }}>
        <Col>
          {currentSearch && <h4 style={{ marginRight: 6, color: currentTheme === "dark" && "white" }}>{t("buttons.activeFilters")}</h4>}
        </Col>
        <Col>
          {currentSearch && <ClearFilters t={t} />}
        </Col>
      </Row>
      <Row align="top" style={{ marginBottom: 12, marginLeft: 20, width: "95%" }} onClick={() => toggleShowSearchDrawer()}>
        {currentSearch && <Row dangerouslySetInnerHTML={{ __html: currentSearch }} />}
      </Row>
    </Col>
  )
}

export default withTranslation()(withRouter(observer(SearchFiltersTags)));