import React, { useEffect } from "react";

import { ApartmentOutlined, StarOutlined } from "@ant-design/icons";

import _ from "lodash";
import { withTranslation } from "react-i18next";

const CollectFormPdfAnnotatorClaim = (props) => {
    return (<>
        {props.isSidebarElement && <>
            <div style={{ textAlign: "center" }}><StarOutlined /></div>
        </>}

        {props.isBuilder && <>
            Pdf annotator claimed object Id (Hidden field in submission)
        </>}

        {props.isSubmission && <>
            {/* Pdf annotator claimed object Id (Hidden field in submission) ({props.value} / {props.storeValue}) */}
        </>}

    </>
    );
}


export default withTranslation()(CollectFormPdfAnnotatorClaim);
