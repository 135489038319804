import React, { useEffect, useState } from "react";

import { Form, message, Spin, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { withTranslation } from "react-i18next";
import GraphQlService from "../../../../Common/GraphQlService";

const CollectFileUploadCommonComponent = props => {
  const graphQlService = new GraphQlService();
  const [isLoading, setIsLoading] = useState(false);

  const custom = async e => {
    if (!props || (!props.canUpload && props.canUpload === false)) return;
    setIsLoading(true);
    if (props.fileUploadMode && props.fileUploadMode === 'multi') {
      graphQlService
        .post(
          `mutation mutate($file: String, $filename: String, $id: Guid){ formSubmissionUploadMultiPagePdf(file: $file, filename: $filename, id: $id) }`,
          {
            file: await toBase64(e.file),
            filename: e.file.name,
            id: props.submissionId
          }
        )
        .then(r => {
          e.onSuccess(r.data.formSubmissionUploadMultiPagePdf);
          message.success(`${e.file.name} ${props.t("uploaded")}`);
          if (props.onUpload) props.onUpload(r.data.formSubmissionUploadMultiPagePdf);
          setIsLoading(false);
        });
    } else {
      // setState(v => ({ ...v, currentFile: undefined, isLoading: true }));
      graphQlService
        .post(
          `mutation mutate($file: String, $filename: String, $id: Guid){ formSubmissionUploadFile(file: $file, filename: $filename, id: $id) }`,
          {
            file: await toBase64(e.file),
            filename: e.file.name,
            id: props.submissionId
          }
        )
        .then(r => {
          e.onSuccess(r.data.formSubmissionUploadFile);
          message.success(`${e.file.name} ${props.t("uploaded")}`);

          // props.updateValue(props.id, r.data.formSubmissionUploadFile, props.values ? props.values.payload : undefined);
          // setState(v => ({ ...v, isLoading: false, currentFile: r.data.formSubmissionUploadFile }));
          if (props.onUpload) props.onUpload(r.data.formSubmissionUploadFile);
          setIsLoading(false);
        });
    }
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  let uploadProps = { disabled: "true" };
  uploadProps = {
    name: "file",
    multiple: false,
    customRequest: custom,
    disabled: props.viewOnly,
    showUploadList: false
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <Form.Item
          label={
            <>
              {props.label} {props.isRequired && <span style={{ color: "red", marginLeft: 2 }}>(*)</span>}
            </>
          }
        />
        <Upload.Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
            {/* {props.fileUploadMode} */}
          </p>
          <p className="ant-upload-text">{props.t("collect.clicktoUpload")}</p>
        </Upload.Dragger>
      </Spin>
    </>
  );
};

export default withTranslation()(CollectFileUploadCommonComponent);
