import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

import componentsRegistry from "./componentsRegistry";
import AppRoute from "./AppRoute";
import LayoutEmpty from "./Common/LayoutEmpty/LayoutEmpty";
import BaseLayout from "./Common/Layout/BaseLayout";

const getUrlProjectPaths = mainPath => [`/:projectName?` + mainPath];
// console.log('getUrlProjectPaths("/automate/templates")', getUrlProjectPaths("/automate/workflows"));
// "path": "/assets/:containerId",        "path": "/assets/analysis/:componentId"
// "/lessonLearn/:containerId",     path: "/utils/project/:subpage?/:tab?","/utils/tenant/:containerId/:subpage?
// path: "/utils/reset-password/:code?",    path: "/utils/login/:jwtToken",     path: "/utils/procore/login-complete/:jwtToken",
// path: "/utils/procore/login/:tenantId",    path: "/utils/activate/:code",
// path: getUrlProjectPaths("/collect/entry/hub/:assetId/:containerId"),    path: "/utils/generate-svg/bowtie/:containerId/:jwtToken",     path: "/utils/generate-svg/bpmn/:containerId",
// path: "/utils/tenant/:containerId/:subpage?",    path: "/utils/reset-password/:code?",    path: "/utils/login/:jwtToken",
// path: "/utils/login/:jwtToken",path: "/utils/generate-svg/bpmn/:containerId/:jwtToken",    path: "/utils/dxf/:containerId",

const routes = [
  {
    path: "/assets/:containerId",
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="Assets.title"
        path="/assets/:containerId"
        {...props}
        component={componentsRegistry.AssetsPage}
      />
    ),
    handle: { breadcrumb: [{ title: "Assets.title" }] }
  },
  {
    path: "/assets/analysis/:componentId",
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="Assets.analysis.title"
        path="/assets/analysis/:componentId"
        {...props}
        component={componentsRegistry.AssetsAnalysisPage}
      />
    ),
    handle: { breadcrumb: [{ title: "Assets.analysis.title" }] }
  },
  {
    path: getUrlProjectPaths("/nora"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="Nora"
        path={getUrlProjectPaths("/nora")}
        {...props}
        component={componentsRegistry.NoraChat}
      />
    ),
    handle: {
      breadcrumb: [{ title: "mainMenu.nora" }]
    }
  },
  {
    path: "/utils/compare-assets",
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="Comparación"
        path="/utils/compare-assets"
        {...props}
        component={componentsRegistry.CompareAssetsPage}
      />
    ),
    handle: {
      breadcrumb: [{ title: "mainMenu.comparison" }]
    }
  },
  {
    path: getUrlProjectPaths("/program"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="Tasks.title"
        path={getUrlProjectPaths("/program")}
        {...props}
        component={componentsRegistry.TasksPage}
      />
    ),
    handle: {
      dynamicComponents: [
        {
          context: "appHeader",
          requiredPermission: "Tasks.View",
          component: "TasksSearch"
        },
        {
          context: "subHeader",
          requiredPermission: "Tasks.View",
          component: "ProgramDrawer"
        }
      ],

      breadcrumb: [{ title: "Tasks.title" }]
    }
  },
  {
    path: getUrlProjectPaths("/documents"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="Documents.title"
        path={getUrlProjectPaths("/documents")}
        {...props}
        component={componentsRegistry.DocumentsPage}
      />
    ),
    handle: {
      dynamicComponents: [
        {
          context: "subHeader",
          key: "",
          component: "DocumentsListDrawer"
        }
      ],
      breadcrumb: [{ title: "Documents.title" }]
    }
  },
  {
    path: getUrlProjectPaths("/consents"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="Consent.title"
        path={getUrlProjectPaths("/consents")}
        {...props}
        component={componentsRegistry.ConsentPage}
      />
    ),
    handle: {
      breadcrumb: [{ title: "Consent.title" }]
    }
  },
  {
    path: getUrlProjectPaths("/mypriorities"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="MyPriorities.title"
        path={getUrlProjectPaths("/mypriorities")}
        {...props}
        component={componentsRegistry.HomePage}
      />
    ),
    handle: { breadcrumb: [{ title: "MyPriorities.title" }] }
  },
  {
    path: getUrlProjectPaths("/myworkload"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="MyWorkload.title"
        path={getUrlProjectPaths("/myworkload")}
        {...props}
        component={componentsRegistry.HomePage}
      />
    ),
    handle: { breadcrumb: [{ title: "mainMenu.home" }, { title: "MyWorkload.title" }] }
  },
  {
    path: getUrlProjectPaths("/digital-twin"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="DigitalTwin.title"
        path={getUrlProjectPaths("/digital-twin")}
        {...props}
        component={componentsRegistry.AnalyzeDigitalTwins}
      />
    ),
    handle: {
      dynamicComponents: [
        {
          context: "appHeader",
          requiredPermission: "Analytics.View",
          component: "DocumentsSearch"
        }
      ],
      breadcrumb: [{ title: "mainMenu.analyze" }, { title: "DigitalTwin.title" }]
    }
  },
  {
    path: getUrlProjectPaths("/workflows"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="BusinessProcesses.title"
        path={getUrlProjectPaths("/workflows")}
        {...props}
        component={componentsRegistry.BusinessProcessManagementListPage}
      />
    ),
    handle: { breadcrumb: [{ title: "mainMenu.myWorkflows" }] }
  },
  {
    path: getUrlProjectPaths("/assets-definitions"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="AssetsDefinitions.title"
        path={getUrlProjectPaths("/assets-definitions")}
        {...props}
        component={componentsRegistry.AssetsDefinitionsList}
      />
    ),
    handle: { breadcrumb: [{ title: "mainMenu.settings" }, { title: "AssetsDefinitions.title" }] }
  },
  {
    path: getUrlProjectPaths("/workflow/:containerId/lessonslearn"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="businessProcessMgmntList.lessonLearnList"
        path={getUrlProjectPaths("/workflow/:containerId/lessonslearn")}
        {...props}
        component={componentsRegistry.LessonLearnList}
      />
    ),
    handle: {
      dynamicComponents: [
        {
          context: "subHeader",
          key: "",
          component: "GetSide"
        }
      ],
      breadcrumb: [
        { title: "mainMenu.myWorkflows", link: "/workflows" },
        { title: "businessProcessMgmntList.lessonLearn" }
      ]
    }
  },
  {
    path: getUrlProjectPaths("/workflow/:containerId/lessonlearn/:llid"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="businessProcessMgmntList.lessonLearn"
        path={getUrlProjectPaths("/workflow/:containerId/lessonlearn/:llid")}
        {...props}
        component={componentsRegistry.LessonLearnPage}
      />
    ),
    handle: {
      breadcrumb: [
        { title: "mainMenu.myWorkflows", link: "/workflows" },
        { title: "businessProcessMgmntList.lessonLearn" },
        { title: "riskAssessmentMngmntPage.definitions" }
      ]
    }
  },
  {
    path: getUrlProjectPaths("/comments"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="documents.drawer.comments"
        path={getUrlProjectPaths("/comments")}
        {...props}
        component={componentsRegistry.CommentList}
      />
    ),
    handle: {
      breadcrumb: [{ title: "documents.drawer.comments" }]
    }
  },
  {
    path: getUrlProjectPaths("/workflow/:containerId"),
    element: props => {
      return (
        <AppRoute
          exact
          layout={BaseLayout}
          title="Definitions.title"
          path={getUrlProjectPaths("/workflow/:containerId")}
          {...props}
          component={componentsRegistry.BusinessProcessManagementPage}
        />
      );
    },
    handle: {
      store: "businessProcessManagementStore",
      dynamicComponents: [
        {
          context: "subHeader",
          component: "AssetMatching"
        }
      ],
      breadcrumb: [
        { title: "mainMenu.myWorkflows", link: "/workflows" },
        { title: "mainMenu.dynamicbusinessProcessManagement" }
      ]
    }
  },
  {
    path: getUrlProjectPaths("/corporate-repository/:containerId"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="BusinessProcesses.corporateRepository.title"
        path={getUrlProjectPaths("/corporate-repository/:containerId")}
        {...props}
        component={componentsRegistry.EnableCorporateRepositoryPreview}
      />
    ),
    handle: {
      store: "businessProcessManagementStore",
      breadcrumb: [
        { title: "BusinessProcesses.corporateRepository.title", link: "/corporate-repository" },
        { title: "mainMenu.dynamicCorporateRepo" }
      ]
    }
  },
  {
    path: getUrlProjectPaths("/corporate-repository"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="BusinessProcesses.corporateRepository.title"
        path={getUrlProjectPaths("/corporate-repository")}
        {...props}
        component={componentsRegistry.EnableCorporateRepository}
      />
    ),
    handle: {
      breadcrumb: [{ title: "BusinessProcesses.corporateRepository.title" }]
    }
  },
  {
    path: getUrlProjectPaths("/lessonLearn/:containerId"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="BusinessProcesses.LessonLearn.title"
        path={getUrlProjectPaths("/lessonLearn/:containerId")}
        {...props}
        component={componentsRegistry.BusinessProcessLessonLearnPage}
      />
    ),
    handle: { breadcrumb: [{ title: "BusinessProcesses.LessonLearn.title", link: "/lessonLearn" }] }
  },
  {
    path: getUrlProjectPaths("/risk-assessments"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="RiskAssessment.title"
        path={getUrlProjectPaths("/risk-assessments")}
        {...props}
        component={componentsRegistry.RiskAssessmentManagementListPage}
      />
    ),
    handle: {
      breadcrumb: [{ title: "mainMenu.riskAssessment" }]
    }
  },
  {
    path: getUrlProjectPaths("/risk-assessment/:containerId"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="riskAssessmentMngmntPage.definitions"
        path={getUrlProjectPaths("/risk-assessment/:containerId")}
        {...props}
        component={componentsRegistry.RiskAssessmentManagementPage}
      />
    ),
    handle: {
      store: "riskAssessmentManagementStore",

      dynamicComponents: [
        {
          context: "subHeader",
          component: "ProcessMatching"
        }
      ],
      breadcrumb: [
        { title: "RiskAssessment.title", link: "/risk-assessments" },
        { title: "mainMenu.dynamicriskAssessmentMngmntPage" }
      ]
    }
  },
  {
    path: getUrlProjectPaths("/evidence-manager"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="EvidenceManager.title"
        path={getUrlProjectPaths("/evidence-manager")}
        {...props}
        component={componentsRegistry.EvidenceManagerList}
      />
    ),
    handle: { breadcrumb: [{ title: "mainMenu.settings" }, { title: "EvidenceManager.title" }] }
  },
  {
    path: getUrlProjectPaths("/settings"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="SettingsPage.title"
        path={getUrlProjectPaths("/settings")}
        {...props}
        component={componentsRegistry.TenantsView}
      />
    ),
    handle: { breadcrumb: [{ title: "SettingsPage.title" }] }
  },
  {
    path: "/utils/projects",
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="projects.ManageProjects"
        path="/utils/projects"
        {...props}
        component={componentsRegistry.ProjectList}
      />
    ),
    handle: { breadcrumb: [{ title: "mainMenu.settings" }, { title: "projects.ManageProjects" }] }
  },
  {
    path: "/utils/project/:subpage?/:tab?",
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="projects.ManageProject"
        path="/utils/project/:subpage?/:tab?"
        {...props}
        component={componentsRegistry.ProjectView}
      />
    ),
    handle: { breadcrumb: [{ title: "SettingsPage.title" }, { title: "projects.ManageProject" }] }
  },
  {
    path: "/utils/tenants",
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="projects.ManageTenants"
        path="/utils/tenants"
        {...props}
        component={componentsRegistry.TenantsList}
      />
    ),
    handle: { breadcrumb: [{ title: "mainMenu.settings" }, { title: "projects.ManageTenants" }] }
  },
  {
    path: "/utils/tenant/:containerId/:subpage?",
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="projects.ManageTenant"
        path="/utils/tenant/:containerId/:subpage?"
        {...props}
        component={componentsRegistry.TenantView}
      />
    ),
    handle: { breadcrumb: [{ title: "mainMenu.settings" }, { title: "projects.ManageTenant" }] }
  },
  {
    path: "/utils/notifications",
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="mainMenu.notifications"
        path="/utils/notifications"
        {...props}
        component={componentsRegistry.NotificationComponent}
      />
    ),
    handle: {
      dynamicComponents: [
        {
          context: "subHeader",
          component: "NotificationsHeaderSide"
        }
      ],

      breadcrumb: [{ title: "mainMenu.notifications" }]
    }
  },
  {
    path: getUrlProjectPaths("/automate/workflows/executions"),
    element: props => {
      return (
        <AppRoute
          exact
          layout={BaseLayout}
          title="automate.AutomateExecutions"
          path={getUrlProjectPaths("/automate/workflows/executions")}
          {...props}
          component={componentsRegistry.AutomateWorkflowsExecutionsList}
        />
      );
    },
    handle: {
      breadcrumb: [{ title: "mainMenu.execution" }]
    }
  },
  {
    path: getUrlProjectPaths("/automate/workflows"),
    element: props => {
      return (
        <AppRoute
          exact
          layout={BaseLayout}
          title="mainMenu.automate"
          path={getUrlProjectPaths("/automate/workflows")}
          {...props}
          component={componentsRegistry.AutomateWorkflowsList}
        />
      );
    },
    handle: {
      breadcrumb: [{ title: "mainMenu.automatedFlows" }]
    }
  },
  {
    path: getUrlProjectPaths("/automate/workflow/:containerId"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="mainMenu.automate"
        path={getUrlProjectPaths("/automate/workflow/:containerId")}
        {...props}
        component={componentsRegistry.AutomateWorkflowsView}
      />
    ),
    handle: {
      store: "automateWorkflowsStore",
      dynamicComponents: [],
      breadcrumb: [
        { title: "mainMenu.automatedFlows", link: "/automate/workflows" },
        { title: "mainMenu.dynamicWorkFlowName" }
      ]
    }
  },

  {
    path: getUrlProjectPaths("/automate/templates"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="mainMenu.automate"
        path={getUrlProjectPaths("/automate/templates")}
        {...props}
        component={componentsRegistry.AutomateWorkflowTemplateList}
      />
    ),
    handle: { breadcrumb: [{ title: "mainMenu.templates" }] }
  },
  {
    path: getUrlProjectPaths("/automate/template/:containerId"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="mainMenu.automate"
        path={getUrlProjectPaths("/automate/template/:containerId")}
        {...props}
        component={componentsRegistry.AutomateWorkflowTemplateView}
      />
    ),
    handle: {
      store: "automateWorkflowTemplateStore",
      breadcrumb: [
        { title: "mainMenu.templates", link: "/automate/templates" },
        { title: "mainMenu.dynamicTemplateName" }
      ]
    }
  },
  {
    path: "/utils/login",
    element: props => (
      <AppRoute
        exact
        layout={LayoutEmpty}
        title="Login"
        path="/utils/login"
        {...props}
        component={componentsRegistry.Login}
      />
    )
  },
  {
    path: "/utils/register",
    element: props => (
      <AppRoute
        exact
        layout={LayoutEmpty}
        title="Register"
        path="/utils/register"
        {...props}
        component={componentsRegistry.Register}
      />
    )
  },
  {
    path: "/utils/reset-password/:code?",
    element: props => (
      <AppRoute
        exact
        layout={LayoutEmpty}
        title="Reset password"
        path="/utils/reset-password/:code?"
        {...props}
        component={componentsRegistry.ResetPassword}
      />
    )
  },
  {
    path: "/utils/login/:jwtToken",
    element: props => (
      <AppRoute
        exact
        layout={LayoutEmpty}
        title="Login"
        path="/utils/login/:jwtToken"
        {...props}
        component={componentsRegistry.Login}
      />
    )
  },
  {
    path: "/utils/procore/login-complete/:jwtToken",
    element: props => (
      <AppRoute
        exact
        layout={LayoutEmpty}
        title="Procore Login"
        path="/utils/procore/login-complete/:jwtToken"
        {...props}
        component={componentsRegistry.LoginProcore}
      />
    )
  },
  {
    path: "/utils/procore/login/:tenantId",
    element: props => (
      <AppRoute
        exact
        layout={LayoutEmpty}
        title="Procore Login"
        path="/utils/procore/login/:tenantId"
        {...props}
        component={componentsRegistry.LoginProcore}
      />
    )
  },
  {
    path: "/utils/activate/:code",
    element: props => (
      <AppRoute
        exact
        layout={LayoutEmpty}
        title="Activate"
        path="/utils/activate/:code"
        {...props}
        component={componentsRegistry.Activate}
      />
    )
  },
  {
    path: getUrlProjectPaths("/collect/forms/:containerId"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="collect.myFormsNew"
        path={getUrlProjectPaths("/collect/forms/:containerId")}
        {...props}
        component={componentsRegistry.FormBuilder}
      />
    ),
    handle: {
      store: "formBuilderStore",

      dynamicComponents: [
        {
          context: "subHeader",
          component: "FormBuilderTemplateDrawer"
        }
      ],
      breadcrumb: [{ title: "mainMenu.myForms", link: "/collect/forms" }, { title: "mainMenu.dynamicFormName" }]
    }
  },
  {
    path: getUrlProjectPaths("/collect/forms"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="mainMenu.myForms"
        path={getUrlProjectPaths("/collect/forms")}
        {...props}
        component={componentsRegistry.FormBuilderList}
      />
    ),
    handle: {
      dynamicComponents: [
        {
          context: "subHeader",
          component: "FormBuilderListDrawer"
        }
      ],
      breadcrumb: [{ title: "mainMenu.myForms" }]
    }
  },
  {
    path: getUrlProjectPaths("/collect/entries"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="mainMenu.entries"
        path={getUrlProjectPaths("/collect/entries")}
        {...props}
        component={componentsRegistry.CollectSubmissionsList}
      />
    ),
    handle: {
      dynamicComponents: [
        {
          context: "subHeader",
          component: "CollectSubmissionListDrawer"
        }
      ],
      breadcrumb: [{ title: "mainMenu.entries" }]
    }
  },
  {
    path: getUrlProjectPaths("/collect/entry/hub/:assetId/:containerId"),
    element: props => (
      <AppRoute
        exact
        layout={BaseLayout}
        title="collect.modifyEntry"
        path={getUrlProjectPaths("/collect/entry/hub/:assetId/:containerId")}
        {...props}
        component={componentsRegistry.CollectSubmissionHubView}
      />
    )
  },

  {
    path: getUrlProjectPaths("/collect/entry/:containerId"),
    element: props => {
      // const location = useLocation();
      // const queryParams = new URLSearchParams(location.search);
      // const isNewEntry = queryParams.has("folderId");
      // const isNewViewEntry = queryParams.has("view");
      // const dynamicTitle = isNewEntry ? "collect.add" : isNewViewEntry ? "collect.viewEntry" : "collect.modifyEntry";
      const isNewEntry = location.pathname.includes("new");
      const dynamicTitle = isNewEntry ? "mainMenu.entry" : "mainMenu.dynamicEntryName";
      return (
        <AppRoute
          exact
          layout={BaseLayout}
          title="collect.modifyEntry"
          // title={`${dynamicTitle}`}
          path={getUrlProjectPaths("/collect/entry/:containerId")}
          {...props}
          component={componentsRegistry.FormSubmissionView}
        />
      );
    },
    handle: {
      breadcrumb: location => {
        const isNewEntry = location.pathname.includes("new");
        const dynamicBreadcrumbTitle = isNewEntry ? "mainMenu.entry" : "mainMenu.dynamicEntryName";

        return [{ title: "mainMenu.entries", link: "/collect/entries" }, { title: dynamicBreadcrumbTitle }];
      }
    }
    // handle: {
    //   breadcrumb: [{ title: "mainMenu.entries", link: "/collect/entries" }, { title: dynamicEntryName }]

    //   // breadcrumb: [{ title: "mainMenu.entries", link: "/collect/entries" }, { title: "mainMenu.entry" }]
    // }
  },
  {
    path: "/utils/init-demo",
    element: props => (
      <AppRoute
        exact
        layout={LayoutEmpty}
        title="Init demo data"
        path="/utils/init-demo"
        {...props}
        component={componentsRegistry.InitDemo}
      />
    )
  },
  {
    path: "/utils/generate-svg/bowtie/:containerId/:jwtToken",
    element: props => (
      <AppRoute
        exact
        layout={LayoutEmpty}
        title="Generate Bowtie SVG"
        path="/utils/generate-svg/bowtie/:containerId/:jwtToken"
        {...props}
        component={componentsRegistry.BowtieSvgGenerator}
      />
    )
  },
  {
    path: "/utils/generate-svg/bpmn/:containerId",
    element: props => (
      <AppRoute
        exact
        layout={LayoutEmpty}
        title="Generate BPMN SVG"
        path="/utils/generate-svg/bpmn/:containerId"
        {...props}
        component={componentsRegistry.InitDemo}
      />
    )
  },
  {
    path: "/utils/generate-svg/bpmn/:containerId/:jwtToken",
    element: props => (
      <AppRoute
        exact
        layout={LayoutEmpty}
        title="Generate BPMN SVG"
        path="/utils/generate-svg/bpmn/:containerId/:jwtToken"
        {...props}
        component={componentsRegistry.InitDemo}
      />
    )
  },
  {
    path: "/utils/dxf/:containerId",
    element: props => (
      <AppRoute
        layout={BaseLayout}
        title="Dxf Editor"
        path="/utils/dxf/:containerId"
        {...props}
        component={componentsRegistry.DxfEditor}
      />
    )
  },
  {
    path: "/utils/query",
    element: props => (
      <AppRoute
        layout={LayoutEmpty}
        title="Query"
        path="/utils/query"
        {...props}
        component={componentsRegistry.QueryBuilderDev}
      />
    )
  },
  {
    path: "/utils/compare-assets",
    element: props => (
      <AppRoute
        layout={BaseLayout}
        title="Comparación"
        path="/utils/compare-assets"
        {...props}
        component={componentsRegistry.CompareAssetsPage}
      />
    )
  },
  {
    path: "/utils/view-builder",
    element: props => (
      <AppRoute
        exact={true}
        layout={BaseLayout}
        title="View builder"
        path="/utils/view-builder"
        {...props}
        component={componentsRegistry.ViewBuilder}
      />
    )
  },
  {
    path: "/utils/pdf",
    element: props => (
      <AppRoute
        exact
        layout={LayoutEmpty}
        title="PDF testing"
        path="/utils/pdf"
        {...props}
        component={componentsRegistry.ViewGenerator}
      />
    )
  },
  {
    path: "/utils/image-annot",
    element: props => (
      <AppRoute
        exact
        layout={LayoutEmpty}
        title="Image annot testing"
        path="/utils/image-annot"
        {...props}
        component={componentsRegistry.ImageAnnotatorComponent}
      />
    )
  },
  {
    path: "/404",
    element: props => (
      <AppRoute
        layout={LayoutEmpty}
        title="Page not found"
        path="/404"
        {...props}
        component={componentsRegistry.EmptyPage}
      />
    )
  },
  {
    path: "*",
    element: props => <AppRoute layout={BaseLayout} title="" {...props} component={componentsRegistry.ViewGenerator} />
  }
];

export default routes;
