import { makeAutoObservable } from "mobx";
import { forkJoin } from "rxjs";
import BackendService from "../../Common/BackendService";
import GraphQlService from "../../Common/GraphQlService";

import { message, notification } from "antd";
import { isEmpty, uniq, isUndefined, cloneDeep, debounce } from "lodash";
import { gql } from "@apollo/client";
import i18next from "i18next";

class RiskAssessmentManagementStore {
  graphQlService = new GraphQlService();
  containersApi = new BackendService(`containers`);
  organizationsApi = new BackendService(`organizations`);

  isLoading = false;
  savePending = false;
  history;

  containers = [];
  project = undefined;
  fields = undefined;

  businessProcesses = [];
  processMatchingVisible = false;

  assetsDefinitions = undefined;

  canModify = false;

  processMatchingRunning = undefined;

  updateContainerDebounce;

  modificationHistoryVisible = undefined;

  noraSessionId = undefined;
  bowtie = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    this.graphQlService
      .client()
      .query({
        query: this.graphQlService.stringToGql(
          `{ assetsDefinitions { id symbol isVirtual isEvidence value fields { symbol value type dbTableLocation label } } }`
        )
      })
      .then(r => {
        this.assetsDefinitions = r.data.assetsDefinitions.map(x => ({
          ...x,
          fields: x.fields.map(y => ({
            ...y,
            key: y.symbol,
            values: y.value ? JSON.parse(y.value) : null,
            __typename: undefined
          }))
        }));
      });
  };

  loadList() {
    this.isLoading = true;
    forkJoin({
      projects: this.graphQlService.client().query({
        query: this.graphQlService.stringToGql(
          `{ riskAssessments(where: [{ path: "Type", comparison: EQUAL, value: "WorldDefinitionRiskAssessment" }]) { id rootId payload  name type  modifiedDateTime modifiedBy{ id username } createdDateTime createdBy { id username } } }`
        )
      })
    }).subscribe(r => {
      this.containers = r.projects.data.riskAssessments.map(x => ({ ...x, payload: JSON.parse(x.payload || "{}") }));
      this.isLoading = false;
    });
  }

  loadProject(id) {
    this.isLoading = true;
    this.project = undefined;
    this.bowtie = undefined;
    this.noraSessionId = undefined;
    this.graphQlService
      .client()
      .query({
        query: this.graphQlService.stringToGql(
          `{ containers(where: [{ path: "RootId", comparison: EQUAL, value: "${id}" }]) { id, rootId, name, payload, bowtie, assignedBusinessProcesses { rootId name bpmn } } }`
        )
      })
      .then(r => {
        if (r.data?.containers?.length === 0) return;

        var parsedPoject = { ...r.data.containers[0] };
        if (r.data.containers[0].payload) parsedPoject.payload = JSON.parse(r.data.containers[0].payload);
        this.project = parsedPoject;
        this.updateContainerDebounce = debounce(() => this.updateContainer(), 2000);
        this.bowtie = r.data.containers[0]?.bowtie;
        this.isLoading = false;

        this.setFields(parsedPoject);
        this.listenForDetectionUpdates(parsedPoject);
        // this.isLoading = false;
      });
  }

  listenForDetectionUpdates = project => {
    var watchRef = this.graphQlService.clientWs().watchQuery({
      fetchPolicy: "no-cache",
      query: gql`
        {
          __typename
        }
      `
    });
    watchRef.subscribeToMore({
      document: gql`subscription listener { asyncActionProgressUpdated(id: "${project.rootId}") { actionId steps{ stepId progress } } }`,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return;

        this.processMatchingRunning = subscriptionData.data.asyncActionProgressUpdated.steps;
        this.isAssetMatchingInProgress = this.processMatchingRunning[0].progress < 100;
        if (this.processMatchingRunning[0].progress >= 100) this.processMatchingRunning = undefined;
        this.isLoading = false;
      }
    });
  };

  createContainer(name, parentId, createMessage, createDescription) {
    if (this.canModify === false) return;

    this.isLoading = true;
    if (isEmpty(name)) return;
    this.containersApi
      .post(``, { name: name, type: "WorldDefinitionRiskAssessment", parentRootId: parentId })
      .then(() => {
        notification["success"]({
          message: createMessage,
          description: createDescription + " " + name
        });
        this.isLoading = false;
        this.loadList();
      });
  }

  updateContainer = () => {
    this.graphQlService
      .post(`mutation mutate($data: UpdateRiskAssessmentCommand){ riskAssessmentUpdate(data: $data) }`, {
        data: {
          id: this.project.id,
          processMatchingType: this.project.payload.ProcessMatchingType,
          processMatchingConfig: JSON.stringify(this.project.payload.ProcessMatchingConfig)
        }
      })
      .then(() => (this.savePending = false));
  };

  delete(id) {
    if (this.canModify === false) return;

    this.isLoading = true;
    this.containersApi.delete(`${id}`).then(() => {
      this.loadList();
    });
  }

  setFields = project => {
    this.fields = undefined;
    if (project?.payload || project?.payload?.ProcessMatchingType) return;
    if (!this.assetsDefinitions) return;
    var assetDef = this.assetsDefinitions.find(x => x.symbol === project.payload?.ProcessMatchingType);
    if (assetDef) this.fields = assetDef.fields;
  };

  saveBowtieModel = (containerId, model, svg, saveMessage) => {
    if (this.canModify === false) return;

    this.organizationsApi
      .post(`risk-assessment/bowtie`, { containerId: containerId, payload: JSON.stringify(model), svg })
      .then(() => {
        message.success(saveMessage);
      });
  };

  getAssets() {
    var bowtie = JSON.parse(this.project.bowtie);
    if (bowtie && bowtie.nodes && this.assetsDefinitions) {
      var assetsSelected = uniq(
        [].concat.apply(
          [],
          bowtie.nodes.map(x => x.occurrenceConditionAssetType).filter(x => !isUndefined(x))
        )
      ).map(x => x.toLowerCase());
      var assetsFiltered = this.assetsDefinitions.filter(x => assetsSelected.includes(x.symbol.toLowerCase()));
      return assetsFiltered;
    } else {
      return [];
    }
  }

  clone = (id, clonedMessage) => {
    if (this.canModify === false) return;

    this.isLoading = true;
    this.organizationsApi.post(`risk-assessment/clone`, id).then(() => {
      message.success(clonedMessage);
      this.loadList();
    });
  };

  rename = (id, name, renamedMessage) => {
    if (this.canModify === false) return;

    this.isLoading = true;
    this.containersApi.put(`rename`, { containerId: id, name: name }).then(() => {
      message.success(renamedMessage);
      this.loadList();
    });
  };

  makePublic = id => {
    this.isLoading = true;
    this.graphQlService
      .post(`mutation mutate($data: BusinessProcessMakePublicCommand){ businessProcessMakePublic(data: $data) }`, {
        data: { containerId: id }
      })
      .then(
        r => {
          if (r.errors) message.error(i18next.t("businessProcessMgmntList.errors." + r.errors[0].extensions.data.CODE));
          this.loadList();
        },
        () => {
          this.isLoading = false;
        }
      );
  };

  makePrivate = id => {
    this.isLoading = true;
    this.graphQlService
      .post(`mutation mutate($data: BusinessProcessMakePrivateCommand){ businessProcessMakePrivate(data: $data) }`, {
        data: { containerId: id }
      })
      .then(
        r => {
          if (r.errors) message.error(i18next.t("businessProcessMgmntList.errors." + r.errors[0].extensions.data.CODE));
          this.loadList();
        },
        () => {
          this.isLoading = false;
        }
      );
  };

  executeProcessMatching = () => {
    if (this.canModify === false) return;

    this.isLoading = true;
    this.organizationsApi.get(`risk-assessment/process-matching/execute?id=${this.project.rootId}`).then(() => {
      message.success(i18next.t("riskAssessmentMngmntStore.processMatchingMessage"));
    });
  };

  cancelProcessMatching = cancelMessage => {
    this.organizationsApi.get(`risk-assessment/process-matching/cancel?id=${this.project.rootId}`).then(() => {
      message.success(cancelMessage);
      this.processMatchingRunning = undefined;
      this.isLoading = false;
    });
  };
  setProcessMatchingVisible = ProcessMatching => {
    this.processMatchingVisible = ProcessMatching;
  };
}
export default new RiskAssessmentManagementStore();
