import React, { useEffect } from "react";

import { ApartmentOutlined } from "@ant-design/icons";

import _ from "lodash";
import { withTranslation } from "react-i18next";

import FormSubmissionView from "../../Submissions/view/FormSubmissionView";

const CollectFormParentView = props => {
  return (
    <>
      {props.isSidebarElement && (
        <>
          <div style={{ textAlign: "center" }}>
            <ApartmentOutlined />
          </div>
        </>
      )}

      {props.isBuilder && (
        <>
          {props.label && (
            <div>
              <label>{props.label}</label>
            </div>
          )}
          <br />
          <ApartmentOutlined />
          <br />
          <br />
        </>
      )}

      {props.isSubmission && (
        <>
          {props.submissionId && (
            <FormSubmissionView
              {...props}
              submissionId={props.submissionId}
              disabled={true}
              canModify={false}
              isParent={true}
            />
          )}
        </>
      )}
    </>
  );
};

export default withTranslation()(CollectFormParentView);
