import Icon from "@ant-design/icons";
import React from "react";

const Line = () => (
  <svg viewBox="0 0 16 16" width="1em" height="1em">
    <path d="M1.089 14.206 14.651.644l.707.707L1.796 14.914z" />
  </svg>
);

export const LineIcon = (props) => <Icon component={Line} {...props} />;

const Text = () => (
  <svg width="1em" height="1em" viewBox="0 0 9 12">
    <path d="M 2.599528,10.077937 V 2.7992575 H 0 v -1.48203 h 6.961955 v 1.48203 H 4.368403 v 7.2786795 z" />
    <path d="M 9.38642,0.28786749 V 11.564707" fill="#4d4d4d" strokeWidth="1" stroke="#757575" />
  </svg>
);

export const TextIcon = (props) => <Icon component={Text} {...props} />;

const Rectangle = () => (
  <svg data-name="Layer 1" viewBox="0 0 16 16" width="1em" height="1em">
    <path d="M16 13.5H0v-10h16zm-15-1h14v-8H1z" />
  </svg>
);

export const RectangleIcon = (props) => <Icon component={Rectangle} {...props} />;

const Polygon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-hexagon"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path></svg>
);

export const PolygonIcon = (props) => <Icon component={Polygon} {...props} />;

const Circle = () => (
  <svg viewBox="0 0 2048 2048" width="1em" height="1em">
    <circle cx="1024" cy="1024" r="900" fill="none" stroke="#000000" strokeWidth="150" />
  </svg>
);

export const CircleIcon = (props) => <Icon component={Circle} {...props} />;

const Grid = () => (
  <svg viewBox="0 0 16 16" width="1em" height="1em">
    <path d="M.5 8.5v-1h15v1z" />
    <path d="M8.5 15.5h-1V.5h1z" />
    <path d="M.5 3.5v-1h15v1zM7.5 15.5V.5m1 0v15M.5 13.5v-1h15v1zM7.5 15.5V.5m1 0v15" />
    <path d="M3.5 15.5h-1V.5h1zM7.5 15.5V.5m1 0v15M13.5 15.5h-1V.5h1z" />
  </svg>
);

export const GridIcon = (props) => <Icon component={Grid} {...props} />;

const Layers = () => (
  <svg viewBox="0 0 32 32" width="1em" height="1em">
    <path d="m4.35 14.29 9.4 5.13a4.65 4.65 0 0 0 4.5 0 1 1 0 1 0-1-1.75 2.69 2.69 0 0 1-2.58 0l-9.4-5.13a.63.63 0 0 1 0-1.08l9.4-5.13a2.69 2.69 0 0 1 2.58 0l9.4 5.13a.63.63 0 0 1 0 1.08l-5.17 2.82a1 1 0 1 0 1 1.75l5.17-2.82a2.62 2.62 0 0 0 0-4.58l-9.4-5.13a4.62 4.62 0 0 0-4.5 0l-9.4 5.13a2.62 2.62 0 0 0 0 4.58z" />
    <path d="m27.55 16.52-10.1 5.13a3.19 3.19 0 0 1-2.9 0l-10.1-5.13a1 1 0 0 0-.9 1.78l10.09 5.14a5.25 5.25 0 0 0 4.72 0l10.09-5.14a1 1 0 1 0-.9-1.78z" />
    <path d="m27.55 20.52-10.1 5.13a3.19 3.19 0 0 1-2.9 0l-10.1-5.13a1 1 0 0 0-.9 1.78l10.09 5.14a5.25 5.25 0 0 0 4.72 0l10.09-5.14a1 1 0 1 0-.9-1.78z" />
  </svg>
);

export const LayersIcon = (props) => <Icon component={Layers} {...props} />;

const Invert = () => (
  <svg viewBox="0 0 512 512" width="1em" height="1em">
    <path d="M406.1,150.1L256,0L105.9,150.1c-82.8,82.8-82.8,217.3,0,300.1C146.9,492,201.4,512,256,512   s109.1-20.9,150.1-61.8C488.8,367.4,488.8,232.8,406.1,150.1z M256,459.3c-42.7,0-82.8-16.4-112.8-46.4c-30-30-46.4-70-46.4-112.8   s16.4-82.8,46.4-112.8L256,75.5V459.3z" />
  </svg>
);

export const InvertIcon = (props) => <Icon component={Invert} {...props} />;

const Annotation = () => (
  <svg viewBox="0 0 1000 1000" width="1em" height="1em">
    <path d="M10,146.8v651.4h233l175,175l175-175h234V395.3h-48.9v354.1H572.8L417.9,904.3L263,749.4H58.7v-554h509.1v-48.6H10z" />
    <path d="M916.1,26.8l-61.5,61.4l73.8,73.7l61.5-61.4L916.1,26.8z" />
    <path d="M555.1,534.6l-73.8-73.7l341.1-340.6l73.8,73.7L555.1,534.6z" />
    <path d="M516.7,571L421,594.9l23.9-95.5L516.7,571z" />
  </svg>
);

export const AnnotationIcon = (props) => <Icon component={Annotation} {...props} />;
