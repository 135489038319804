import React from 'react';

import { FieldStringOutlined } from '@ant-design/icons';
import { Flex, Typography } from 'antd';

import { isNil } from 'lodash';
import { withTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const CollectFormText = (props) => {
  return (
    <Flex justify="start">
      {props.isSidebarElement && (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <FieldStringOutlined />
        </div>
      )}

      {props.isBuilder && (
        <Flex vertical>
          {props.label && (
            <Title
              level={5}
              style={{
                textTransform: 'uppercase',
                fontSize: '0.8em',
                color: '#90a8be',
                fontWeight: 'bolder',
                textAlign: 'left',
              }}
            >
              {props.label}
            </Title>
          )}
          {!isNil(props?.value) && <Text style={{ textAlign: 'left' }}>{props.value}</Text>}
        </Flex>
      )}
    </Flex>
  );
};

export default withTranslation()(CollectFormText);
