import React, { useEffect, useState } from "react";
import { Form, Input } from "antd-mobile";

const FormInputMobile = props => {
  const [value, setValue] = useState("###");
  const [validationError, setValidationError] = useState(undefined);

  useEffect(() => {
    setValue(props.storeValue ? props.storeValue : props.value);
    validate();
  }, []);

  useEffect(() => {
    if (props.storeValue === undefined && props.value !== undefined && props.updateValue)
      props.updateValue(props.id, props.value);
    validate();
  }, [props.value]);

  const normalizeStr = str => str.replace(" ", "").toUpperCase();

  useEffect(() => validate(), [value]);

  const validate = () => {
    if (!props.identifier) return;

    setValidationError(undefined);

    if (!value || value === "") {
      setValidationError({ status: "error", text: "Missing value" });
      if (props.setValidityStatus) props.setValidityStatus(props.id, false);
    } else {
      setValidationError({ status: undefined, text: "" });
      if (props.setValidityStatus) props.setValidityStatus(props.id, true);
    }
  };

  if (value === "###") return <></>;
  return (
    <>
      <Form.Item
        name={props.id}
        label={
          <>
            {props.label} {props.isRequired && <span style={{ color: "red", marginLeft: 2 }}>(*)</span>}
          </>
        }
        rules={[{ message: props.placeholder }]}
        validateStatus={validationError?.status}
        hasFeedback
        help={validationError?.text}
      >
        <Input
          type="text"
          placeholder={props.placeholder}
          key={props.id}
          defaultValue={value}
          onChange={e => setValue(e)}
          onBlur={() => {
            props.updateValue && props.updateValue(props.id, value);
          }}
          disabled={props.disabled}
          style={{
            border: "1px solid #90A8BE",
            borderRadius: "4px",
            padding: 5,
            borderColor: validationError?.status ? "red" : "#90A8BE"
          }}
        />
      </Form.Item>
      {props.dupes &&
        props.identifier &&
        props.dupes.some(
          d =>
            normalizeStr(d.value) === normalizeStr(props.storeValue) &&
            normalizeStr(d.name) === normalizeStr(props.label)
        ) && <h4 style={{ color: "#ec547a", marginBottom: 20 }}>{props.t("collect.duplicatedIdentifier")}</h4>}
    </>
  );
};

export default FormInputMobile;
