import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import GraphQlService from "../GraphQlService";
import { Device } from "@capacitor/device";
import { Button, Input, Modal, Spin, message } from "antd";

const ReportIssue = ({ t }) => {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [agent, setAgent] = useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const id = await Device.getId();
    const info = await Device.getInfo();
    const language = await Device.getLanguageCode();

    setAgent(JSON.stringify({ id: id, info: info, language: language }));
  };

  const submit = () => {
    if (!content) return;

    setIsLoading(true);
    const graphQlService = new GraphQlService();
    graphQlService
      .post(
        `mutation mutate($content: String, $agent: String){ supportIssueTicketCreate(content: $content, agent: $agent) }`,
        {
          content: content,
          agent: agent
        }
      )
      .then(() => {
        message.success(t("mobileMenu.issueSubmitted"));
        setIsLoading(false);
        setContent("");
        Modal.destroyAll();
      });
  };

  return (
    <div>
      <Spin spinning={isLoading}>
        <Input.TextArea rows={4} value={content} onChange={e => setContent(e.target.value)} />
        <Button type="primary" style={{ width: "100%", marginTop: 3 }} onClick={() => submit()}>
          {t("mobileMenu.submit")}
        </Button>
      </Spin>
    </div>
  );
};
export default withTranslation()(observer(ReportIssue));
