import { message } from "antd";
import { makeAutoObservable, toJS } from "mobx";
import { Toast } from "antd-mobile";

import GraphQlService from "../../../Common/GraphQlService";
import i18next from "i18next";
import CollectSubmissionStore from "../FormSubmissionStore";

export class CollectSubmissionListStore {
  isLoading = false;
  isFiltering = false;

  graphQlService = new GraphQlService();

  submissions = [];
  totalSubmissions = 0;

  page = 1;
  pageSize = 15;

  jqlSearch = null;

  currentMobileParentId = undefined;

  currentPlatform = localStorage.getItem("platform");

  canAddStandaloneEntry = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadSubmissions = async (folderId, updateList = true) => {
    this.isLoading = true;
    this.isFiltering = false;

    if (folderId === "ffffffff-ffff-ffff-ffff-ffffffffffff") {
      folderId = null;
    }

    var query = `{ formSubmissions(platform: ${this.currentPlatform === "mobile" ? '["mobile"]' : '["web", "mobile"]'
      }, jqlSearch: ${this.jqlSearch ? `"${JSON.stringify(this.jqlSearch).replaceAll('"', '\\"')}"` : null}, page: ${this.page
      }, pageSize: ${this.pageSize || 15}, folderId: ${!folderId ? null : `"${folderId}"`
      } ) { submission { id platform structure values createdDateTime title subTitle } template { name childTemplateId } createdBy { username } childSubmissions parentSubmission } }`;
    const submissionsQueryResult = await this.graphQlService.get(query);
    var submissions = submissionsQueryResult.data.formSubmissions.map(x => ({
      ...x,
      ...x.submission,
      children: x.childSubmissions.length > 0 ? x.childSubmissions : null
    }));

    this.totalSubmissions = submissionsQueryResult.data.totalEntries;

    if (!updateList) {
      this.isLoading = false;
      return submissions;
    }

    this.submissions = submissions;
    this.isLoading = false;
  };

  loadMoreSubmissions = async (page, folderId = undefined) => {
    if (folderId === "ffffffff-ffff-ffff-ffff-ffffffffffff") {
      folderId = null;
    }
    const submissionsQueryResult = await this.graphQlService.get(
      `{ formSubmissions(platform: ["mobile"], page: ${page}, pageSize: 6, folderId: ${!folderId ? null : `"${folderId}"`
      } )
            { submission { id platform structure values createdDateTime title subTitle } template { name childTemplateId } createdBy { username } childSubmissions parentSubmission } }`
    );
    this.totalSubmissions = submissionsQueryResult.data.totalEntries;

    return submissionsQueryResult.data.formSubmissions.map(x => ({
      ...x,
      ...x.submission,
      children: x.childSubmissions.length > 0 ? x.childSubmissions : null
    }));
  };

  filterSubmissions = async (type, value, folderId = undefined) => {
    if (folderId === "ffffffff-ffff-ffff-ffff-ffffffffffff") {
      folderId = null;
    }
    this.isFiltering = true;
    this.isLoading = true;
    const submissionsQueryResult = await this.graphQlService.get(
      `{ formSubmissions(platform: ["mobile"], ${type}: ${type === "fuzzySearch" ? '"' + value + '"' : JSON.stringify(value)
      }, folderId: ${!folderId ? null : `"${folderId}"`
      }) { submission { id platform structure values createdDateTime title subTitle } template { name childTemplateId } createdBy { username } childSubmissions parentSubmission } }`
    );
    this.submissions = submissionsQueryResult.data.formSubmissions.map(x => ({
      ...x,
      ...x.submission,
      children: x.childSubmissions.length > 0 ? x.childSubmissions : null
    }));
    this.totalSubmissions = submissionsQueryResult.data.totalEntries;

    this.isLoading = false;
  };

  loadChildSubmissions = async (submissionId, updateList = true) => {
    this.isLoading = true;
    const submissionsQueryResult = await this.graphQlService.get(
      `{ formChildSubmissions(parentId: "${submissionId}") { submission { id platform structure values createdDateTime } template { name childTemplateId } createdBy { username } childSubmissions parentSubmission } }`
    );
    this.isLoading = false;
    const childSubs = submissionsQueryResult.data.formChildSubmissions.map(x => ({
      ...x,
      ...x.submission,
      children: x.childSubmissions.length > 0 ? x.childSubmissions : null
    }));
    if (!updateList) return childSubs;
    this.updateSubmissionsList(this.submissions, submissionId, childSubs);
    // this.submissions = [...this.submissions];
    const currentPlatform = localStorage.getItem("platform");
    this.submissions = currentPlatform === "mobile" ? [...childSubs] : [...this.submissions];
  };

  canAddStandaloneEntryQuery = async () => {
    this.canAddStandaloneEntry =
      (await this.graphQlService.get(`{ formBuildersActive(platform: "mobile") { id } }`)).data.formBuildersActive
        .length > 0;
  };

  updateSubmissionsList = (list, submissionId, value) => {
    for (let i = 0; i < list.length; i++) {
      var item = list[i];
      if (item.id === submissionId) item.children = value;

      if (item.children) this.updateSubmissionsList(item.children, submissionId, value);
    }
  };

  clone = async id => {
    this.isLoading = true;

    var payload = { submissionId: id };
    const response = await this.graphQlService.post(
      `mutation mutate($data: SubmittedFormCloneCommand){ formSubmissionClone(data: $data) }`,
      { data: payload }
    );
    if (response.errors) this.graphQlService.displayErrors(response.errors);
    else message.success(`Submission has been cloned!`);
    this.isLoading = false;
    return response.data.formSubmissionClone;
  };

  activateChildren = async id => {
    this.isLoading = true;
    const response = await this.graphQlService.post(
      `mutation mutate($data: ActivateChildrenSubmissionFormCommand){ activeFormSubmissionsChildren(data: $data) }`,
      { data: { submissionId: id } }
    );
    if (response.errors) this.graphQlService.displayErrors(response.errors);
    else {
      message.success(`Sub submission has been created!`);
      window.location.assign(
        `/${localStorage.getItem(`projectName`)}/collect/entry/${response.data.activeFormSubmissionsChildren}`
      );
    }
  };

  deleteForm = async (id, parentSubmissionId = undefined) => {
    this.isLoading = true;
    await this.deleteFormExecute(id);
    this.currentPlatform === "web" && message.success(i18next.t("collect.entryDeleted"));
    this.currentPlatform === "mobile" &&
      Toast.show({
        content: i18next.t("collect.entryDeleted"),
        duration: 1500,
        position: "top"
      });
    if (!parentSubmissionId) {
      var folderId = undefined;
      var currentSelectedFolder = localStorage.getItem(`collectSelectedFolder`);
      if (currentSelectedFolder != null) folderId = JSON.parse(currentSelectedFolder)["record"]["id"];

      this.loadSubmissions(folderId);
    } else this.loadChildSubmissions(parentSubmissionId);
  };

  deleteFormExecute = async id =>
    await this.graphQlService.post(
      `mutation mutate($data: DeleteSubmittedFormTemplateCommand){ formSubmissionDelete(data: $data) }`,
      { data: { id: id } }
    );
}

export default new CollectSubmissionListStore();
