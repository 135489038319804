import { useCallback } from "react";

const useThemeSwitcher = () => {
  const setDarkTheme = useCallback(() => {
    localStorage.setItem("theme", "dark");
  }, []);

  const setLightTheme = useCallback(() => {
    localStorage.setItem("theme", "light");
  }, []);

  const switchTheme = useCallback(() => {
    const currentTheme = localStorage.getItem("theme");
    if (currentTheme !== "dark") {
      setDarkTheme();
    } else {
      setLightTheme();
    }
    window.location.reload();
  }, [setDarkTheme, setLightTheme]);

  return { switchTheme };
};

export default useThemeSwitcher;
