import React from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";

const WrapperSelectorProject = ({ title, children }) => {
  return (
    <div style={{}}>
      <h3>{title}</h3>
      {children}
    </div>
  );
};
export default withTranslation()(observer(WrapperSelectorProject));
