import React, { useState } from "react";
import { Button, Tag, Flex } from "antd";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { LeftOutlined } from "@ant-design/icons";
import FormBuilderStore from "../../../Collect/MyForms/FormBuilderStore";
import Breadcrumbs from "../molecules/Breadcrumbs";
import "../../../assets/scss/main.scss";

const PageNavigation = ({ pageTitle, currentNameTitle, path, t }) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  return (
    <Flex style={{ marginLeft: 24 }} align="baseline">
      {params.containerId && (
        <Button
          disabled={isButtonDisabled}
          type="text"
          style={{ marginRight: 10 }}
          onClick={() => {
            const searchParams = new URLSearchParams(location?.search);
            const redirectPath = searchParams.get("l");
            setIsButtonDisabled(true);
            if (redirectPath) {
              history.push(redirectPath);
            } else if (path.includes("/collect/forms/:containerId")) {
              FormBuilderStore.updateForm();
              history.goBack();
              setTimeout(() => {
                setIsButtonDisabled(false);
                window.location.reload();
              }, 1000);
            } else if (params.containerId) {
              path.includes("/collect/entry/:containerId") ? history.push("/collect/entries") : history.goBack();
              path.includes("/collect/entry/:containerId") && setIsButtonDisabled(false);

              !path.includes("/collect/entry/:containerId") &&
                setTimeout(() => {
                  setIsButtonDisabled(false);
                  window.location.reload();
                }, 100);
            } else {
              history.goBack();
              setTimeout(() => {
                setIsButtonDisabled(false);
                window.location.reload();
              }, 100);
            }
          }}
          icon={<LeftOutlined />}
        />
      )}
      <Breadcrumbs style={{ marginLeft: 0 }} path={path} currentNameTitle={currentNameTitle} pageTitle={pageTitle} />

      {path.includes("/collect/forms/:containerId") && (
        <Tag style={{ marginLeft: 10 }} color={FormBuilderStore.isActive ? "#87d068" : "#f50"}>
          {FormBuilderStore.isActive ? t("collect.active") : t("collect.notActive")}
        </Tag>
      )}
    </Flex>
  );
};

export default withTranslation()(observer(PageNavigation));
