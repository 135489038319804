import { makeAutoObservable } from "mobx";
import i18next from "i18next";

import { cloneDeep } from "lodash";

import GraphQlService from "../../../Common/GraphQlService";
import { message, notification } from "antd";
import BackendService from "../../../Common/BackendService";

class AutomateWorkflowStore {
  graphQlService = new GraphQlService();
  containersApi = new BackendService(`containers`);
  workflowApi = new BackendService(`organizations/business-process`);

  isLoading = true;

  list = undefined;
  workflow = undefined;
  bpmnEditorStore = undefined;

  modificationHistoryVisible = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  loadList = () => {
    this.isLoading = true;
    this.graphQlService
      .get(
        `{ automateWorkflows(where: [{ path: "Type" comparison: EQUAL value: "AutomateWorkflow" }]) { id rootId payload name modifiedDateTime modifiedBy{ id username } createdDateTime createdBy{ username id } } }`
      )
      .then(r => {
        this.list = r.data.automateWorkflows.map(x => ({ ...x, payload: JSON.parse(x.payload || "{}") }));
        this.isLoading = false;
      });
  };

  loadSingle(id) {
    this.isLoading = true;
    this.workflow = undefined;

    this.graphQlService
      .get(
        `{ containers(where: [{ path: "RootId", comparison: EQUAL, value: "${id}" }]) { id rootId name bpmn payload } }`
      )
      .then(r => {
        setTimeout(() => {
          this.workflow = cloneDeep(r.data.containers[0]);
          this.isLoading = false;
        }, 1000);
      });
  }

  create = name =>
    this.graphQlService.post(`mutation mutate($data: CreateContainerCommand){ automateWorkflow(data: $data) }`, {
      data: { name: name }
    });

  delete(id) {
    this.isLoading = true;
    this.containersApi.delete(`${id}`).then(() => {
      this.loadList();
    });
  }

  clone = id => {
    this.isLoading = true;
    this.workflowApi.post(`clone`, id).then(
      () => {
        message.success(i18next.t("businessProcessMgmntStore.cloned"));
        this.loadList();
      },
      e => {
        if (e.response.data.Message === "WORKFLOW_LIMIT_EXCEEDED") message.error(`Workflows limit exceeded!`);
        else message.error(e.response.data.Message);
      }
    );
  };

  rename = (id, name) => {
    this.isLoading = true;
    this.containersApi.put(`rename`, { containerId: id, name: name }).then(() => {
      message.success(i18next.t(`businessProcessMgmntStore.renamed`));
      this.loadList();
    });
  };

  changeActiveStatus = (id, status) =>
    this.graphQlService
      .post(
        `mutation mutate($data: ToggleAutomateActiveStatusCommand){ automateWorkflowActiveStatusToggle(data: $data) }`,
        { data: { id: id, status: status } }
      )
      .then(() => this.loadList());

  execute = id => {
    this.graphQlService
      .post(`mutation mutate($id: Guid){ automateWorkflowTriggerExecute(id: $id) }`, { id: id })
      .then(r => {
        if (r.errors) this.graphQlService.displayErrors(r.errors);
        else message.success(i18next.t("automate.executing"));
      });
  };
  setIsLoading = isLoading => {
    this.isLoading = isLoading;
  };
}
export default new AutomateWorkflowStore();
