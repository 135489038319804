import { makeAutoObservable, toJS } from "mobx";
import GraphQlService from "../GraphQlService";
import { isEqual } from "lodash";

class BookmarksStore {
    graphQlService = new GraphQlService();
    showBookmarks = false;
    showEditBookmarksDialog = false;
    allBookmarks = [];
    bookmarks = [];
    projects = []

    constructor() {
        makeAutoObservable(this);
    }

    defaultLoad = (projectName) => {
        const formattedProjectName = projectName?.includes(" ") ? projectName.split(" ").join("%20") : projectName;

        this.graphQlService.get("{ currentUser { bookmarks} }").then((res) => {
            const userBookmarks = res.data.currentUser.bookmarks

            const parsedBookmarks = JSON.parse(userBookmarks);

            const allProjectsBookmarks = parsedBookmarks ? parsedBookmarks : []

            this.allBookmarks = allProjectsBookmarks;

            const currentProjectBookmarks = allProjectsBookmarks.length ? allProjectsBookmarks.filter(current => current.pathname.includes(formattedProjectName)) : [];

            if (currentProjectBookmarks.length) {
                this.bookmarks = currentProjectBookmarks

                const showAlwaysBookmarks = localStorage.getItem("showAlwaysBookmarks");

                if (showAlwaysBookmarks === "true") return this.showBookmarks = true
            }

            localStorage.setItem("showAlwaysBookmarks", false);
            this.showBookmarks = false;
        });
    };

    toggleShowBookmarks() {
        const toggledValue = !this.showBookmarks;

        this.showBookmarks = toggledValue;

        localStorage.setItem("showAlwaysBookmarks", toggledValue)
    }

    mutateBookmarks(method, bookmark) {
        let updatedBookmarks = [];
        let updatedAllBookmarks = [...this.allBookmarks];

        const addBookmark = (bookmarks, newBookmark) => [...bookmarks, newBookmark];
        const deleteBookmark = (bookmarks) => bookmarks.filter((current) => current.id !== bookmark.id)
        const editBookmark = (bookmarks) => bookmarks.map((current) => {
            if (current.id === bookmark.id) return bookmark
            return current
        });

        if (method === "add") {
            updatedBookmarks = addBookmark(this.bookmarks, bookmark);
            updatedAllBookmarks = addBookmark(updatedAllBookmarks, bookmark);
        } else if (method === "delete") {
            updatedBookmarks = deleteBookmark(this.bookmarks, bookmark.id);
            updatedAllBookmarks = deleteBookmark(updatedAllBookmarks, bookmark.id)
        } else if (method === "edit") {
            updatedBookmarks = editBookmark(this.bookmarks)
            updatedAllBookmarks = editBookmark(updatedAllBookmarks)
        }

        this.bookmarks = updatedBookmarks;
        this.allBookmarks = updatedAllBookmarks;

        this.graphQlService.post(`mutation mutate($data: UpdateUserCommand){ userUpdate(data: $data) }`, { data: { bookmarks: JSON.stringify(updatedAllBookmarks) } });
    }

    init = (projectName) => {
        this.defaultLoad(projectName);
    }

}

export default new BookmarksStore();
