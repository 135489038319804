import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { Col, Drawer, Row } from "antd";
import { SafeArea } from "antd-mobile";
import React, { useState } from "react";
import UserStore from "../Users/UserStore";
import MainMenuMobile from "./MainMenuMobile";
import { withTranslation } from "react-i18next";
import { NameAvatarComponent } from "../Users/NameAvatarComponent";

const BaseLayoutHeaderMobile = ({ t }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  return (
    <>
      <SafeArea position="top" />
      <Row style={{ marginTop: 65, marginBottom: 15 }}>
        <Col span={20} offset={1}>
          <Row align="middle">
            <NameAvatarComponent name={UserStore.user?.username} style={{ verticalAlign: "middle" }} />
            <h3 style={{ paddingTop: 5, paddingLeft: 10 }}>{UserStore.user?.username}</h3>
          </Row>
        </Col>
        <Col span={2} offset={1}>
          <MenuOutlined onClick={() => setDrawerVisible(!drawerVisible)} />
        </Col>
      </Row>
      <Drawer placement="bottom" closable={true} closeIcon={<CloseOutlined style={{ marginLeft: -16 }} />} open={drawerVisible} width={"100%"} height={"70%"} title={t("mobileMenu.menu")} onClose={() => setDrawerVisible(false)}>
        <MainMenuMobile setDrawerVisible={setDrawerVisible} style={{ paddingTop: 5 }} />
      </Drawer>
    </>
  );
};

export default withTranslation()(BaseLayoutHeaderMobile);
