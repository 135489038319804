import React, { useEffect, useState } from "react";
import { Col, Form, Input, InputNumber, Row, Spin } from "antd";
import { Form as FormMobile, Input as InputMobile } from "antd-mobile";
import GraphQlService from "../../../Common/GraphQlService";
import FormSubmissionCommonStore from "../../Submissions/view/FormSubmissionCommonStore";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import FormBuilderStore from "../../MyForms/FormBuilderStore";

const FormNumericInput = props => {
  const normalizeStr = str => str.replace(" ", "").toUpperCase();
  const currentPlatform = localStorage.getItem("platform");

  const [tempvalue, setTempValue] = useState(undefined);
  const graphQlService = new GraphQlService();
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState(undefined);

  useEffect(() => {
    if (props.storeValue === undefined && props.value !== undefined && props.updateValue)
      props.updateValue(props.id, props.value);
    validate();
  }, [props.value]);

  useEffect(() => validate(), [tempvalue]);

  const validate = () => {
    if ((!tempvalue || tempvalue === "") && props.identifier) {
      setValidationError({ status: "error", text: props.t("collect.validations.missingValue") });
      if (props.setValidityStatus) props.setValidityStatus(props.id, false);
    } else if (props.minValue && tempvalue < +props.minValue) {
      setValidationError({ status: "error", text: props.t("collect.validations.tooLow", { val: props.minValue }) });
      if (props.setValidityStatus) props.setValidityStatus(props.id, false);
    } else if (props.maxValue && tempvalue > +props.maxValue) {
      setValidationError({ status: "error", text: props.t("collect.validations.tooHigh", { val: props.maxValue }) });
      if (props.setValidityStatus) props.setValidityStatus(props.id, false);
    } else {
      setValidationError({ status: undefined, text: "" });
      if (props.setValidityStatus) props.setValidityStatus(props.id, true);
    }
  };

  const setValue = async val => {
    if (props.updateValue) props.updateValue(props.id, val);
  };

  return (
    <>
      <Spin spinning={isLoading}>
        {currentPlatform === "web" && (
          <Form.Item
            name={props.label}
            validateStatus={validationError?.status}
            hasFeedback
            help={validationError?.text}
            label={
              currentPlatform === "web" && (
                <>
                  {props.label} {props.isRequired && <span style={{ color: "red", marginLeft: 2 }}>(*)</span>}
                </>
              )
            }
            disabled={props.disabled}
            style={currentPlatform === "mobile" ? { margin: "15px 0px" } : { margin: "15px 0px" }}
          >
            <div style={{ display: "flex", justifyContent: currentPlatform === "mobile" ? "center" : "flex-end" }}>
              <InputNumber
                style={
                  currentPlatform === "mobile"
                    ? {
                        marginRight: 0,
                        marginLeft: 2,
                        padding: "2px 0px",
                        width: "90%",
                        fontSize: 14,
                        borderColor: "#90A8BE",
                        borderRadius: "3px"
                      }
                    : { marginRight: 20, width: "100%" }
                }
                type="number"
                key={props.id}
                value={props.storeValue !== undefined ? props.storeValue : props.value}
                disabled={props.disabled}
                onChange={value => setTempValue(value)}
                onBlur={e => setValue(tempvalue)}
                controls={false}
              />
            </div>
          </Form.Item>
        )}

        {currentPlatform === "mobile" && (
          <>
            <FormMobile.Item
              name={props.id}
              label={props.label}
              rules={[{ message: props.placeholder }]}
              validateStatus={validationError?.status}
              hasFeedback
              help={validationError?.text}
            >
              <InputMobile
                type="number"
                placeholder={props.placeholder}
                key={props.id}
                defaultValue={props.storeValue !== undefined ? props.storeValue : props.value}
                onChange={e => setTempValue(e)}
                onBlur={() => setValue(tempvalue)}
                disabled={props.disabled}
                style={{
                  border: "1px solid #90A8BE",
                  borderRadius: "4px",
                  padding: 5,
                  borderColor: validationError?.status ? "red" : "#90A8BE"
                }}
              />
            </FormMobile.Item>
          </>
        )}

        {props.dupes &&
          props.identifier &&
          props.storeValue &&
          props.dupes.some(
            d =>
              normalizeStr(d.value) === normalizeStr(props.storeValue.toString()) &&
              normalizeStr(d.name) === normalizeStr(props.label)
          ) && <h4 style={{ color: "#ec547a", marginBottom: 20 }}>{props.t("collect.duplicatedIdentifier")}</h4>}
      </Spin>
    </>
  );
};

export const CollectFormNumbericInputSettings = observer(props => {
  const inputStyle = { marginLeft: 5, width: "90%" };
  return (
    <>
      <Row gutter={4} style={{ marginTop: 5 }}>
        <Col span={6} style={{ textAlign: "right" }}>
          {props.t("collect.minValue")}
        </Col>
        <Col span={18}>
          <Input
            size="small"
            style={inputStyle}
            value={FormBuilderStore.modifyInputColumn.minValue}
            onChange={e => props.setFieldValue("minValue", e.target.value)}
          />
        </Col>
      </Row>
      <Row gutter={4} style={{ marginTop: 5 }}>
        <Col span={6} style={{ textAlign: "right" }}>
          {props.t("collect.maxValue")}
        </Col>
        <Col span={18}>
          <Input
            size="small"
            style={inputStyle}
            value={FormBuilderStore.modifyInputColumn.maxValue}
            onChange={e => props.setFieldValue("maxValue", e.target.value)}
          />
        </Col>
      </Row>
    </>
  );
});

export default withTranslation()(FormNumericInput);
