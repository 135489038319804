import React from "react";
import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Layout, Row, Col } from "antd";
import packageJson from "../../../package.json";
const { Header, Footer, Content } = Layout;

class LayoutEmpty extends React.Component {
  render() {
    const appConfig = JSON.parse(localStorage.getItem("appConfig"));
    return (
      <Layout>
        {this.props.path &&
          !this.props.path.includes("utils/login") &&
          !this.props.path.includes("utils/register") &&
          !this.props.path.includes("utils/reset-password") &&
          window.location.pathname.split(`/`).length >= 3 && (
            <Header
              style={{
                position: "fixed",
                width: "100%",
                zIndex: 1001,
                boxShadow: "0px 0px 10px rgba(0,0,0,.06)"
              }}
            >
              <Row>
                <Col span={4}>
                  <img src={appConfig.header.path} alt="Beawre" style={{ height: "32px", marginTop: -5 }} />
                </Col>
              </Row>
            </Header>
          )}
        <Content style={{ marginTop: "80px", marginLeft: 20, marginRight: 20, marginBottom: 100 }}>
          {this.props.children}
        </Content>
        {/* <Footer style={{ position: "fixed", left: 0, bottom: 0, right: 0 }}>
                    <small style={{ float: "right" }}>version: {packageJson.version}</small>
                    <small>{appConfig && appConfig.footer && appConfig.footer.text}</small>
                </Footer> */}
      </Layout>
    );
  }
}

export default withTranslation()(observer(LayoutEmpty));
