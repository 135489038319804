import React, { useState } from "react";
import { Button, message } from "antd";

// import { usePhotoGallery, Photo } from "./../../hooks/UsePhotoGrallery";
import ConfigStore from "../../../Common/ConfigStore";

import GraphQlService from "./../../../Common/GraphQlService";
import { withTranslation } from "react-i18next";

import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

const FormCamera = props => {
  const [currentFile, setCurrentFile] = useState(props.values ? props.values.value : undefined);

  // const { photos, takePhoto } = usePhotoGallery();

  const takePhoto = async () => {
    const photo = await Camera.getPhoto({
      quality: 100, // 0-100
      source: CameraSource.Camera, // Prompt | Camera | Photos
      saveToGallery: false,
      resultType: CameraResultType.Base64
    });
    return photo.base64String;
  };

  const captureImage = async () => {
    if (!props.canUpload || props.canUpload === false) return;
    var imageBase64 = await takePhoto();

    let filename = new Date().getTime() + ".jpeg";
    var graphQlService = new GraphQlService();
    graphQlService
      .post(
        `mutation mutate($file: String, $filename: String, $id: Guid){ formSubmissionUploadFile(file: $file, filename: $filename, id: $id) }`,
        {
          data: {
            file: imageBase64,
            filename: filename,
            id: props.submissionId
          }
        }
      )
      .then(r => {
        message.success(props.t("collect.succesfullUploadFormCamera"));

        props.updateValue(props.id, r.data.formSubmissionUploadFile);
        props.save();
        setCurrentFile(r.data.formSubmissionUploadFile);
      });
  };

  return (
    <div>
      {props.label && (
        <span>
          <label>{props.label}</label>
          <br />
        </span>
      )}
      {!currentFile && (
        <Button htmlType="button" disabled={props.disabled} onClick={async () => await captureImage()}>
          {props.t("collect.clickToTakePic")}
        </Button>
      )}
      {currentFile && <img src={`${ConfigStore.backendUrlHost}/${currentFile}`} alt="img" width="100%" height="100%" />}
    </div>
  );
};

export default withTranslation()(FormCamera);
