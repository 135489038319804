import React, { useEffect, useState } from "react";
import { Col, Form, Input, InputNumber, Row } from "antd";
import { Form as FormMobile, TextArea as TextAreaMobile } from "antd-mobile";
import { observer } from "mobx-react-lite";
import FormBuilderStore from "../../MyForms/FormBuilderStore";
const FormTextArea = props => {
  const [value, setValue] = useState();

  const currentPlatform = localStorage.getItem("platform");

  useEffect(() => {
    setValue(props.storeValue !== undefined ? props.storeValue : props.value);
  }, []);

  useEffect(() => {
    if (props.storeValue === undefined && props.value !== undefined && props.updateValue)
      props.updateValue(props.id, props.value);
  }, [props.value]);

  if (currentPlatform === "mobile")
    return (
      <FormMobile.Item
        label={
          <>
            {props.label} {props.isRequired && <span style={{ color: "red", marginLeft: 2 }}>(*)</span>}
          </>
        }
        style={{ margin: "0px" }}
        name={props.id}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <TextAreaMobile
            style={{ border: "1px solid #90A8BE", borderRadius: "4px", padding: 5 }}
            defaultValue={props.storeValue !== undefined ? props.storeValue : props.value}
            placeholder={props.placeholder}
            key={props.id}
            disabled={props.disabled}
            rows={props.rows}
            onChange={e => props.updateValue && props.updateValue(props.id, e)}
          />
        </div>
      </FormMobile.Item>
    );

  return (
    <Form.Item
      name={props.label}
      label={
        <>
          {props.label} {props.isRequired && <span style={{ color: "red", marginLeft: 2 }}>(*)</span>}
        </>
      }
      style={{ margin: "15px 20px 15px 0px" }}
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Input.TextArea
          value={value}
          placeholder={props.placeholder}
          key={props.id}
          disabled={props.disabled}
          rows={props.rows}
          onChange={e => setValue(e.target.value)}
          onBlur={() => {
            props.updateValue && props.updateValue(props.id, value);
          }}
        />
      </div>
    </Form.Item>
  );
};

export const CollectFormTextAreaSettings = observer(props => {
  const inputStyle = { marginLeft: 5, width: "90%" };

  return (
    <>
      <Row gutter={4} style={{ marginTop: 5 }}>
        <Col span={6} style={{ textAlign: "right" }}>
          {props.t("collect.rows")}
        </Col>
        <Col span={18}>
          <InputNumber
            style={inputStyle}
            size="small"
            min={1}
            max={10}
            value={FormBuilderStore.modifyInputColumn.rows}
            onChange={e => props.setFieldValue("rows", e)}
          />
        </Col>
      </Row>
    </>
  );
});

export default FormTextArea;
