import React, { useEffect } from 'react';
import { Rate, Row } from 'antd';

const FormStarRate = (props) => {

    useEffect(() => {
        if (props.storeValue === undefined && props.value !== undefined && props.updateValue) props.updateValue(props.id, props.value)
      }, [props.value])

    return (
        <Row align='middle' justify='center'>
            <p style={{ marginRight: 10, paddingTop: 20 }}>{<>{props.label} {props.isRequired && <span style={{ color: 'red', marginLeft: 2 }}>(*)</span>}</>}</p>
            <Rate
                onChange={(newValue) => props.updateValue && props.updateValue(props.id, newValue)}
                value={props.storeValue !== undefined ? props.storeValue : props.value}
                defaultValue={props.defaultValue}
                disabled={props.disabled}
            />
        </Row>
    )
}

export default FormStarRate;
