import { makeAutoObservable } from "mobx";

class PdfAnnotatorSquareDrawer {
  startX = 0;
  startY = 0;

  endX = 0;
  endY = 0;

  width = 0;
  height = 0;

  constructor() {
    makeAutoObservable(this);
  }

  onMouseDown = (x, y) => {
    this.startX = x;
    this.startY = y;

    this.endX = x;
    this.endY = y;
  };

  onMouseMove = (x, y) => {
    this.endX = x;
    this.endY = y;

    this.width = this.endX - this.startX;
    this.height = this.endY - this.startY;
  };

  draw = (context, annotationConfig) => {
    context.beginPath();
    context.rect(this.startX, this.startY, this.width, this.height);
    context.strokeStyle = annotationConfig.hexColor;
    context.stroke();
  };

  drawSelect = (context, annot, viewport) => {
    const rectStart = viewport.convertToViewportPoint(annot.rect[0], annot.rect[1]);
    const rectEnd = viewport.convertToViewportPoint(annot.rect[2], annot.rect[3]);

    const width = Math.abs(rectStart[0] - rectEnd[0]);
    const height = Math.abs(rectStart[1] - rectEnd[1]);

    const lowestX = rectStart[0] < rectEnd[0] ? rectStart[0] : rectEnd[0];
    const lowestY = rectStart[1] < rectEnd[1] ? rectStart[1] : rectEnd[1];

    context.beginPath();
    context.rect(lowestX, lowestY, width, height);
    context.fillStyle = "rgba(11, 11, 11, 0.3)";
    context.fill();
    context.stroke();
  };
}

export default new PdfAnnotatorSquareDrawer();
