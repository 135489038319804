import React, { useEffect } from "react";
import { Row, Col, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const FormSwitch = props => {
  const currentPlatform = localStorage.getItem("platform");

  useEffect(() => {
    if (props.storeValue === undefined && props.value !== undefined && props.updateValue) {
      props.updateValue(props.id, props.value);
    }
  }, []);

  useEffect(() => {
    if (props.storeValue === undefined && props.value !== undefined && props.updateValue)
      props.updateValue(props.id, props.value);
  }, [props.value]);

  if (currentPlatform === "web")
    return (
      <Col span={props.rowColsLength === 3 ? 8 : props.rowColsLength === 2 ? 12 : 24}>
        <Row align="middle" justify="space-between">
          <p>
            {props.label}
            {props.hints &&
              props.hints
                .filter(h => h.value && (h.displayHint || h.alwaysVisible))
                .map(hint => (
                  <>
                    <br />
                    <small>
                      <b>{hint.value}</b>
                    </small>
                  </>
                ))}
          </p>
          <Switch
            checked={
              props.storeValue !== undefined ? props.storeValue : props.value === undefined ? false : props.value
            }
            disabled={props.disabled}
            onChange={newValue => props.updateValue && props.updateValue(props.id, newValue)}
            key={props.key}
          />
        </Row>
      </Col>
    );

  // = = = Mobile view
  return (
    <Row align="middle" justify="space-between">
      <p style={{ paddingTop: 5 }}>
        {props.label}
        {props.hints &&
          props.hints
            .filter(h => h.value && (h.displayHint || h.alwaysVisible))
            .map(hint => (
              <>
                <br />
                <small>
                  <b>{hint.value}</b>
                </small>
              </>
            ))}
      </p>
      <Switch
        checked={props.storeValue !== undefined ? props.storeValue : props.value}
        disabled={props.disabled}
        onChange={newValue => props.updateValue && props.updateValue(props.id, newValue)}
        key={props.key}
        checkedText={<CheckOutlined fontSize={18} />}
        uncheckedText={<CloseOutlined fontSize={18} />}
      />
    </Row>
  );
};

export default FormSwitch;
