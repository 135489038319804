import { Col, Row } from "antd";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react";
import { HuePicker } from "react-color";

class PdfAnnotatorCircleDrawer {
    centerX = 0;
    centerY = 0;

    endX = 0;
    endY = 0;

    radius = 0;

    constructor() {
        makeAutoObservable(this);
    }

    onMouseDown = (x, y) => {
        this.centerX = x;
        this.centerY = y;

        this.endX = x;
        this.endY = y;
    }

    onMouseMove = (x, y) => {
        this.endX = x;
        this.endY = y;

        this.radius = Math.sqrt((Math.pow(this.endX - this.centerX, 2)) + (Math.pow(this.endY - this.centerY, 2)));
    }

    draw = (context, annotationConfig) => {
        context.beginPath();
        context.arc(this.centerX, this.centerY, this.radius, 0, 2 * Math.PI);
        context.strokeStyle = annotationConfig.hexColor;
        context.stroke();
    }

    drawSelect = (context, annot, viewport) => {
        const rectStart = viewport.convertToViewportPoint(annot.rect[0], annot.rect[1]);
        const rectEnd = viewport.convertToViewportPoint(annot.rect[2], annot.rect[3]);

        const diameter = Math.abs(rectStart[0] - rectEnd[0]);
        const radius = diameter / 2;

        const lowestX = rectStart[0] < rectEnd[0] ? rectStart[0] : rectEnd[0];
        const lowestY = rectStart[1] < rectEnd[1] ? rectStart[1] : rectEnd[1];

        context.beginPath();
        context.arc(lowestX + radius, lowestY + radius, radius, 0, 2 * Math.PI);
        context.fillStyle = 'rgba(11, 11, 11, 0.2)';
        context.fill();
        context.stroke();
    }
}
export default new PdfAnnotatorCircleDrawer();
