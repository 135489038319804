import React from "react";
import { Layout } from "antd";
import { themeConfig } from "../config";

import "../../../assets/scss/main.scss";

const WrapperLayoutHeader = ({ children }) => {
  const { currentTheme } = themeConfig();

  return (
    <Layout.Header className={`wrapper-layout-header ${currentTheme === "light" ? "light" : "dark"}`}>
      <div className="header-flex">{children}</div>
    </Layout.Header>
  );
};

export default WrapperLayoutHeader;
