import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { matchPath } from "react-router-dom";
import routes from "../../../routes";

const useCurrentRoute = currentPath => {
  const location = useLocation();

  const currentRoute = useMemo(() => {
    const path = currentPath;

    return routes.find(route => {
      const routePaths = Array.isArray(route.path) ? route.path : [route.path];

      return routePaths.some(routePath => {
        const cleanedRoutePath = routePath.replace("/:projectName?", "");
        const cleanedPath = path.replace("/:projectName?", "");

        const isMatch = matchPath(cleanedPath, {
          path: cleanedRoutePath,
          exact: true,
          strict: false
        });

        return isMatch;
      });
    });
  }, [location.pathname]);

  return currentRoute;
};

export default useCurrentRoute;
