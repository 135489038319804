import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";

const CustomNavigationBlocker = ({ shouldBlockNavigation, t }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    const unblock = history.block(nextLocation => {
      if (shouldBlockNavigation && !confirmed) {
        setShowModal(true);
        setNextLocation(nextLocation);
        return false;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [shouldBlockNavigation, confirmed, history]);

  useEffect(() => {
    if (confirmed && nextLocation) {
      history.push(nextLocation.pathname);
      setConfirmed(false);
    }
  }, [confirmed, nextLocation, history]);

  const handleConfirm = () => {
    setConfirmed(true);
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
    setNextLocation(null);
  };

  return (
    <>
      <Modal onCancel={handleCancel} onOk={handleConfirm} title={t("collect.promptTitle")} open={showModal}>
        {t("collect.prompt")}
      </Modal>
    </>
  );
};

export default withTranslation()(observer(CustomNavigationBlocker));
