import { withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import ViewGeneratorStore from "../ViewGeneratorStore";
import { useEffect, useState } from "react";
import { Button, Dropdown } from "antd";
import * as AntdIcons from "@ant-design/icons";
import ViewGeneratorButtonsRenderer from "./ViewGeneratorButtonsRenderer";

const ViewGeneratorHeader = ({ t }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {}, [ViewGeneratorStore.hubConfig]);

  if (!ViewGeneratorStore.hubConfig) return;
  return (
    <>
      {(ViewGeneratorStore.hubConfig.buttons || ViewGeneratorStore.hubConfig.SelectableRows) && (
        <Dropdown
          trigger={["click"]}
          open={dropdownOpen}
          onOpenChange={(o, i) => {
            if (i.source === "trigger") setDropdownOpen(o);
          }}
          dropdownRender={() => (
            <ViewGeneratorButtonsRenderer
              buttons={ViewGeneratorStore.hubConfig.buttons}
              isHeader={true}
              onCompareShow={() => setDropdownOpen(false)}
            />
          )}
          placement="bottomRight"
        >
          <Button
            style={{ display: "flex", alignItems: "center" }}
            variant="filled"
            type="text"
            icon={<AntdIcons.MoreOutlined style={{ margin: "2px -10px 0px -10px" }} />}
          >
            <span>{t("buttons.additionalOptions")}</span>
          </Button>
        </Dropdown>
      )}
    </>
  );
};

export default withTranslation()(observer(ViewGeneratorHeader));
