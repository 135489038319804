import React, { useEffect, useState } from "react";
import { Checkbox, Form } from "antd";

const FormCheckbox = props => {
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(props.storeValue !== undefined ? props.storeValue : props.value);
  }, []);

  useEffect(() => {
    if (props.storeValue === undefined && props.value !== undefined && props.updateValue)
      props.updateValue(props.id, props.value);
  }, [props.value]);

  return (
    <Form.Item name={props.id}>
      <Checkbox
        checked={value}
        disabled={props.disabled}
        onChange={e => {
          setValue(e.target.checked);
          props.updateValue && props.updateValue(props.id, e.target.checked);
        }}
        defaultChecked={props.defaultChecked}
        key={props.key}
      >
        {props.label}
      </Checkbox>
    </Form.Item>
  );
};

export default FormCheckbox;
