import React, { useEffect, useState } from "react";

import { Button, Form, message, Spin, Tooltip, Upload } from "antd";
import { MehOutlined, RetweetOutlined, UploadOutlined } from "@ant-design/icons";

import _ from "lodash";

import GraphQlService from "../../../Common/GraphQlService";
import ConfigStore from "../../../Common/ConfigStore";
import { withTranslation } from "react-i18next";
import PdfAnnotator from "../pdf-annotator/PdfAnnotator";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { toJS } from "mobx";
import CollectSubmissionStore from "../../Submissions/FormSubmissionStore";
import CollectSubmissionCommonStore from "../../Submissions/view/FormSubmissionCommonStore";
import ImageAnnotatorComponent from "../../../Common/ImageAnnotator/ImageAnnotatorComponent";

const FormFileUpload = props => {
  const graphQlService = new GraphQlService();
  const routerMatch = useRouteMatch();

  const [state, setState] = useState({ isLoading: false, currentFile: undefined });

  useEffect(() => {
    //
    if (props.values && props.values.value) setState({ currentFile: props.values.value, isLoading: false });
  }, []);

  const onAnnotationsUpdated = annotations => {
    //
    props.updateValue(props.id, props.values.value, { annotations });
  };

  const custom = async e => {
    if (!props || (!props.canUpload && props.canUpload === false)) return;
    setState(v => ({ ...v, currentFile: undefined, isLoading: true }));
    if (e.file.name.includes(".pdf")) {
      graphQlService
        .post(
          `mutation mutate($file: String, $filename: String, $id: Guid){ formSubmissionUploadMultiPagePdf(file: $file, filename: $filename, id: $id) }`,
          {
            file: await toBase64(e.file),
            filename: e.file.name,
            id: props.submissionId
          }
        )
        .then(r => {
          e.onSuccess(r.data.formSubmissionUploadFile);
          message.success(`${e.file.name} ${props.t("uploaded")}`);

          props.updateValue(props.id, r.data.formSubmissionUploadFile, props.values ? props.values.payload : undefined);
          setState(v => ({ ...v, isLoading: false, currentFile: r.data.formSubmissionUploadFile }));
        });
    } else {
      graphQlService
        .post(
          `mutation mutate($file: String, $filename: String, $id: Guid){ formSubmissionUploadFile(file: $file, filename: $filename, id: $id) }`,
          {
            file: await toBase64(e.file),
            filename: e.file.name,
            id: props.submissionId
          }
        )
        .then(r => {
          e.onSuccess(r.data.formSubmissionUploadFile);
          message.success(`${e.file.name} ${props.t("uploaded")}`);

          props.updateValue(props.id, r.data.formSubmissionUploadFile, props.values ? props.values.payload : undefined);
          setState(v => ({ ...v, isLoading: false, currentFile: r.data.formSubmissionUploadFile }));
        });
    }
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  let uploadProps = { disabled: "true" };
  uploadProps = {
    name: "file",
    multiple: false,
    customRequest: custom,
    disabled: props.viewOnly,
    showUploadList: false
  };

  return (
    <>
      <Spin spinning={state.isLoading}>
        <Form.Item
          name={props.label}
          label={
            <>
              <>
                {props.label} {props.isRequired && <span style={{ color: "red", marginLeft: 2 }}>(*)</span>}
              </>
              <Tooltip title="Replace current PDF">
                <Upload {...uploadProps}>
                  <Button
                    htmlType="button"
                    icon={<RetweetOutlined />}
                    disabled={props.disabled}
                    style={{ marginLeft: 10 }}
                  ></Button>
                </Upload>
              </Tooltip>
            </>
          }
        />
        {!state.currentFile && (
          <Upload.Dragger {...uploadProps}>
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">{props.t("collect.clicktoUpload")}</p>
          </Upload.Dragger>
        )}
        {state.currentFile && (
          <>
            {state.currentFile.includes(".pdf") && (
              <div style={{ minHeight: 300 }}>
                <PdfAnnotator
                  url={state.currentFile}
                  submissionId={CollectSubmissionStore.submissionId}
                  currentAnnotations={props.values?.payload?.annotations || []}
                  onAnnotationsUpdated={onAnnotationsUpdated}
                  onObjectClaimed={v => (CollectSubmissionCommonStore.valueToUpdate = v)}
                  allowModify={routerMatch.params.containerId === props.submissionId}
                  allowClaim={routerMatch.params.containerId !== props.submissionId}
                  claimTextContent={CollectSubmissionStore.pdfAnnotatorClaimContent}
                />
              </div>
            )}

            {!state.currentFile.includes(".pdf") && (
              <div>
                <a href={ConfigStore.backendUrlHost + "/" + state.currentFile}>
                  {_.last(state.currentFile.split("/"))}
                </a>
              </div>
            )}
          </>
        )}
      </Spin>
    </>
  );
};

export default withTranslation()(FormFileUpload);
