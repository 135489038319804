import { Graphics, Sprite, Text } from "pixi.js";
import DxfEditorStore from "../DxfEditorStore";
import { v4 as uuidv4 } from "uuid";
import { makeAutoObservable, toJS } from "mobx";
import { observer } from "mobx-react";
import { Col, Collapse, Input, Row } from "antd";
import { AlphaPicker, HuePicker } from "react-color";
import { clone, cloneDeep } from "lodash";
import DocumentCommentStore from "../../../WorflowRiskControl/Documents/components/DocumentCommentStore";
import CommentStore from "../../../WorflowRiskControl/Comments/CommentStore";
import { SmoothGraphics } from "@pixi/graphics-smooth";

export const DxfEditorDrawRectangle = observer(({ store }) => {
  const hexToRgba = data => `rgba(${data.r}, ${data.g}, ${data.b}, ${data.a})`;

  return (
    <div>
      <Collapse defaultActiveKey={["1", "3"]}>
        <Collapse.Panel header="Background" key="1">
          <Row gutter={12}>
            <Col span={12}>
              Height:
              <Input
                defaultValue={store.shapeConfig.height}
                onChange={e => (store.shapeConfig.height = +e.target.value)}
              />
            </Col>
            <Col span={12}>
              Width:
              <Input
                defaultValue={store.shapeConfig.width}
                onChange={e => (store.shapeConfig.width = +e.target.value)}
              />
            </Col>
            <Col span={12}>
              Color:
              <HuePicker
                width="100%"
                color={store.shapeConfig.fillColor}
                onChange={e => {
                  store.shapeConfig.fillColor = e.hex;
                  store.shapeConfig.fillOpacity = e.rgb;
                }}
              />
            </Col>
            <Col span={12}>
              Transparency:
              <AlphaPicker
                width="100%"
                color={store.shapeConfig.fillOpacity}
                onChange={e => (store.shapeConfig.fillOpacity = e.rgb)}
              />
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel header="Text" key="2">
          <Row gutter={12}>
            <Col span={12}>
              Content:
              <Input defaultValue={store.shapeConfig.text} onChange={e => (store.shapeConfig.text = e.target.value)} />
            </Col>
            <Col span={12}>
              Font size:
              <Input
                defaultValue={store.shapeConfig.fontSize}
                onChange={e => (store.shapeConfig.fontSize = +e.target.value)}
              />
            </Col>
            <Col span={12}>
              Color:
              <HuePicker
                width="100%"
                color={store.shapeConfig.textColor}
                onChange={e => (store.shapeConfig.textColor = e.hex)}
              />
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel header="Preview" key="3" style={{ textAlign: "center" }}>
          <div
            style={{
              backgroundColor: hexToRgba(store.shapeConfig.fillOpacity),
              minHeight: store.shapeConfig.height,
              maxHeight: store.shapeConfig.height,
              minWidth: store.shapeConfig.width,
              maxWidth: store.shapeConfig.width,
              textAlign: "center",
              verticalAlign: "middle"
            }}
          >
            <div style={{ fontSize: store.shapeConfig.fontSize, color: store.shapeConfig.textColor }}>
              {store.shapeConfig.text}
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
});

class DxfEditorDrawRectangleStore {
  initPointer = undefined;
  newGraphic = undefined;

  shapeConfig = {
    fillColor: "#0000ff",
    fillOpacity: { r: 0, g: 0, b: 255, a: 1 },
    height: 100,
    width: 200,

    text: "",
    fontSize: 16,
    textColor: "#ffffff"
  };

  exportConfig = {};

  constructor() {
    makeAutoObservable(this);
  }

  parseFromDxf = entity => {
    var object = { hexColor: entity.hexColor, fillOpacity: 0 };

    var lowestPos = undefined;
    var highestPos = undefined;
    entity.vertices.forEach(pos => {
      if (lowestPos === undefined) lowestPos = clone(pos);
      if (lowestPos.x > pos.x) lowestPos.x = clone(pos).x;
      if (lowestPos.y > pos.y) lowestPos.y = clone(pos).y;

      if (highestPos === undefined) highestPos = clone(pos);
      if (highestPos.x < pos.x) highestPos.x = clone(pos).x;
      if (highestPos.y < pos.y) highestPos.y = clone(pos).y;
    });

    object.x = lowestPos.x;
    object.y = highestPos.y;

    object.height = highestPos.y - lowestPos.y;
    object.width = highestPos.x - lowestPos.x;

    object.lowestPos = lowestPos;
    object.highestPos = highestPos;

    return object;
  };

  draw = (store, item) => {
    if (!item.hexColor) item.hexColor = "#ffffff";
    item.hexColor = item.hexColor.replace("#", "0x");

    if (item.width === undefined) item.width = 200;
    if (item.height === undefined) item.height = 100;
    if (item.fillOpacity === undefined) item.fillOpacity = 1;

    const graphics = new SmoothGraphics();

    graphics.beginFill(item.hexColor, item.fillOpacity);
    graphics.lineStyle(1, item.hexColor);
    graphics.drawRect(0, 0, item.width, item.height);
    graphics.endFill();

    var texture = store.app.renderer.generateTexture(graphics);
    let sprite = new Sprite(texture);

    sprite.position.x = item.x;
    sprite.position.y = -item.y;
    // sprite.anchor.set(0.5);

    if (item.text) {
      if (!item.textColor) item.textColor = "#ffffff";
      item.textColor = item.textColor.replace("#", "0x");

      let text = new Text(item.text, {
        fontSize: item.fontSize,
        fill: item.textColor,
        align: "center",
        breakWords: true,
        wordWrap: true,
        wordWrapWidth: item.width
      });
      text.anchor.set(0.5);
      text.x = item.width / 2;
      text.y = item.height / 2;
      sprite.addChild(text);
    }

    this.exportConfig = {
      layer: "beawre_annotation",
      handle: "B1",
      ownerHandle: "Beawre",
      colorIndex: 7,
      vertices: [],
      lineweight: -1,
      lineType: "ByLayer",
      height: item.height,
      width: item.width
    };
    this.exportConfig.vertices = this.getRectCorners(item.x, -item.y, item.height, item.width);
    return sprite;
  };

  getRectCorners = (x, y, height, width) => {
    return [
      { x: x, y: y },
      { x: x, y: y - height },
      { x: x + width, y: y - height },
      { x: x + width, y: y }
    ];
  };

  delete(store, item) {
    const allComments = DocumentCommentStore.allComments;
    const text = item.name.slice(item.type.length + 2, item.name.length);

    if (allComments.length > 0) {
      const currentComment = allComments.find(comment => comment.content === text);

      if (currentComment) DocumentCommentStore.delete(currentComment.id);
    }

    store.annotations = [...store.annotations.filter(x => x.key != item.key)];
    item.instance.destroy();
  }

  onMouseMove = (store, e) => {
    const mousePosRef = store.viewportRef.toLocal(e.global);
    this.newGraphic.position.x = mousePosRef.x;
    this.newGraphic.position.y = mousePosRef.y;

    this.exportConfig.vertices = this.getRectCorners(
      mousePosRef.x,
      -mousePosRef.y,
      this.exportConfig.height,
      this.exportConfig.width
    );
  };

  onMouseDown = (store, e) => {
    this.initPointer = store.viewportRef.toLocal(e.global);

    this.newGraphic = this.draw(store, {
      ...this.shapeConfig,
      x: this.initPointer.x,
      y: -this.initPointer.y,
      hexColor: this.shapeConfig.fillColor,
      fillOpacity: this.shapeConfig.fillOpacity.a
    });
    store.viewportRef.addChild(this.newGraphic);
    store.isMouseButtonDown = true;
  };

  onMouseUp = (store, e) => {
    DocumentCommentStore.reply(undefined, this.shapeConfig.text); // Create comment

    CommentStore.loadList(); // Load all comments

    store.annotations.push({
      key: uuidv4(),
      name: "Rectangle: " + this.shapeConfig.text,
      type: "rectangle",
      instance: this.newGraphic,
      exportConfig: this.exportConfig
    });
    store.annotations = [...store.annotations];
  };

  exportToDxf = (store, results, entity) => {
    results = store.exportInsert(results, 0, "LWPOLYLINE");
    if (entity.handle) results = store.exportInsert(results, 5, entity.handle);
    if (entity.ownerHandle) results = store.exportInsert(results, 330, entity.ownerHandle);
    results = store.exportInsert(results, 100, "AcDbEntity");
    if (entity.layer) results = store.exportInsert(results, 8, entity.layer);
    if (entity.lineType) results = store.exportInsert(results, 6, entity.lineType);
    if (entity.colorIndex) results = store.exportInsert(results, 62, entity.colorIndex);
    if (entity.lineweight) results = store.exportInsert(results, 370, entity.lineweight);
    results = store.exportInsert(results, 100, "AcDbPolyline");
    if (entity.vertices) results = store.exportInsert(results, 90, entity.vertices.length);
    results = store.exportInsert(results, 70, 1);
    results = store.exportInsert(results, 43, 0);
    if (entity.vertices) {
      entity.vertices.forEach(pos => {
        results = store.exportInsert(results, 10, pos.x);
        results = store.exportInsert(results, 20, pos.y);
      });
    }
    return results;
  };
}

export default new DxfEditorDrawRectangleStore();
