import { makeAutoObservable } from "mobx";
import GraphQlService from "../../../Common/GraphQlService";
import BackendService from "../../../Common/BackendService";
import { cloneDeep } from "lodash";
import i18next from "i18next";
import { notification } from "antd";

class AutomateWorkflowTemplateStore {
  graphQlService = new GraphQlService();
  containersApi = new BackendService(`containers`);

  isLoading = true;
  list = [];

  bpmnEditorStore = undefined;
  workflow = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  loadList = () => {
    this.isLoading = true;
    this.graphQlService
      .get(
        `{ automateWorkflowTemplate(where: [{ path: "Type" comparison: EQUAL value: "AutomateWorkflowTemplate" }]) { id rootId payload name createdDateTime createdBy{ username id }  modifiedDateTime modifiedBy{ id username } } }`
      )
      .then(r => {
        this.list = r.data.automateWorkflowTemplate.map(x => ({ ...x, payload: JSON.parse(x.payload || "{}") }));
        this.isLoading = false;
      });
  };

  loadSingle = id => {
    this.isLoading = true;
    this.graphQlService
      .get(
        `{ automateWorkflowTemplate(where: [{ path: "RootId" comparison: EQUAL value: "${id}" }, { path: "ProjectId" comparison: EQUAL value: "${localStorage.getItem(
          `tenantId`
        )}" }]) { id rootId payload name bpmn } }`
      )
      .then(r => {
        this.workflow = cloneDeep(r.data.automateWorkflowTemplate[0]);
        this.workflow.payload = JSON.parse(this.workflow.payload || "{}");
        this.isLoading = false;
      });
  };

  create = name => {
    return this.graphQlService.post(
      `mutation mutate($data: CreateContainerCommand){ automateWorkflowTemplateCreate(data: $data) }`,
      { data: { name: name } }
    );
  };

  delete = id => {
    this.isLoading = true;

    this.containersApi.delete(`${id}`).then(() => {
      this.loadList();
    });
  };

  rename = (id, name) => this.containersApi.put(`rename`, { containerId: id, name: name });
}
export default new AutomateWorkflowTemplateStore();
