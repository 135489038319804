import { message, notification } from "antd";
import { debounce, forEach, uniqBy, values as lodashValues } from "lodash";
import { makeAutoObservable, toJS } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { Toast } from "antd-mobile";
import i18next from "i18next";

import ConfigStore from "../../Common/ConfigStore";
import GraphQlService from "../../Common/GraphQlService";

export class CollectSubmissionStore {
  graphQlService = new GraphQlService();

  isLoading = false;
  submissions = [];
  totalSubmissions = 0;
  pendingSubmissions = [];

  template;
  templates = [];

  page = 1;
  pageSize = 50;

  submissionId = undefined;
  parentSubmission = undefined;

  structure = undefined;
  values = [];

  externalDataValues = {};

  canModify = false;
  title = "";
  tP = {};

  currentPlatform = localStorage.getItem("platform");

  driverRoute = [];

  isFiltering = false;

  validityStatus = {};

  disabledInputs = [];
  autoUnassignFields = [];

  autoSaveStatus = 0;
  notSaved = false;
  autoSaveTimer = undefined;

  cameraIsOpen = false;

  // = = = Cross form
  pdfAnnotatorClaimContent = [];

  constructor() {
    makeAutoObservable(this);
  }

  toSaveDebounce = debounce(async () => {
    if (!this.validityStatus) return;
    if (!window.location.href.includes("/collect/entry")) return;
    // await this.update();

    this.autoSaveRecurring(0);
  }, 1000);

  autoSaveRecurring = start => {
    if (this.autoSaveTimer) clearTimeout(this.autoSaveTimer);
    this.autoSaveStatus = start;

    if (this.autoSaveStatus === -1) return;
    if (this.autoSaveStatus >= 100) {
      if (this.autoSaveTimer) clearTimeout(this.autoSaveTimer);
      this.update(); // save
      return;
    }

    this.autoSaveTimer = setTimeout(() => {
      this.autoSaveRecurring(start + 1);
    }, 1000);
  };

  setValidityStatus = (id, value) => {
    const newValue = {};
    newValue[id] = value;
    this.validityStatus = { ...this.validityStatus, ...newValue };
  };

  saveDisabled = () => !lodashValues(this.validityStatus).every(e => e);

  reset = () => {
    this.submissionId = undefined;
    this.structure = undefined;
    this.values = undefined;

    this.resetNotSaved();
  };

  init = async () => {
    this.autoSaveStatus = 0;
    clearTimeout(this.autoSaveTimer);

    return new Promise(async (resolve, reject) => {
      this.isLoading = true;

      if (!ConfigStore.isOffline) {
        this.templates = (
          await this.graphQlService.get(
            `{ formBuildersActive(platform: "${ConfigStore.platform}") { id name platform structure isActive hubOnly childTemplateId } }`
          )
        ).data.formBuildersActive;
        this.templates = this.templates.map(x => ({
          ...x,
          childTemplateId: x.childTemplateId ? x.childTemplateId.split(",") : []
        }));
        localStorage.setItem(`templates`, JSON.stringify(this.templates));
      } else this.templates = localStorage.getItem(`templates`) ? JSON.parse(localStorage.getItem(`templates`)) : [];

      this.submissionId = undefined;
      resolve(true);
    });
  };

  loadSubmission = async submissionId => {
    this.isLoading = true;

    var submission = undefined;
    if (!ConfigStore.isOffline) {
      submission = (
        await this.graphQlService.get(
          `{ formSubmission(id: "${submissionId}") { id platform structure values createdDateTime title subTitle template { name } parentSubmission { id platform structure values createdDateTime title subTitle template { name } } } }`
        )
      ).data.formSubmission;
    } else {
      var submissions = localStorage.getItem(`submissions`) ? JSON.parse(localStorage.getItem(`submissions`)) : [];
      submission = submissions.find(x => x.id === submissionId);
    }
    if (submission === undefined) {
      message.error(`Submission not found!`);
      return;
    }

    this.template = submission.template;
    this.parseSubmission(submission);
    this.canModify = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 300);
  };

  parseSubmission = submission => {
    this.submissionId = submission.id;
    this.structure = JSON.parse(submission.structure);
    this.template = submission.template;

    if (submission.values) this.values = JSON.parse(submission.values);
    else this.values = [];

    this.values.forEach(valueItem => {
      var field = this.getField(valueItem.id);
      if (field && field.pdfAnnotationContent === true)
        this.pdfAnnotatorClaimContent.push({ id: field.id, value: valueItem.value });
    });

    if (submission.parentSubmission) this.parentSubmission = submission.parentSubmission;
  };

  getValue = id => {
    if (!this.values) return undefined;
    var item = this.values.find(x => x.id === id);

    if (item) return item.value;

    // TEMP: Progreso X example of default values for the cases where it is undefined.
    return undefined;
  };

  GetValues = id => {
    if (!this.values) return undefined;
    var item = this.values.find(x => x.id === id);
    if (item) return item;
    return undefined;
  };

  create = async (templateId, history, folderId = null, goBackLocation = undefined) => {
    this.isLoading = true;
    var submissionId = undefined;

    var payload = { templateId, folderId };

    if (payload.folderId === null) payload.folderId = "ffffffff-ffff-ffff-ffff-ffffffffffff";

    if (!ConfigStore.isOffline)
      submissionId = (
        await this.graphQlService.post(
          `mutation mutate($data: SubmitFormCommand){ createFormSubmissions(data: $data) }`,
          { data: payload }
        )
      ).data.createFormSubmissions;
    else {
      payload["id"] = uuidv4();
      submissionId = payload["id"];
      payload["isOffline"] = true;

      this.templates = localStorage.getItem(`templates`) ? JSON.parse(localStorage.getItem(`templates`)) : [];
      payload["template"] = this.templates.find(x => x.id === templateId);

      payload["platform"] = payload["template"]["platform"];
      payload["structure"] = payload["template"]["structure"];

      if (payload["template"]["childTemplateId"]) {
        var childTemplate = this.templates.find(x => x.id === payload["template"]["childTemplateId"]);
        // create child?
      }

      this.pendingSubmissions.push({ action: "create", data: payload });
      this.submissions.push(payload);

      localStorage.setItem(`submissions`, JSON.stringify(this.submissions));
      localStorage.setItem(`pendingSubmissions`, JSON.stringify(this.pendingSubmissions));
    }

    let newLocation = `/collect/entry/${submissionId}`;

    if (!!goBackLocation) {
      newLocation = newLocation + "?l=" + goBackLocation;
    }
    history.push(newLocation);
    await this.loadSubmission(submissionId);
    this.isLoading = false;
  };

  update = async updateMessage => {
    this.isLoading = true;

    var payload = { id: this.submissionId, values: JSON.stringify(this.values) };

    if (!ConfigStore.isOffline) {
      var r = await this.graphQlService.post(
        `mutation mutate($data: ModifySubmittedFormCommand){ updateFormSubmissions(data: $data) }`,
        { data: payload }
      );
      if (r.errors) {
        this.isLoading = false;
        if (r.errors[0].extensions.data.CODE === "IDENTIFIER_IN_USE") {
          notification.warning({
            message: "Save not possible",
            description: i18next.t("collect.identifierWarning"),
            placement: "topRight"
          });
        } else this.graphQlService.displayErrors(r.errors);
        return false;
      }
    } else {
      payload["isOffline"] = true;
      this.pendingSubmissions.push({ action: "update", data: payload });
      this.submissionId.filter(x => x.id === this.submissionId)[0] = payload;

      localStorage.setItem(`submissions`, JSON.stringify(this.submissions));
      localStorage.setItem(`pendingSubmissions`, JSON.stringify(this.pendingSubmissions));
    }

    if (this.currentPlatform === "web" && updateMessage) message.success(updateMessage);
    if (this.currentPlatform === "mobile" && updateMessage)
      Toast.show({
        content: updateMessage,
        duration: 1500,
        position: "top"
      });

    this.isLoading = false;
    return true;
  };
  resetNotSaved = () => {
    this.notSaved = false;
  };
  updateValue = (id, value, payload) => {
    const currentIdentifiers = this.getIdentifierFields(this.structure);
    console.log("identifiers", toJS(currentIdentifiers), toJS(this.structure));

    var item = this.values.find(x => x.id === id);
    var field = this.getField(id);
    if (!field || field.type === "loop") return;

    if (item) {
      item.name = field.label;
      item.value = value;
      item.payload = payload;
      item.defaultValue = value;
      item.identifier = currentIdentifiers.some(i => i.id === item.id && i.identifier);
      item.required = currentIdentifiers.some(i => i.id === item.id && i.isRequired);
    } else
      this.values.push({
        id,
        name: field.label,
        value,
        defaultValue: value,
        payload,
        identifier: currentIdentifiers.some(i => i.id === id),
        required: currentIdentifiers.some(i => i.id === id && i.isRequired)
      });

    if (field.pdfAnnotationContent === true) {
      this.pdfAnnotatorClaimContent = this.pdfAnnotatorClaimContent.filter(x => x.id !== field.id);
      this.pdfAnnotatorClaimContent.push({ id: field.id, content: value });
    }

    forEach(this.autoUnassignFields, autoUnassignField => {
      if (item && autoUnassignField.fields.includes(item.id)) {
        var oldData = this.values.find(x => x.id === autoUnassignField.id);
        if (oldData) this.updateValue(oldData.id, null, oldData.payload);
      }
    });

    console.log("values", toJS(this.values));
    if (this.values.filter(x => x.identifier || x.required).length > 0) this.notSaved = true;

    // temporaily disable auto save
    // this.toSaveDebounce();
  };

  getField = (id, structure) => {
    var result = undefined;
    if (!structure) structure = this.structure;
    // if (!structure) return undefined;

    for (let rowIndex = 0; rowIndex < structure.rows.length; rowIndex++) {
      var row = structure.rows[rowIndex];
      for (let colIndex = 0; colIndex < row.cols.length; colIndex++) {
        var col = row.cols[colIndex];
        if (col.id === id) result = col;

        if (!result && col.items) {
          for (let itemIndex = 0; itemIndex < col.items.length; itemIndex++) {
            var item = col.items[itemIndex];

            if (item.id === id) result = item;

            if (!result && item.structure) {
              // Check inside for example collapse child
              result = this.getField(id, item.structure);
            }
          }
        }

        if (!result && col.type === "loop") {
          result = this.getField(id, col.structure);
        }
      }
    }

    return result;
  };

  getIdentifierFields = structure => {
    if (!structure || !structure.rows) return [];

    var identifiers = [];
    structure.rows.forEach(row => {
      row.cols.forEach(col => {
        if (col.identifier || col.isRequired) {
          identifiers.push(col);
        }

        if (col.items) {
          for (let itemIndex = 0; itemIndex < col.items.length; itemIndex++) {
            var item = col.items[itemIndex];

            if (item.structure)
              // Check inside for example collapse child
              identifiers = [...identifiers, ...this.getIdentifierFields(item.structure)];
          }
        }
      });
    });

    return identifiers;
  };

  syncPending = async () => {
    for (let index = 0; index < this.pendingSubmissions.length; index++) {
      if (this.pendingSubmissions[index].action === "create")
        await this.graphQlService.post(
          `mutation mutate($data: SubmitFormCommand){ createFormSubmissions(data: $data) }`,
          { data: this.pendingSubmissions[index].data }
        );
      else
        await this.graphQlService.post(
          `mutation mutate($data: ModifySubmittedFormCommand){ updateFormSubmissions(data: $data) }`,
          { data: this.pendingSubmissions[index].data }
        );
      this.pendingSubmissions = this.pendingSubmissions.filter(x => x.id !== this.pendingSubmissions[index].data.id);
    }

    localStorage.setItem(`pendingSubmissions`, JSON.stringify([]));
    this.loadSubmissions();
    this.init();
  };

  deleteForm = async (id, parentSubmissionId = undefined) => {
    this.isLoading = true;
    await this.deleteFormExecute(id);
    this.currentPlatform === "web" && message.success(i18next.t("collect.entryDeleted"));
    this.currentPlatform === "mobile" &&
      Toast.show({
        content: i18next.t("collect.entryDeleted"),
        duration: 1500,
        position: "top"
      });

    if (!parentSubmissionId) {
      var folderId = undefined;
      var currentSelectedFolder = localStorage.getItem(`collectSelectedFolder`);
      if (currentSelectedFolder != null) folderId = JSON.parse(currentSelectedFolder)["record"]["id"];
      this.loadSubmissions(folderId);
    } else this.loadChildSubmissions(parentSubmissionId);
  };

  deleteFormExecute = async id =>
    await this.graphQlService.post(
      `mutation mutate($data: DeleteSubmittedFormTemplateCommand){ formSubmissionDelete(data: $data) }`,
      { data: { id: id } }
    );

  loadChildSubmissions = async (submissionId, updateList = true) => {
    this.isLoading = true;
    const submissionsQueryResult = await this.graphQlService.get(
      `{ formChildSubmissions(parentId: "${submissionId}") { submission { id platform structure values createdDateTime } template { name childTemplateId } createdBy { username } childSubmissions parentSubmission } }`
    );
    this.isLoading = false;
    const childSubs = submissionsQueryResult.data.formChildSubmissions.map(x => ({
      ...x,
      ...x.submission,
      children: x.childSubmissions.length > 0 ? x.childSubmissions : null
    }));
    if (!updateList) return childSubs;
    this.updateSubmissionsList(this.submissions, submissionId, childSubs);
    this.submissions = [...this.submissions];
  };

  loadSubmissions = async (folderId, updateList = true) => {
    this.isLoading = true;
    this.isFiltering = false;
    if (folderId === "ffffffff-ffff-ffff-ffff-ffffffffffff") {
      folderId = null;
    }

    var query = `{ formSubmissions(platform: ${this.currentPlatform === "mobile" ? '["mobile"]' : '["web", "mobile"]'
      }, jqlSearch: ${this.jqlSearch ? `"${JSON.stringify(this.jqlSearch).replaceAll('"', '\\"')}"` : null}, page: ${this.page
      }, pageSize: ${this.pageSize || 15}, folderId: ${!folderId ? null : `"${folderId}"`
      } ) { submission { id platform structure values createdDateTime title subTitle } template { name childTemplateId } createdBy { username } childSubmissions parentSubmission } }`;
    const submissionsQueryResult = await this.graphQlService.get(query);
    var submissions = submissionsQueryResult.data.formSubmissions.map(x => ({
      ...x,
      ...x.submission,
      title: x.submission.title,
      children: x.childSubmissions.length > 0 ? x.childSubmissions : null
    }));

    this.totalSubmissions = submissionsQueryResult.data.totalEntries;

    if (!updateList) {
      this.isLoading = false;
      return submissions;
    }
    this.submissions = submissions;
    this.isLoading = false;
  };
  setTemplate = template => {
    this.template = template;
  };
}

export default new CollectSubmissionStore();
