import { Col, Input, Row } from "antd";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react";
import { Graphics, Text } from "pixi.js";
import { useState } from "react";
import { HuePicker } from "react-color";
import { v4 as uuidv4 } from "uuid";
import DxfEditorStore from "./../DxfEditorStore";
import DocumentCommentStore from "../../../WorflowRiskControl/Documents/components/DocumentCommentStore";
import CommentStore from "../../../WorflowRiskControl/Comments/CommentStore";

export const DxfEditorDrawText = observer(({ store }) => {
  return (
    <div>
      <Row gutter={12}>
        <Col span={24}>
          Content:
          <Input defaultValue={store.textConfig.content} onChange={e => (store.textConfig.content = e.target.value)} />
        </Col>
        <Col span={12}>
          Font size:
          <Input
            defaultValue={store.textConfig.fontSize}
            onChange={e => (store.textConfig.fontSize = +e.target.value)}
          />
        </Col>
        <Col span={12}>
          Font color:
          <HuePicker width="100%" color={store.textConfig.color} onChange={e => (store.textConfig.color = e.hex)} />
        </Col>
      </Row>
    </div>
  );
});

class DxfEditorDrawTextStore {
  initPointer = undefined;
  newGraphic = undefined;

  textConfig = {
    content: "Text..",
    fontSize: 16,
    color: "#ffff00"
  };

  exportConfig = {};

  constructor() {
    makeAutoObservable(this);
  }

  drawText = (store, content, fontSize, x, y, hexColor) => {
    if (!hexColor) hexColor = "#ffffff";
    hexColor = hexColor.replace("#", "0x");

    let text = new Text(content, {
      fontSize: fontSize,
      fill: hexColor
    });
    text.x = x;
    text.y = -y;
    store.viewportRef.addChild(text);

    this.exportConfig = {
      layer: "beawre_annotation",
      handle: "B1",
      ownerHandle: "Beawre",
      colorIndex: 7,
      startPoint: { x: x, y: y, z: 0 },
      text: content,
      textHeight: fontSize
    };

    return text;
  };

  delete(store, item) {
    const allComments = DocumentCommentStore.allComments;
    const text = item.name.slice(item.type.length + 3, item.name.length);

    if (allComments.length > 0) {
      const currentComment = allComments.find(comment => comment.content === text);

      if (currentComment) DocumentCommentStore.delete(currentComment.id);
    }

    store.annotations = [...store.annotations.filter(x => x.key != item.key)];
    item.instance.destroy();
  }

  onMouseMove = (store, e) => {
    this.newGraphic.destroy();

    const mousePosRef = store.viewportRef.toLocal(e.global);
    this.newGraphic = this.drawText(
      store,
      this.textConfig.content,
      this.textConfig.fontSize,
      mousePosRef.x,
      -mousePosRef.y,
      this.textConfig.color
    );
  };

  onMouseDown = (store, e) => {
    this.initPointer = store.viewportRef.toLocal(e.global);

    this.newGraphic = this.drawText(
      store,
      this.textConfig.content,
      this.textConfig.fontSize,
      this.initPointer.x,
      -this.initPointer.y,
      this.textConfig.color
    );
    store.isMouseButtonDown = true;
  };

  onMouseUp = (store, e) => {
    DocumentCommentStore.reply(undefined, this.textConfig.content);

    CommentStore.loadList();

    store.annotations.push({
      key: uuidv4(),
      name: "Text - " + this.textConfig.content,
      type: "text",
      instance: this.newGraphic,
      exportConfig: this.exportConfig
    });
    store.annotations = [...store.annotations];
    store.actionsHistory.push({ action: "create", type: "text", config: this.textConfig, instance: this.newGraphic });
  };

  exportToDxf = (store, results, entity) => {
    results = store.exportInsert(results, 0, "TEXT");
    if (entity.handle) results = store.exportInsert(results, 5, entity.handle);
    if (entity.ownerHandle) results = store.exportInsert(results, 330, entity.ownerHandle);
    results = store.exportInsert(results, 100, "AcDbEntity");
    if (entity.layer) results = store.exportInsert(results, 8, entity.layer);
    if (entity.colorIndex) results = store.exportInsert(results, 62, entity.colorIndex);
    results = store.exportInsert(results, 100, "AcDbText");
    if (entity.startPoint) {
      results = store.exportInsert(results, 10, entity.startPoint.x);
      results = store.exportInsert(results, 20, entity.startPoint.y);
      results = store.exportInsert(results, 30, entity.startPoint.z);
    }
    if (entity.textHeight) results = store.exportInsert(results, 40, entity.textHeight);
    if (entity.text) results = store.exportInsert(results, 1, entity.text);
    if (entity.xScale) results = store.exportInsert(results, 41, entity.xScale);
    results = store.exportInsert(results, 100, "AcDbText");
    return results;
  };
}
export default new DxfEditorDrawTextStore();
