import React from "react";

import { observer } from "mobx-react";
import { Col } from "antd";
import { withTranslation } from "react-i18next";

import FormBuilderStore from "../../FormBuilderStore";
import FormBuilderDropZone from "../inputs/FormBuilderDropZone";
import FormBuilderInput from "../inputs/FormBuilderInput";

class FormBuilderColumn extends React.Component {
  store = FormBuilderStore;
  currentPlatform = localStorage.getItem("platform");

  render() {
    return (
      <Col span={this.currentPlatform === "web" ? this.props.col.span : 24} style={this.props.col.style} >
        <div style={{ border: "1px dotted grey", padding: 5, marginTop: 5, textAlign: "center" }}>

          {/* DISPLAY INPUT */}
          <FormBuilderInput col={{ ...this.props.col, disabled: true, isBuilder: true }} colIndex={this.props.colIndex} rowIndex={this.props.rowIndex} disabled={true} />

          {/* {JSON.stringify(this.props.col)}*/}
            {/* {JSON.stringify(this.store.moveObjectId)}  */}
          {/* DISPLAY DROPBOX IS ITEM IS BEING DRAGGED */}
          {/* {this.store.moveObjectId && this.store.moveObjectId !== this.props.col.id && this.props.col.id && <>
            
            <FormBuilderDropZone
              colIndex={this.props.colIndex}
              rowIndex={this.props.rowIndex}
              parentId={this.props.col.parentId}
              text="Replace with this input!"
            />
          </>} */}

        </div>
      </Col>
    );
  }
}

export default withTranslation()(observer(FormBuilderColumn));
