import React from 'react'
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Button } from "antd";
import { FormOutlined, SearchOutlined, BookOutlined } from "@ant-design/icons";

import BookmarksStore from './BookmarksStore';
import { themeConfig } from '../Layout/config';

export const getIcon = (icon) => {
    if (icon === "FormOutlined") return FormOutlined;
    if (icon === "SearchOutlined") return SearchOutlined;
    return BookOutlined;
}

const BookmarkItem = ({ icon, label, pathname, search, currentTheme }) => {
    const Icon = getIcon(icon)

    return (
        <Button type="text" size="small" style={{ display: "flex", alignItems: "center", margin: "0px 12px 0px 0px" }}>
            <Link to={{ pathname, search }} style={{ color: currentTheme === "dark" ? "#FFF" : "black"}}>
                <Icon style={{ marginRight: 6 }} /> {label.length > 32 ? label.slice(0, 32) + "..." : label}
            </Link>
        </Button>
    )
}

const BookmarksBar = () => {
    const bookmarksStore = BookmarksStore;
    const { currentTheme } = themeConfig();

    return (
        <div style={{
            width: "100vw",
            marginLeft: 0,
            marginBottom: 20,
            paddingLeft: 18,
            height: 42,
            backgroundColor: currentTheme === "light" ? "#FFF" : "#141414",
            borderBottom: currentTheme === "light" ? "1px solid #E8E8E8" : "1px solid #808080" ,
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 12px 20px rgba(0,0,0,.03)"
        }}>
            {bookmarksStore.bookmarks.map((item) => <BookmarkItem key={item.id} label={item.label} icon={item.icon} pathname={item.pathname} search={item.search} currentTheme={currentTheme} />)}
        </div>
    )
}

export default observer(BookmarksBar);