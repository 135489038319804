import { first, forEach, keys, last, toNumber } from "lodash";
import { makeAutoObservable } from "mobx";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";

import GraphQlService from "../../Common/GraphQlService";
import { message } from "antd";
import i18next from "i18next";
dayjs.extend(weekOfYear);

export class CommentStore {
  graphQlService = new GraphQlService();

  isLoading = false;
  creatorsReady = false;
  list = [];
  creators = [];
  searchComponentJqlQuery;

  constructor() {
    makeAutoObservable(this);
  }

  loadDefault = () => {
    // var defaultSearch = JSON.parse(localStorage.getItem(`savedSearchcomments`) || "[]").find((x) => x.isDefault === true);
    // if (!_.isEmpty(window.location.search)) {
    //   // load list by jql query
    //   const queryParams = queryString.parse(window.location.search);
    //   this.loadList(JSON.parse(decodeURIComponent(queryParams.query)));
    // } else if (_.isEmpty(window.location.search) && defaultSearch) {
    //   // no jql but default search, redirect
    //   window.location.replace(`${window.location.origin}${window.location.pathname}${defaultSearch.link}`);
    // } else {
    //   // load default list
    //   this.loadList();
    // }
  };

  loadList = body => {
    const jql = body ? JSON.stringify(body).replaceAll('"', '\\"') : "";
    this.isLoading = true;
    this.graphQlService
      .get(
        `{ commentsByProject(jql: "${jql}") { totalEntries list { id content isResolved mainParent { name } parentId createdDateTime createdBy { id username } payload riskStatus { name icon statusId color }  } } }`
      )
      .then(list => {
        if (!this.creatorsReady && this.list.length === 0 && list.data.commentsByProject.list.length > 0) {
          this.creators = [...new Set(list.data.commentsByProject.list.map(comment => comment.createdBy))];
          this.creatorsReady = true;
        }

        this.list = list.data.commentsByProject.list.map(comment => {
          return {
            ...comment,
            parentId:
              list.data.commentsByProject.list.length > 0 && list.data.commentsByProject.list.length === 1
                ? null
                : comment.parentId,
            payload: JSON.parse(comment.payload)
          };
        });

        // DocumentCommentStore.allComments = this.list;
        // DocumentCommentStore.comments = this.list;
        this.isLoading = false;
      });
  };

  //
  // Bubble chart feed
  //
  generateListOfWeeks(startYear, startMonth, endYear, endMonth) {
    let yearMonthList = [];
    for (var year = startYear; year <= endYear; year++) {
      for (var month = 1; month <= 52; month++) {
        if (year === startYear && month < startMonth) continue;
        if (year === toNumber(endYear) && month > toNumber(endMonth)) break;
        yearMonthList.push(year + "-" + month);
      }
    }
    return yearMonthList;
  }

  getNameOfRiskStatus(comments) {
    const orderedEntries = _(comments)
      .filter(x => x.riskStatus)
      .orderBy(x => x.riskStatus.statusId)
      .value();
    if (orderedEntries.length === 0) return " ";

    const returnValue = last(orderedEntries).riskStatus.name;
    if (returnValue === "Unspecified") return "Completed";
    return returnValue;
  }

  get commentsByWeek() {
    const chartData = {
      yName: "Originator name",
      xDict: [],
      yDict: [],
      data: []
    };
    const commentsGroupedByWeek = _(this.list)
      .map(comment => {
        const createdDate = comment.payload.DateRaised ? comment.payload.DateRaised : comment.createdDateTime;
        const date = dayjs(createdDate);

        if (!date) return { ...comment, groupCreatedDate: "0000-00" };
        const groupCreatedDate = date.year() + "-" + date.week();
        return { ...comment, groupCreatedDate };
      })
      .filter(comment => !comment.groupCreatedDate.includes("NaN"))
      .filter(x => x.payload.Originator)
      .filter(x => !x.payload.Originator.includes("/"))
      .filter(x => x.payload.Originator !== "")
      .filter(x => toNumber(x.groupCreatedDate.split("-")[0]) >= 2022)
      .orderBy(comment => dayjs(comment.groupCreatedDate.split("-")[0]).week(comment.groupCreatedDate.split("-")[1]))
      .groupBy(comment => comment.groupCreatedDate)
      .value();

    const yFromData = _(this.list)
      .filter(x => x.payload.Originator)
      //.filter((x) => x.payload.Originator !== "")
      .filter(x => !x.payload.Originator.includes("/"))
      .groupBy(x => x.payload.Originator)
      .keys()
      .value();

    if (keys(commentsGroupedByWeek).length > 0) {
      const startYear = first(keys(commentsGroupedByWeek)).split("-")[0];
      const startMonth = first(keys(commentsGroupedByWeek)).split("-")[1];
      const endYear = last(keys(commentsGroupedByWeek)).split("-")[0];
      const endMonth = last(keys(commentsGroupedByWeek)).split("-")[1];

      const xDict = this.generateListOfWeeks(startYear, startMonth - 1, endYear, endMonth + 1);

      const yDict = [" ", ...yFromData];
      chartData.yDict = yDict;
      chartData.xDict = xDict;

      forEach(keys(commentsGroupedByWeek), xDate => {
        const oneDateData = commentsGroupedByWeek[xDate];
        const oneDateDataGroupedByOrganizor = _(oneDateData)
          .groupBy(comment => comment.payload.Originator)
          .forEach((comments, type) => {
            if (comments.length > 0) {
              chartData.data.push({
                type,
                activityId: type + "_" + xDate,
                date: xDate,
                name: xDate + "_" + type,
                //                riskStatus: this.getNameOfRiskStatus(comments),
                riskStatus: " ",
                x: xDict.indexOf(xDate),
                y: yDict.indexOf(type),
                z: comments.length
              });
            }
          });
      });
    }
    return chartData;
  }

  get byDeliverableByOriginator() {
    var commentsByDeliverableByOriginator = _(this.list)
      .groupBy(comment => {
        if (!comment.payload.DeliverableDiscipline) return " ";
        const discipline = comment.payload.DeliverableDiscipline.split("-");
        if (discipline.length <= 1) return " ";
        return discipline[1].trim();
      })
      .map((comments, deliverableName) => {
        const byOriginator = _(comments)
          .groupBy(x => x.payload.Originator)
          .map((commentsByOriginator, originatorName) => {
            return {
              key: originatorName,
              value: commentsByOriginator.length
            };
          })
          .value();

        let returnValues = {
          key: deliverableName,
          total: comments.length,
          data: byOriginator
        };

        return returnValues;
      })
      .filter(x => x.key !== "undefined" && x.key !== " " && x.total > 1)
      .value();

    return commentsByDeliverableByOriginator;
  }

  update = ({ id, content, file, owner, deadline }) => {
    this.isLoading = true;
    this.graphQlService
      .post(`mutation mutate($data: UpdateCommentCommand){ commentUpdate(data: $data) }`, {
        data: { id: id, content: content, owner: owner, deadline: deadline, file }
      })
      .then(r => {
        this.isLoading = false;
        if (r.errors) this.graphQlService.displayErrors(r.errors);
        else {
          message.success(i18next.t("comment.commentModified"));
          this.loadDefault();
        }
      });
  };

  delete = id => {
    this.isLoading = true;
    this.graphQlService.post(`mutation mutate($data: Guid){ commentDelete(id: $data) }`, { data: id }).then(r => {
      this.isLoading = false;
      if (r.errors) this.graphQlService.displayErrors(r.errors);
      else {
        message.success(i18next.t("comment.commentDeleted"));
        this.loadDefault();
      }
    });
  };

  reply = (content, owner, deadline, parentId) => {
    this.isLoading = true;

    let data = { content: content, payload: JSON.stringify({ Deadline: deadline, Owner: owner }) };
    if (this.containerId) {
      data.mainParentType = "Container";
      data.mainParentId = this.containerId;
    }

    if (parentId) {
      data.targetObjectType = "Comments";
      data.targetObjectId = parentId;
    }
    this.graphQlService
      .post(`mutation mutate($data: CreateCommentCommand){ commentCreate(data: $data) }`, {
        data: data
      })
      .then(r => {
        this.isLoading = false;
        if (r.errors) this.graphQlService.displayErrors(r.errors);
        else this.loadDefault();
      });
  };
}

export default new CommentStore();
