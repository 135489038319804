import React, { useState, useEffect } from "react";
import { Form, Checkbox, Space } from "antd-mobile";

const FormCheckboxGroupMobile = props => {
  useEffect(() => {
    if (props.storeValue === undefined && props.value !== undefined && props.updateValue)
      props.updateValue(props.id, props.value);
  }, [props.value]);

  return (
    <Space style={{ display: "flex" }}>
      <Form.Item label={props.label} name={props.label}>
        <Checkbox.Group
          disabled={props.disabled}
          name={props.id}
          value={props.storeValue !== undefined ? props.storeValue : props.value}
          key={props.id}
          options={props.items ? props.items : []}
          onChange={newValue => props.updateValue && props.updateValue(props.id, newValue)}
        >
          <Space direction="vertical" style={{ alignItems: "flex-start", marginTop: 14 }}>
            {props.items.map(item => (
              <Checkbox value={item.value} name={item.idx}>
                {item.label}
              </Checkbox>
            ))}
          </Space>
        </Checkbox.Group>
      </Form.Item>
    </Space>
  );
};

export default FormCheckboxGroupMobile;
