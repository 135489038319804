import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { withTranslation } from "react-i18next";

const FormInput = props => {
  const normalizeStr = str => str.replace(" ", "").toUpperCase();

  const [value, setValue] = useState();
  const [validationError, setValidationError] = useState(undefined);

  useEffect(() => {
    setValue(props.storeValue !== undefined ? props.storeValue : props.value);

    validate();
  }, []);

  useEffect(() => {
    if (props.storeValue === undefined && props.value !== undefined && props.updateValue)
      props.updateValue(props.id, props.value);
    validate();
  }, [props.value]);

  useEffect(() => validate(), [value]);

  const validate = () => {
    if (!props.identifier || !props.isSubmission) return;

    setValidationError(undefined);

    if (!value || value === "") {
      setValidationError({ status: "error", text: props.t("collect.validations.missingValue") });
      if (props.setValidityStatus) props.setValidityStatus(props.id, false);
    } else {
      setValidationError({ status: undefined, text: "" });
      if (props.setValidityStatus) props.setValidityStatus(props.id, true);
    }
  };

  return (
    <>
      <Form.Item
        name={props.label}
        label={
          <span>
            {props.label} {props.isRequired && <span style={{ color: "red", marginLeft: 2 }}>(*)</span>}
          </span>
        }
        disabled={props.disabled}
        style={{ margin: "15px 0px" }}
        validateStatus={validationError?.status}
        hasFeedback
        help={validationError?.text}
      >
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Input
            style={{ marginRight: 20 }}
            placeholder={props.placeholder}
            key={props.id}
            value={value}
            disabled={props.disabled}
            onChange={e => setValue(e.target.value)}
            onBlur={() => {
              props.updateValue && props.updateValue(props.id, value);
            }}
          />
        </div>
      </Form.Item>
    </>
  );
};

export default withTranslation()(FormInput);
