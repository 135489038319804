import * as _ from "lodash";
import { orderBy } from "lodash";
import { makeAutoObservable, toJS } from "mobx";
import BackendService from "../../Common/BackendService";
import GraphQlService from "../../Common/GraphQlService";

class TasksStore {
  graphqlApi = new BackendService("graphql");
  containers = new BackendService("containers");
  graphQlService = new GraphQlService();

  loading = true;
  noData = false;
  allTasksLoading = true;
  disableLoadMore = false;
  connectedDocumentsList = [];
  queryBuilderJson;

  uploadPrimaveraModalVisible = false;

  tasks = undefined;

  configModalVisible = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadDefault() {
    this.loading = true;
    this.graphQlService
      .get(
        `{containers(where: { path: "Type", comparison: EQUAL, value: "task" }){id,rootId,payload,name,riskStatus{icon,name,color,statusId},parentOf{id},childOf{id},createdDateTime}}`
      )
      .then(r => {
        let list = this.graphQlService.deserializeObjects(r.data.containers);
        if (list.length === 0) this.noData = true;
        else {
          var root = list.find(x => x.payload.Id === "root");

          if (!root) this.noData = true;
          else this.tasks = [this.buildTree(root, list)];
        }
        this.loading = false;
      });
  }

  buildTree = (branch, tasks) => {
    if (branch.parentOf && branch.parentOf.length > 0) {
      branch.children = [];
      branch.parentOf.forEach(child => {
        if (tasks.find(x => x.id === child.id))
          branch.children.push(
            this.buildTree(
              tasks.find(x => x.id === child.id),
              tasks
            )
          );
      });
    }
    return branch;
  };

  flatten = items => {
    const flat = [];

    items.forEach(item => {
      if (Array.isArray(item.children)) {
        flat.push(...this.flatten(item.children));
      } else {
        flat.push(item);
      }
    });

    return flat;
  };

  searchByValue(value) {
    this.loading = true;
    this.containers.get(`search/task?value=${encodeURIComponent(value)}`).then(data => {
      if (_.isNull(_.first(data))) {
        this.noData = true;
      } else {
        let t = this.graphqlApi.deserializePayload(data);
        this.tasks = this.removeEmptyChildren(t);

        this.disableLoadMore = true;
      }
      this.loading = false;
    });
  }

  // searchByJql(body) {
  //
  //   this.loading = true;
  //   this.containers.post(`search/by/jql/task`, body).then((data) => {
  //     if (_.isNull(_.first(data))) {
  //       this.noData = true;
  //     } else {
  //
  //       let t = this.graphqlApi.deserializePayload(data);
  //
  //       this.tasks = this.removeEmptyChildren(t);

  //       this.disableLoadMore = true;
  //     }
  //     this.loading = false;
  //   });
  // }

  searchByJql(body) {
    this.loading = true;

    const jql = JSON.stringify(body).replaceAll('"', '\\"');

    const jqlParents = JSON.stringify({
      id: "ba8a9a98-0123-4456-b89a-b1809a634b45",
      type: "group",
      children1: [
        {
          id: "8b8bb9bb-cdef-4012-b456-71809a636183",
          type: "rule",
          properties: {
            field: "Name",
            operator: "equal",
            value: ["Construction"],
            valueSrc: ["value"],
            valueType: ["text"]
          }
        },
        {
          id: "99b9a889-89ab-4cde-b012-31809a63a5f1",
          type: "rule",
          properties: {
            field: "Name",
            operator: "equal",
            value: ["Preconstruction"],
            valueSrc: ["value"],
            valueType: ["text"]
          }
        },
        {
          id: "b888889b-4567-489a-bcde-f1809a655308",
          type: "rule",
          properties: {
            field: "Name",
            operator: "equal",
            value: ["Close-out"],
            valueSrc: ["value"],
            valueType: ["text"]
          }
        },
        {
          id: "aaa8b9b9-0123-4456-b89a-b1809a659251",
          type: "rule",
          properties: {
            field: "Name",
            operator: "equal",
            value: ["Bridge USC"],
            valueSrc: ["value"],
            valueType: ["text"]
          }
        }
      ],
      properties: {
        conjunction: "OR"
      }
    }).replaceAll('"', '\\"');

    this.graphQlService
      .client()
      .query({
        query: this.graphQlService.stringToGql(
          `{ containers(where: [{ path: "Type" comparison: EQUAL value: "task" }], jqlAssetType: "task", jql: "${jqlParents}"){id,rootId,payload,name,riskStatus{icon,name,color,statusId},parentOf{id},childOf{id},createdDateTime}}`
        )
      })
      .then(pre => {
        this.disableLoadMore = true;

        const parents = this.graphQlService.deserializeObjects(pre.data.containers);
        const root = parents.find(x => x.payload.Id === "root");

        this.graphQlService
          .client()
          .query({
            query: this.graphQlService.stringToGql(
              `{ containers(where: [{ path: "Type" comparison: EQUAL value: "task" }], jqlAssetType: "task", jql: "${jql}"){id,rootId,payload,name,riskStatus{icon,name,color,statusId},parentOf{id},childOf{id},createdDateTime}}`
            )
          })
          .then(res => {
            this.disableLoadMore = true;

            const list = this.graphQlService.deserializeObjects(res.data.containers);
            if (list.length === 0) this.noData = true;
            else {
              const parentsIds = [];
              list.forEach(item =>
                item.childOf.forEach(parent => {
                  if (!parentsIds.includes(parent.id)) return parentsIds.push(parent.id);
                })
              );
              this.tasks = [this.buildTree(root, parents.filter(task => parentsIds.includes(task.id)).concat(list))];
            }
            this.loading = false;
          });
      });
  }

  removeEmptyChildren(data) {
    data.forEach(entry => {
      if (_.isEmpty(entry["children"])) {
        delete entry["children"];
      } else {
        entry["children"] = this.removeEmptyChildren(entry["children"]);
        entry["children"] = orderBy(entry.children, x => +x.payload.Id, "asc");
      }
    });
    return data;
  }

  changeObjectById(newData, tasks) {
    tasks.forEach((task, index) => {
      if (task.id === newData.id) {
        task["children"] = this.removeEmptyChildren(newData["children"]);
      } else {
        if (!_.isEmpty(task["children"])) {
          task["children"] = this.changeObjectById(newData, task["children"]);
        }
      }
    });
    return tasks;
  }

  loadMoreData(objectPayloadId) {
    if (!this.disableLoadMore) {
      const query = `?query={containers(where:{path:"id",comparison:EQUAL,value:"${objectPayloadId}"}){id,rootId,payload,name,riskStatus{icon,name,color,statusId},children:parentOf{id,rootId,payload,name,riskStatus{icon,name,color,statusId},children:parentOf{id,rootId,name,payload,riskStatus{icon,name,color,statusId}}}}}`;
      this.graphqlApi.get(query).then(data => {
        if (!_.isUndefined(data)) {
          const dataDeserialized = this.graphqlApi.deserializePayload(data.data["containers"]);

          let promises = [];
          _.forEach(dataDeserialized, task => {
            _.forEach(task.children, subTask => {
              if (subTask.children.length === 0) {
                // Is task
                promises.push(
                  new Promise((resolve, reject) => {
                    this.graphqlApi
                      .get(
                        `?query={containers(where:{path:"id",comparison:EQUAL,value:"${subTask.id}"}){id,documentsOfTask{name,payload,id,riskStatus{icon,name,color,statusId}}}}`
                      )
                      .then(data => {
                        if (!_.isUndefined(data)) {
                          let documents = this.graphqlApi.deserializePayload(data.data["containers"])[0]
                            .documentsOfTask;
                          subTask.connectedDocuments = documents;
                        }
                        resolve();
                      });
                  })
                );
              }
            });
          });
          Promise.all(promises).then(r => {
            const newTasks = this.changeObjectById(dataDeserialized[0], [...toJS(this.tasks)]);
            this.tasks = newTasks;
          });
        }
      });
    }
  }

  loadDocuments(taskId) {
    this.loading = true;
    this.graphqlApi
      .get(
        `?query={containers(where:{path:"id",comparison:EQUAL,value:"${taskId}"}){id,documentsOfTask{name,payload,id,riskStatus{icon,name,color,statusId}}}}`
      )
      .then(data => {
        if (!_.isUndefined(data)) {
          let documents = this.graphqlApi.deserializePayload(data.data["containers"]);
          this.connectedDocumentsList = documents[0].documentsOfTask;
          this.loading = false;
        }
      });
  }

  getAllTasks() {
    const query = `?query={containers(where:[{path: "Type",comparison:EQUAL,value: "task"},{path:"Name",comparison:CONTAINS,value:"B2"}]){id,key:id,payload,name,riskStatus{icon,name,color,statusId}}}`;
    return this.graphqlApi.get(query);
  }

  getDocumentsTasksMap() {
    const query = `?query={containers(where:{path:%20%22Type%22,%20comparison:%20EQUAL,%20value:%22document%22}){id,tasks:parentOf{id,rootId}}}`;
    return this.graphqlApi.get(query);
  }

  generateTreeMode(tasks) {
    if (!tasks) return [];
    return tasks.map(task => {
      return {
        title: task.name,
        key: task.id,
        children: !_.isUndefined(task.children) && !_.isEmpty(task.children) ? this.generateTreeMode(task.children) : []
      };
    });
  }

  get tasksInTreeMode() {
    return this.generateTreeMode(this.tasks);
  }

  get tasksFlatten() {
    return this.flatten(toJS(this.tasks));
  }

  get defaultExpandedRowKeys() {
    let taskKeys = [];
    _.forEach(toJS(this.tasks), task => {
      taskKeys.push(task.id);
      if (!_.isEmpty(task.children)) {
        _.forEach(task.children, subTask => {
          taskKeys.push(subTask.id);
        });
      }
    });
    return taskKeys;
  }
}
export default new TasksStore();
