import { computed } from "mobx";
import CollectSubmissionStore from "../../Collect/Submissions/FormSubmissionStore";
import Store from "../../Automate/Automate/Workflows/Store";
import AutomateWorkflowTemplateStore from "../../Automate/Automate/Templates/AutomateWorkflowTemplateStore";
import FormBuilderStore from "../../Collect/MyForms/FormBuilderStore";
import RiskAssessmentManagementStore from "../../Enable/RiskAssessmentManagement/RiskAssessmentManagementStore";
import BusinessProcessManagementStore from "../../Enable/BusinessProcessManagement/BusinessProcessManagementStore";
export const getDynamicTitles = () => ({
  dynamicEntryName: CollectSubmissionStore.template?.name,
  workflowName: AutomateWorkflowTemplateStore.workflow?.name,
  dynamicWorkFlowName: Store.workflow?.name,
  riskAssessmentStore: RiskAssessmentManagementStore.project?.name,
  dynamicTemplateName: AutomateWorkflowTemplateStore.workflow?.name,
  dynamicFormName: FormBuilderStore?.name,
  dynamicbusinessProcessManagement: BusinessProcessManagementStore.project?.name,
  dynamicriskAssessmentMngmntPage: RiskAssessmentManagementStore.project?.name,
  dynamicCorporateRepo: BusinessProcessManagementStore.project?.name
});
export const getStoreTitle = storeKey => {
  const storeTitleMap = {
    dynamicEntryName: computed(() => CollectSubmissionStore.template?.name),
    automateWorkflowsStore: computed(() => Store.workflow?.name),
    automateWorkflowTemplateStore: computed(() => AutomateWorkflowTemplateStore.workflow?.name),
    formBuilderStore: computed(() => FormBuilderStore?.name),
    riskAssessmentManagementStore: computed(() => RiskAssessmentManagementStore.project?.name),
    businessProcessManagementStore: computed(() => BusinessProcessManagementStore.project?.name)
  };

  return storeTitleMap[storeKey]?.get();
};
