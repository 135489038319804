import { makeAutoObservable } from "mobx";
import BackendService from "../../Common/BackendService";
import CommentStore from "../../WorflowRiskControl/Comments/CommentStore";
import SearchComponentStore from "../Search/SearchComponentStore";
import DocumentsStore from "./../../WorflowRiskControl/Documents/DocumentsStore";
import TasksStore from "./../../WorflowRiskControl/Tasks/TasksStore";

class BaseLayoutStore {
  menuCollapsed = false;
  user = {};
  userApi = new BackendService("users");
  settingsEvidenceActiveKey = "";

  tasksStore = TasksStore;
  documentsStore = DocumentsStore;
  searchStore = SearchComponentStore;
  commentStore = CommentStore;

  forceShowSearch = false;

  searchComponentJqlQuery() {
    return this.documentsStore.searchComponentJqlQuery;
  }

  tasksLoadDefault() {
    this.tasksStore.loadDefault();
  }

  documentsLoadDefault() {
    this.documentsStore.loadDefault();
  }

  commentsLoadDefault() {
    // THIS IS BREAKING LOADING COMMENTS BY JQL OR DOCUMENT
    // this.commentStore.loadList();
  }

  toggleMenuCollapsed() {
    this.menuCollapsed = !this.menuCollapsed;
  }

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    if (!window.location.href.includes(`postlogin`)) {
      this.userApi.get().then(user => {
        this.user = user;
        if (this.user) localStorage.setItem("email", this.user.email);
      });
    }
  };
}

export default new BaseLayoutStore();
