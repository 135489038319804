import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Drawer,
  List,
  Popover,
  Row,
  Spin,
  Switch,
  Tag,
  Tooltip,
  Upload
} from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  FolderOpenOutlined,
  SaveOutlined,
  UploadOutlined
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
// import store from "./store";
import DxfEditorDrawLineStore, { DxfEditorDrawLine } from "./components/DxfEditorDrawLine";
import DxfEditorDrawTextStore, { DxfEditorDrawText } from "./components/DxfEditorDrawText";
import DxfEditorDrawRectangleStore, { DxfEditorDrawRectangle } from "./components/DxfEditorDrawRectangle";
import DxfEditorDrawCircleStore, { DxfEditorDrawCircle } from "./components/DxfEditorDrawCircle";
import { v4 as uuidv4 } from "uuid";
import DxfEditorStore from "./DxfEditorStore";
import {
  AnnotationIcon,
  CircleIcon,
  GridIcon,
  InvertIcon,
  LayersIcon,
  LineIcon,
  RectangleIcon,
  TextIcon
} from "./DxfEditorIcons";
import DocumentCommentStore from "../../WorflowRiskControl/Documents/components/DocumentCommentStore";
import DocumentsStore from "../../WorflowRiskControl/Documents/DocumentsStore";
import BookmarksStore from "../../Common/Bookmarks/BookmarksStore";

// -------------------------------------
// Menu button components
// -------------------------------------
const EditorLineButton = ({ dxfStore }) => (
  <Popover
    title={
      <Row>
        <Col>Line</Col>
        <Col style={{ marginLeft: "auto" }}>
          <CloseOutlined onClick={() => dxfStore.toggleEditShape("line")} />
        </Col>
      </Row>
    }
    trigger="click"
    open={dxfStore.editShapeSelected === "line"}
    content={<DxfEditorDrawLine store={DxfEditorDrawLineStore} />}
    placement="rightTop"
  >
    <Tooltip title="Line" placement="right">
      <Button type="text" size="large" icon={<LineIcon />} onClick={() => dxfStore.toggleEditShape("line")} />
    </Tooltip>
  </Popover>
);

const EditorTextButton = ({ dxfStore }) => (
  <Popover
    title={
      <Row>
        <Col>Text</Col>
        <Col style={{ marginLeft: "auto" }}>
          <CloseOutlined onClick={() => dxfStore.toggleEditShape("text")} />
        </Col>
      </Row>
    }
    placement="rightTop"
    open={dxfStore.editShapeSelected === "text"}
    onOpenChange={() => dxfStore.toggleEditShape("text")}
    content={<DxfEditorDrawText store={DxfEditorDrawTextStore} />}
    trigger="click"
  >
    <Tooltip title="Text" placement="right">
      <Button type="text" size="large" icon={<TextIcon />} />
    </Tooltip>
  </Popover>
);

const EditorCircleButton = ({ dxfStore }) => (
  <Popover
    title={
      <Row>
        <Col>Circle</Col>
        <Col style={{ marginLeft: "auto" }}>
          <CloseOutlined onClick={() => dxfStore.toggleEditShape("circle")} />
        </Col>
      </Row>
    }
    placement="rightTop"
    open={dxfStore.editShapeSelected === "circle"}
    onOpenChange={() => dxfStore.toggleEditShape("circle")}
    content={<DxfEditorDrawCircle store={DxfEditorDrawCircleStore} />}
    trigger="click"
  >
    <Tooltip title="Circle" placement="right">
      <Button type="text" size="large" icon={<CircleIcon />} />
    </Tooltip>
  </Popover>
);

const EditorRectangelButton = ({ dxfStore }) => (
  <Popover
    title={
      <Row>
        <Col>Rectangle</Col>
        <Col style={{ marginLeft: "auto" }}>
          <CloseOutlined onClick={() => dxfStore.toggleEditShape("rectangle")} />
        </Col>
      </Row>
    }
    placement="rightTop"
    open={dxfStore.editShapeSelected === "rectangle"}
    onOpenChange={() => dxfStore.toggleEditShape("rectangle")}
    content={<DxfEditorDrawRectangle store={DxfEditorDrawRectangleStore} />}
    trigger="click"
  >
    <Tooltip title="Rectangle" placement="right">
      <Button type="text" size="large" icon={<RectangleIcon />} />
    </Tooltip>
  </Popover>
);
// -------------------------------------
// DXF Editor definition
// -------------------------------------
const DxfEditor = ({ t, history, store, fileContent, location, match }) => {
  const [divId, setDivId] = useState(uuidv4());
  let [dxfStore, setDxfStore] = useState(store ? store : new DxfEditorStore());
  // View controls
  const [layersOpen, setLayersOpen] = useState(false);
  const [annotationsDrawerOpen, setAnnotationsDraweOpen] = useState(false);

  useEffect(() => {
    dxfStore.isLoading = true;
    if (fileContent) dxfStore.loadDxfFile(fileContent, divId);

    if (match.params.containerId) DocumentCommentStore.containerId = match.params.containerId;

    if (!store && match.params.containerId) {
      setTimeout(() => {
        DocumentsStore.getDxf(match.params.containerId)
          .then(async res => res.Blob.text())
          .then(text => dxfStore.loadDxfFile(text, divId));
      }, 2000);
    }
  }, []);

  return (
    <>
      {dxfStore && dxfStore.isLoading && <Spin spinning={true} />}
      {dxfStore && (
        <>
          <div
            style={{
              backgroundColor: "#efefef",
              border: "1px solid #cecece",
              position: "fixed",
              top: 230,
              left: 60,
              zIndex: 10
            }}
          >
            {dxfStore.mode === "edit" && (
              <>
                <Popover
                  title={
                    <Row>
                      <Col>Line</Col>
                      <Col style={{ marginLeft: "auto" }}>
                        <CloseOutlined onClick={() => dxfStore.toggleEditShape("line")} />
                      </Col>
                    </Row>
                  }
                  trigger="click"
                  open={dxfStore.editShapeSelected === "line"}
                  content={<DxfEditorDrawLine store={DxfEditorDrawLineStore} />}
                  placement="rightTop"
                >
                  <Tooltip title="Line" placement="right">
                    <Button
                      type="text"
                      size="large"
                      icon={<LineIcon />}
                      onClick={() => dxfStore.toggleEditShape("line")}
                    />
                  </Tooltip>
                </Popover>
                <br />
                <Popover
                  title={
                    <Row>
                      <Col>Text</Col>
                      <Col style={{ marginLeft: "auto" }}>
                        <CloseOutlined onClick={() => dxfStore.toggleEditShape("text")} />
                      </Col>
                    </Row>
                  }
                  placement="rightTop"
                  open={dxfStore.editShapeSelected === "text"}
                  content={<DxfEditorDrawText store={DxfEditorDrawTextStore} />}
                  trigger="click"
                >
                  <Tooltip title="Text" placement="right">
                    <Button
                      type="text"
                      size="large"
                      icon={<TextIcon />}
                      onClick={() => dxfStore.toggleEditShape("text")}
                    />
                  </Tooltip>
                </Popover>
                <br />
                <Popover
                  title={
                    <Row>
                      <Col>Circle</Col>
                      <Col style={{ marginLeft: "auto" }}>
                        <CloseOutlined onClick={() => dxfStore.toggleEditShape("circle")} />
                      </Col>
                    </Row>
                  }
                  placement="rightTop"
                  open={dxfStore.editShapeSelected === "circle"}
                  content={<DxfEditorDrawCircle store={DxfEditorDrawCircleStore} />}
                  trigger="click"
                >
                  <Tooltip title="Circle" placement="right">
                    <Button
                      type="text"
                      size="large"
                      icon={<CircleIcon />}
                      onClick={() => dxfStore.toggleEditShape("circle")}
                    />
                  </Tooltip>
                </Popover>
                <br />
                <Popover
                  title={
                    <Row>
                      <Col>Rectangle</Col>
                      <Col style={{ marginLeft: "auto" }}>
                        <CloseOutlined onClick={() => dxfStore.toggleEditShape("rectangle")} />
                      </Col>
                    </Row>
                  }
                  placement="rightTop"
                  open={dxfStore.editShapeSelected === "rectangle"}
                  content={<DxfEditorDrawRectangle store={DxfEditorDrawRectangleStore} />}
                  trigger="click"
                >
                  <Tooltip title="Rectangle" placement="right">
                    <Button
                      type="text"
                      size="large"
                      icon={<RectangleIcon />}
                      onClick={() => dxfStore.toggleEditShape("rectangle")}
                    />
                  </Tooltip>
                </Popover>
                <br />
                <Divider />
              </>
            )}
            <Tooltip title={dxfStore.mode === "view" ? "Edit Mode" : "View Mode"} placement="right">
              <Button
                type="text"
                size="large"
                icon={dxfStore.mode === "view" ? <EditOutlined /> : <EyeOutlined />}
                onClick={() => dxfStore.changeMode(dxfStore.mode === "view" ? "edit" : "view")}
              />
            </Tooltip>
            <br />
            <Tooltip title="Enable / Disable Grid" placement="right">
              <Button type="text" size="large" icon={<GridIcon />} onClick={() => dxfStore.toggleGridOn()} />
            </Tooltip>
            <br />
            <Popover
              title={
                <Row>
                  <Col>Layers</Col>
                  <Col style={{ marginLeft: "auto" }}>
                    <CloseOutlined onClick={() => setLayersOpen(false)} />
                  </Col>
                </Row>
              }
              trigger="click"
              onOpenChange={newOpen => setLayersOpen(newOpen)}
              open={layersOpen}
              content={dxfStore.layers.map(layer => (
                <h3 key={`layer_${layer.name}_${layer.hexColor}`}>
                  <Checkbox
                    defaultChecked={layer.visible || true}
                    onChange={e => dxfStore.toggleLayerVisibility(layer.name)}
                  >
                    <Tag color={layer.hexColor} style={{ border: "1px solid grey", color: layer.hexColor }}>
                      X
                    </Tag>{" "}
                    {layer.name}
                  </Checkbox>
                </h3>
              ))}
              placement="rightTop"
            >
              <Tooltip title="Layers" placement="right">
                <Button type="text" size="large" icon={<LayersIcon />} />
              </Tooltip>
            </Popover>
            <br />
            <Tooltip title="Show / Hide Annotations" placement="right">
              <Button
                type="text"
                size="large"
                icon={<AnnotationIcon />}
                onClick={() => setAnnotationsDraweOpen(!annotationsDrawerOpen)}
              />
            </Tooltip>
            <br />
            <Tooltip title="Invert Colors" placement="right">
              <Button
                type="text"
                size="large"
                icon={<InvertIcon />}
                onClick={() =>
                  dxfStore.changeBackgroundColor(dxfStore.backgroundColor === "#111111" ? "#ffffff" : "#111111", divId)
                }
              />
            </Tooltip>
            {/* <br />
            <Tooltip title="Open file" placement="right">
              <Button type="text" size="large" icon={<FolderOpenOutlined />} />
            </Tooltip>
            <br />
            <Tooltip title="Export file" placement="right">
              <Button type="text" size="large" icon={<SaveOutlined />} onClick={() => dxfStore.exportData()} />
            </Tooltip> */}
          </div>
          <Card>
            <div id={divId} style={{ marginRight: 10, width: "100%" }}></div>
          </Card>
          <Drawer
            title="Annotations"
            style={!BookmarksStore.showBookmarks ? { paddingTop: 48 } : { paddingTop: 48, marginTop: 35 }}
            maskStyle={{ backgroundColor: "transparent" }}
            open={annotationsDrawerOpen}
            onClose={() => setAnnotationsDraweOpen(false)}
            size="large"
          >
            {dxfStore.annotations && dxfStore.mode === "edit" && (
              <List
                dataSource={dxfStore.annotations}
                renderItem={item => (
                  <List.Item
                    actions={[
                      <Button icon={<DeleteOutlined />} size="small" onClick={() => dxfStore.deleteAnnotation(item)} />
                    ]}
                  >
                    {item.name}
                  </List.Item>
                )}
              />
            )}
          </Drawer>
        </>
      )}
    </>
  );
};

export default observer(DxfEditor);
