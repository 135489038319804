import { Graphics, Sprite } from "pixi.js";
import DxfEditorStore from "../DxfEditorStore";
import { v4 as uuidv4 } from "uuid";
import { makeAutoObservable, toJS } from "mobx";
import { observer } from "mobx-react";
import { Col, Input, Row } from "antd";
import { HuePicker } from "react-color";
import { SmoothGraphics } from "@pixi/graphics-smooth";

export const DxfEditorDrawCircle = observer(({ store }) => {
  return (
    <div>
      <Row gutter={12}>
        <Col span={12}>
          Line width:
          <Input defaultValue={store.shapeConfig.width} onChange={(e) => (store.shapeConfig.width = +e.target.value)} />
        </Col>
        <Col span={12}>
          Diameter:
          <Input defaultValue={store.shapeConfig.diameter} onChange={(e) => (store.shapeConfig.diameter = +e.target.value)} />
        </Col>
        <Col span={12}>
          Color:
          <HuePicker width="100%" color={store.shapeConfig.hexColor} onChange={(e) => (store.shapeConfig.hexColor = e.hex)} />
        </Col>
      </Row>
    </div>
  );
});

class DxfEditorDrawCircleStore {
  initPointer = undefined;
  newGraphic = undefined;

  shapeConfig = {
    width: 2,
    diameter: 12,
    hexColor: "#ffff00",
  };
  exportConfig = {};

  constructor() {
    makeAutoObservable(this);
  }

  draw = (store, item) => {
    if (!item.hexColor) item.hexColor = "#ffffff";
    item.hexColor = item.hexColor.replace("#", "0x");

    const shape = new SmoothGraphics();
    // shape.beginFill(hexColor);
    shape.lineStyle(item.width, item.hexColor);
    shape.drawCircle(item.x, -item.y, item.diameter / 2);
    shape.endFill();

    var texture = store.app.renderer.generateTexture(shape);
    let sprite = new Sprite(texture);

    sprite.position.x = item.x;
    sprite.position.y = -item.y;
    sprite.anchor.set(0.5);

    this.exportConfig = {
      layer: "beawre_annotation",
      handle: "B1",
      ownerHandle: "Beawre",
      colorIndex: 7,
      center: { x: item.x, y: item.y, z: 0 },
      radius: item.diameter / 2,
    };

    return sprite;
  };

  delete(store, item) {
    store.annotations = [...store.annotations.filter((x) => x.key != item.key)];
    item.instance.destroy();
  }

  onMouseMove = (store, e) => {
    const mousePosRef = store.viewportRef.toLocal(e.global);
    this.newGraphic.x = mousePosRef.x;
    this.newGraphic.y = mousePosRef.y;
  };

  onMouseDown = (store, e) => {
    this.initPointer = store.viewportRef.toLocal(e.global);

    this.newGraphic = this.draw(store, { x: this.initPointer.x, y: -this.initPointer.y, ...this.shapeConfig });
    store.viewportRef.addChild(this.newGraphic);
    store.isMouseButtonDown = true;
  };

  onMouseUp = (store, e) => {
    store.annotations.push({
      key: uuidv4(),
      name: "circle",
      type: "circle",
      instance: this.newGraphic,
      exportConfig: this.exportConfig,
    });
    store.annotations = [...store.annotations];
  };

  exportToDxf = (store, results, entity) => {
    results = store.exportInsert(results, 0, "CIRCLE");
    if (entity.handle) results = store.exportInsert(results, 5, entity.handle);
    if (entity.ownerHandle) results = store.exportInsert(results, 330, entity.ownerHandle);
    results = store.exportInsert(results, 100, "AcDbEntity");
    if (entity.layer) results = store.exportInsert(results, 8, entity.layer);
    if (entity.colorIndex) results = store.exportInsert(results, 62, entity.colorIndex);
    results = store.exportInsert(results, 100, "AcDbCircle");
    if (entity.center) {
      results = store.exportInsert(results, 10, entity.center.x);
      results = store.exportInsert(results, 20, entity.center.y);
      results = store.exportInsert(results, 30, entity.center.z);
    }
    if (entity.radius) results = store.exportInsert(results, 40, entity.radius);
    return results;
  };
}

export default new DxfEditorDrawCircleStore();
