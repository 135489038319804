import { makeAutoObservable, toJS } from "mobx";
import GraphQlService from "../GraphQlService";

class SearchComponentStore {
  isLoading = false;
  page = "";
  savedSearches = [];
  showSavedSearchesFromPages = [];

  searchValue = "";

  showAdvancedSearch = false;
  queryBuilderJson = undefined;
  queryBuilderString = undefined;
  fields = undefined;

  assetDefinitionSymbol = "";

  graphQlService = new GraphQlService();

  results = [];

  constructor() {
    makeAutoObservable(this);
  }

  loadDefault = () => {};

  searchByJql = value => {
    this.loading = true;
    const jql = JSON.stringify(value).replaceAll('"', '\\"');
    this.graphQlService
      .get(
        `{ containers(where: [{ path: "Type" comparison: EQUAL value: "${this.assetDefinitionSymbol}" }], jqlAssetType: "${this.assetDefinitionSymbol}", jql: "${jql}") {id,rootId,payload,isDeleted,version,type,name,rootId,createdDateTime,riskStatus{icon,name,color,statusId}} }`
      )
      .then(r => {
        this.results = r.data.containers;
        this.isLoading = false;
      });
  };

  searchByValue = value => {};

  loadFields = () => {
    this.isLoading = true;
    return this.graphQlService
      .get(
        `{assetsDefinitionBySymbol(symbol: "${this.assetDefinitionSymbol}"){id,fields{symbol,value,type,dbTableLocation,label}}}`
      )
      .then(r => {
        this.fields = r.data.assetsDefinitionBySymbol ? r.data.assetsDefinitionBySymbol.fields : [];
        this.isLoading = false;
      });
  };
}

export default new SearchComponentStore();
