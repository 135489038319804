import React, { useEffect, useState } from "react";
import { Breadcrumb, Spin } from "antd";
import { NavLink, useRouteMatch } from "react-router-dom";

import { observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import useCurrentRoute from "../hooks/useCurrentRoute";
import CollectSubmissionStore from "../../../Collect/Submissions/FormSubmissionStore";
import { getDynamicTitles } from "../constant";
import "../../../assets/scss/main.scss";

const Breadcrumbs = ({ currentNameTitle, path, pageTitle, t }) => {
  const [loadingLastItem, setLoadingLastItem] = useState(true);

  const dynamicTitles = getDynamicTitles();

  const currentRoute = useCurrentRoute(path);
  const match = useRouteMatch();

  const [initialBreadcrumbs, setInitialBreadcrumbs] = useState([]);
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const getValueFromPath = (obj, path) => {
    const newPath = typeof path === "function" ? path() : path;
    const key = newPath?.split(".").pop();

    return obj[key];
  };

  useEffect(() => {
    const initialBreadcrumbs = [{ title: currentNameTitle || pageTitle }];
    setInitialBreadcrumbs(initialBreadcrumbs);
  }, [match, currentNameTitle, pageTitle]);

  useEffect(() => {
    setBreadcrumbItems(
      (typeof currentRoute?.handle?.breadcrumb === "function"
        ? currentRoute?.handle?.breadcrumb(location)
        : currentRoute?.handle?.breadcrumb
      )?.map(crumb => {
        const result = getValueFromPath(dynamicTitles, crumb.title);
        if (result) {
          crumb = { title: result };
        }
        return crumb;
      }) || initialBreadcrumbs
    );
  }, [initialBreadcrumbs, currentRoute, CollectSubmissionStore.isLoading]);
  const isTranslationReady = translation => {
    return (
      translation && !/\{\{.*?\}\}/.test(translation) && (!translation.includes(".") || !translation.startsWith("{{"))
    );
  };

  useEffect(() => {
    const lastItem = breadcrumbItems[breadcrumbItems.length - 1];
    const lastItemTranslation = t(lastItem?.title);

    setLoadingLastItem(!isTranslationReady(lastItemTranslation));
    const timeout = setTimeout(() => {
      setLoadingLastItem(false);
    }, 300);

    return () => clearTimeout(timeout);
  }, [breadcrumbItems, t]);

  return (
    <Breadcrumb
      separator=">"
      className="breadcrumbs"
      items={breadcrumbItems.map((item, index, array) => {
        const isLastItem = index === array.length - 1;
        const translatedText = t(item?.title);

        if (isLastItem) {
          const isReady = isTranslationReady(translatedText);
          return {
            title: isReady && !loadingLastItem ? translatedText : <Spin size="small" />
          };
        }
        item = {
          title: item?.link ? (
            <NavLink exact to={`${item?.link}`}>
              <span style={{ color: index < array.length - 1 ? "#8c8c8c" : "" }}>{translatedText}</span>
            </NavLink>
          ) : (
            <span style={{ color: index < array.length - 1 ? "#8c8c8c" : "" }}>{translatedText}</span>
          )
        };
        return item;
      })}
    ></Breadcrumb>
  );
};

export default withTranslation()(observer(Breadcrumbs));
