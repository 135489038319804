import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { Col, Row, Spin } from "antd";
import * as _ from "lodash";

import SearchComponentByJql from "./SearchComponentByJql";
import SearchComponentByText from "./SearchComponentByText";

import { withTranslation } from "react-i18next";
import SearchComponentStore from "./SearchComponentStore";

const SearchComponent = props => {
  const [jqlFields, setJqlFields] = useState(undefined);

  useEffect(() => {
    componentDidMountAsync();
  }, []);

  const componentDidMountAsync = async () => {
    SearchComponentStore.page = props.page ? props.page : window.location.pathname;

    if (props.assetDefinitions) SearchComponentStore.assetDefinitionSymbol = props.assetDefinitions;

    // if (!props.fields) await SearchComponentStore.loadFields();
    if (props.urlQuery) SearchComponentStore.queryBuilderJson = props.urlQuery;
    if (!props.jqlFields && props.fields) setJqlFields(props.fields);

    if (props.showSavedSearchesFromPages)
      SearchComponentStore.showSavedSearchesFromPages = props.showSavedSearchesFromPages;
    else SearchComponentStore.showSavedSearchesFromPages = [props.page];
    SearchComponentStore.showSavedSearchesFromPages = SearchComponentStore.showSavedSearchesFromPages.map(i =>
      i.replace(":projectName?/", "")
    );

    if (SearchComponentStore.queryBuilderJson) {
      jqlQueryUpdated(SearchComponentStore.queryBuilderJson);
    }
  };

  const searchByValue = value => {
    SearchComponentStore.isLoading = true;
    if (_.isEmpty(value)) {
      // props.history.replace(SearchComponentStore.page);
      loadDefault();
    } else {
      if (props.searchByValue) props.searchByValue(value);
      else SearchComponentStore.searchByValue(value);
    }
    SearchComponentStore.isLoading = false;
  };

  const searchByJql = value => {
    if (props.searchByJql) props.searchByJql(value);
    else SearchComponentStore.searchByJql(value);
  };

  const loadDefault = () => {
    if (props.loadDefault) props.loadDefault();
    else SearchComponentStore.loadDefault();
  };

  const jqlQueryUpdated = v => {
    if (props.jqlQueryUpdated) props.jqlQueryUpdated(v);
  };

  return (
    <Row id="searchComponent">
      <Col
        span={24}
        offset={0}
        style={{
          border: "1px solid transparent",
          borderRadius: 4,
          padding: 10,
          marginTop: 16
        }}
      >
        <Spin tip="Loading..." spinning={SearchComponentStore.isLoading}>
          {props.variant === "basic" && <SearchComponentByText searchByValue={searchByValue} />}
          {/*           {props.variant === 'advanced' && jqlFields && (
            <SearchComponentByJql
              fields={jqlFields}
              jqlQueryUpdated={jqlQueryUpdated}
              toggleShowSearchDrawer={props.toggleShowSearchDrawer}
              searchByJql={searchByJql}
              loadDefault={loadDefault}
            />
          )} */}
        </Spin>
      </Col>
    </Row>
  );
};

export default withTranslation()(observer(SearchComponent));
