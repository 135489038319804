import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import { Col, Collapse, InputNumber, Row, Spin } from "antd";

import { View, Feature, Map } from "ol";
import Point from "ol/geom/Point";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import * as olProj from "ol/proj";
import Icon from "ol/style/Icon";
import Polyline from "ol/format/Polyline.js";

import "./FormOpenLayersMapStyles.css";
import { LineString } from "ol/geom";

import Stroke from "ol/style/Stroke";
import CollectSubmissionStore from "../../Submissions/FormSubmissionStore";
import FormBuilderStore from "../../MyForms/FormBuilderStore";
import FormSubmissionCommonStore from "../../Submissions/view/FormSubmissionCommonStore";

// const pointsExample = [
//   {
//     "trucK_CODE": "167",
//     "latitude": 14.5845184326172,
//     "longitude": -90.5381469726563,
//     "datE_TIME": "2024-03-19T08:28:14"
//   },
//   {
//     "trucK_CODE": "167",
//     "latitude": 14.5843658447266,
//     "longitude": -90.5381927490234,
//     "datE_TIME": "2024-03-19T08:38:12"
//   },
//   {
//     "trucK_CODE": "167",
//     "latitude": 14.5843658447266,
//     "longitude": -90.5381927490234,
//     "datE_TIME": "2024-03-19T08:43:12"
//   },
//   {
//     "trucK_CODE": "167",
//     "latitude": 14.5843658447266,
//     "longitude": -90.5381927490234,
//     "datE_TIME": "2024-03-19T08:48:12"
//   },
//   {
//     "trucK_CODE": "167",
//     "latitude": 14.5843658447266,
//     "longitude": -90.5381927490234,
//     "datE_TIME": "2024-03-19T08:53:12"
//   },
//   {
//     "trucK_CODE": "167",
//     "latitude": 14.5843811035156,
//     "longitude": -90.5381927490234,
//     "datE_TIME": "2024-03-19T08:58:12"
//   },
//   {
//     "trucK_CODE": "167",
//     "latitude": 14.5843811035156,
//     "longitude": -90.5381927490234,
//     "datE_TIME": "2024-03-19T09:03:12"
//   },
//   {
//     "trucK_CODE": "167",
//     "latitude": 14.5843963623047,
//     "longitude": -90.5381927490234,
//     "datE_TIME": "2024-03-19T09:08:12"
//   },
//   {
//     "trucK_CODE": "167",
//     "latitude": 14.5843963623047,
//     "longitude": -90.5381927490234,
//     "datE_TIME": "2024-03-19T09:13:12"
//   },
//   {
//     "trucK_CODE": "167",
//     "latitude": 14.5843963623047,
//     "longitude": -90.5381927490234,
//     "datE_TIME": "2024-03-19T09:18:13"
//   },
//   {
//     "trucK_CODE": "167",
//     "latitude": 14.5843963623047,
//     "longitude": -90.5382080078125,
//     "datE_TIME": "2024-03-19T09:23:13"
//   },
//   {
//     "trucK_CODE": "167",
//     "latitude": 14.5843811035156,
//     "longitude": -90.5382080078125,
//     "datE_TIME": "2024-03-19T09:28:13"
//   },
//   {
//     "trucK_CODE": "167",
//     "latitude": 14.5847320556641,
//     "longitude": -90.5380554199219,
//     "datE_TIME": "2024-03-19T09:36:40"
//   }
// ];

const FormOpenLayersMap = props => {
  const [isEditable, setIsEditable] = useState(undefined);

  const [mapId, setMapId] = useState(undefined);
  const [map, setMap] = useState(undefined);
  const [zoom, setZoom] = useState(8);

  useEffect(() => {
    if (!props.divName && !props.id) return;

    setMapId(getMapId());
  }, []);

  useEffect(() => {
    if (!mapId) return;
    createMap(mapId);
  }, [mapId]);

  useEffect(() => {
    if (!FormSubmissionCommonStore.mapUpdatePoints || !props.updateValue) return;
    props.updateValue(props.id, FormSubmissionCommonStore.mapUpdatePoints);

    setMap(undefined);
    setMapId(undefined);
    setTimeout(() => setMapId(getMapId()), 500);
  }, [FormSubmissionCommonStore.mapUpdatePoints]);

  const getMapId = () => {
    var newMapId;
    if (props.id) {
      newMapId = `mapExists${props.id.replaceAll("-", "")}`;
      setIsEditable(true);
    } else if (props.divName) newMapId = props.divName;
    return newMapId;
  };

  const createMap = id => {
    var points = props.value;

    var currentPosition = {
      longitude: props.coordinates ? props.coordinates[0] : points ? points[0].longitude : 0,
      latitude: props.coordinates ? props.coordinates[1] : points ? points[0].latitude : 0
    };

    var map = new Map({
      target: id,
      layers: [
        new TileLayer({
          source: new OSM()
        })
      ],

      view: new View({
        center: olProj.fromLonLat([currentPosition.longitude, currentPosition.latitude]),
        zoom: zoom
      })
    });
    setMap(map);
  };

  return (
    <Collapse defaultActiveKey={["1"]} size="small" ghost>
      <Collapse.Panel key="1" header={props.label}>
        <Spin spinning={!map} />
        {mapId && !mapId.includes("mapCreate") && (
          <div className="worldMap">
            <div id={mapId} style={{ height: props.height ? props.height : 200, width: "100%" }}></div>
          </div>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export const CollectFormOpenLayersMapSettings = observer(props => {
  const inputStyle = { marginLeft: 5, width: "90%" };

  const update = (colIndex, type, value) => {
    if (!FormBuilderStore.modifyInputColumn.coordinates) FormBuilderStore.modifyInputColumn.coordinates = [0, 0];
    FormBuilderStore.modifyInputColumn.coordinates[type] = value;
  };

  return (
    <div>
      <Row gutter={4} style={{ marginTop: 5 }}>
        <Col span={6} style={{ textAlign: "right" }}>
          Height:
        </Col>
        <Col span={18}>
          <InputNumber
            style={inputStyle}
            size="small"
            min={100}
            value={FormBuilderStore.modifyInputColumn.height}
            onChange={e => props.setFieldValue("height", e)}
          />
        </Col>
      </Row>
      <Row gutter={4} style={{ marginTop: 5 }}>
        <Col span={6} style={{ textAlign: "right" }}>
          {props.t("collect.defaultCoordinates")}
        </Col>
        <Col span={8}>
          <InputNumber
            style={inputStyle}
            size="small"
            value={
              FormBuilderStore.modifyInputColumn.coordinates ? FormBuilderStore.modifyInputColumn.coordinates[0] : null
            }
            onChange={e => update(props.colIndex, 0, e)}
            placeholder="Longitude"
          />
        </Col>
        <Col span={9}>
          <InputNumber
            style={inputStyle}
            size="small"
            value={
              FormBuilderStore.modifyInputColumn.coordinates ? FormBuilderStore.modifyInputColumn.coordinates[1] : null
            }
            onChange={e => update(props.colIndex, 1, e)}
            placeholder="Latitude"
          />
        </Col>
      </Row>
      <Row gutter={4} style={{ marginTop: 5 }}>
        <Col span={6} style={{ textAlign: "right" }}>
          {props.t("collect.defaultZoom")}
        </Col>
        <Col span={18}>
          <InputNumber
            style={inputStyle}
            size="small"
            value={FormBuilderStore.modifyInputColumn.zoom}
            onChange={e => props.setFieldValue("zoom", e)}
          />
        </Col>
      </Row>
    </div>
  );
});

export default observer(FormOpenLayersMap);
