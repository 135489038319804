import React, { Component } from "react";
import { Query, Builder, Utils } from "@react-awesome-query-builder/antd";
import throttle from "lodash/throttle";
import loadedConfigDefault from "./config";
import { observer } from "mobx-react";
import { cloneDeep, keyBy, toPairs, mapValues, isEqual, size, groupBy, keys, forEach, debounce } from "lodash";
import { toJS } from "mobx";
import SearchComponentStore from "../../Common/Search/SearchComponentStore";
import QueryBuilderStore from "./QueryBuilderStore";
import i18next from "i18next";

import en_US from "antd/es/locale/en_US";
import es_ES from "antd/es/locale/es_ES";
import { withTranslation } from "react-i18next";

const { getTree, checkTree, loadTree, uuid, queryString } = Utils;
const emptyInitValue = { id: uuid(), type: "group" };

class QueryBuilderComponent extends Component {
  store = SearchComponentStore;

  state = {
    tree: undefined,
    loading: true,
    loadedConfig: undefined
  };

  setConfig(fields) {
    const loadedConfig = cloneDeep(loadedConfigDefault);
    loadedConfig.fields = cloneDeep(fields);
    loadedConfig.operators.like.label = "Contains";
    loadedConfig.operators.like.valueSources = ["value", "field"];
    loadedConfig.types.text.widgets.field.operators.push("like");
    loadedConfig.types.text.widgets.field.operators.push("not_like");

    if (i18next.language === "en") {
      loadedConfig.settings.locale = {
        short: "en",
        full: "en-US",
        antd: en_US
      };
    } else {
      if (i18next.language === "es") {
        loadedConfig.settings.locale = {
          short: "es",
          full: "es-ES",
          antd: es_ES
        };
      }
    }
    this.setLanguageConfig(loadedConfig);

    loadedConfig.settings.canLeaveEmptyGroup = false;

    loadedConfig.operators["is_current"] = {
      label: "Is current",
      labelForFormat: "IS CURRENT",
      cardinality: 0,
      jsonLogic: "=="
    };

    loadedConfig.operators["has_evidence"] = {
      label: "Has evidence",
      labelForFormat: "HAS EVIDENCE",
      cardinality: 0,
      jsonLogic: "=="
    };

    loadedConfig.types["bpfield"] = {
      defaultOperator: "equal",
      widgets: {
        boolean: {
          operators: ["is_current"],
          widgetProps: {}
        }
      }
    };

    loadedConfig.types["json"] = {
      defaultOperator: "like",
      widgets: {
        json: {
          defaultOperator: "like",
          operators: ["like", "not_like"]
        },
        field: {
          operators: ["like", "not_like"]
        }
      }
    };

    const initTree = checkTree(
      loadTree(!this.props.queryString ? emptyInitValue : this.props.queryString, loadedConfig),
      loadedConfig
    );
    this.setState({ tree: initTree, loadedConfig: loadedConfig, loading: false });
  }

  setLanguageConfig = config => {
    config.settings.addRuleLabel = this.props.t(`QueryBuilder.AddRule`);
    config.settings.addGroupLabel = this.props.t(`QueryBuilder.AddGroup`);
    config.settings.addCaseLabel = this.props.t(`QueryBuilder.AddCondition`);
    config.settings.addSubRuleLabel = this.props.t(`QueryBuilder.AddSubRule`);
    config.settings.addDefaultCaseLabel = this.props.t(`QueryBuilder.AddDefaultCondition`);
    config.settings.notLabel = this.props.t(`QueryBuilder.Not`);
    config.settings.fieldLabel = this.props.t(`QueryBuilder.Field`);
    config.settings.fieldPlaceholder = this.props.t(`QueryBuilder.FieldPlaceholder`);
    config.settings.fieldSourcePopupTitle = this.props.t(`QueryBuilder.FieldSourcePopupTitle`);

    config.settings.removeRuleConfirmOptions.title = this.props.t(`QueryBuilder.ConfirmRuleDelete`);
    config.settings.removeRuleConfirmOptions.okText = this.props.t(`QueryBuilder.ConfirmRuleDeleteOkText`);
    config.settings.removeRuleConfirmOptions.cancelText = this.props.t(`documents.cancel`);

    config.conjunctions.AND.label = this.props.t(`QueryBuilder.And`);
    config.conjunctions.OR.label = this.props.t(`QueryBuilder.Or`);

    config.widgets.text.valuePlaceholder = this.props.t(`QueryBuilder.EnterString`);

    _.keys(config.operators).forEach(operatorKey => {
      if (i18next.exists(`QueryBuilder.Operator.` + operatorKey))
        config.operators[operatorKey].label = this.props.t(`QueryBuilder.Operator.` + operatorKey);
    });
  };

  componentDidMount() {
    QueryBuilderStore.urlUpdateDebounce = debounce(() => this.updateUrl(), 5000);


    if (this.props.rawFields) {
      var fields = this.processFields(cloneDeep(this.props.rawFields));
      console.log('query builder fields', toJS(fields))
      this.setConfig(fields);
    }
  }

  render() {
    return (
      <div>
        {!this.state.loading && (
          <Query
            {...this.state.loadedConfig}
            value={this.state.tree}
            onChange={this.onChange}
            renderBuilder={this.renderBuilder}
          />
        )}
      </div>
    );
  }

  processFields = fieldsArray => {
    var fields = {};

    let fieldsArrayBySource = groupBy(
      fieldsArray.filter(x => x.source),
      x => x.source.symbol || ""
    );
    forEach(keys(fieldsArrayBySource), sourceKey => {
      var source = fieldsArrayBySource[sourceKey][0].source;

      fields[sourceKey] = {
        type: "!struct",
        label: source?.value,
        subfields: {}
      };

      forEach(fieldsArrayBySource[sourceKey], field => {
        // if (fields[sourceKey] && fields[sourceKey].subfields && field.dbTableLocation && field.dbTableLocation != null) {
        //   var processedField = this.processField(field);
        //   fields[sourceKey].subfields[processedField.dbTableLocation] = processedField;
        // }

        if (
          fields[sourceKey] &&
          fields[sourceKey].subfields &&
          (field.function || (field.dbTableLocation && field.dbTableLocation != null))
        ) {
          var processedField = this.processField(field);
          fields[sourceKey].subfields[processedField.dbTableLocation] = processedField;
        }
      });
    });
    fieldsArray.filter(x => !x.source).forEach(field => (fields[field.dbTableLocation] = this.processField(field)));

    return fields;
  };

  processField = field => {
    var result = {};

    if (field.type && field.dbTableLocation) {
      if (field.source && !field.dbTableLocation.includes(field.source.symbol)) field.dbTableLocation = `${field.source.symbol}:${field.dbTableLocation}`;

      result = {
        label: field.label ? field.label : field.symbol,
        originalType: field.type,
        type: this.fieldTypeToJqlType(field.type),
        dbTableLocation: field.dbTableLocation
      };
      if (field.value) {
        var valuesType = Object.prototype.toString.call(JSON.parse(field.value || "{}"));
        if (valuesType === "[object Array]") {
          result["type"] = "select";
          result["fieldSettings"] = {};
          result["fieldSettings"]["listValues"] = JSON.parse(field.value).map(x => ({
            value: x.replace(" ", "").toLowerCase(),
            title: x
          }));
        }
        result.valueSources = ["value", "field"];
      }
      if (field.values) {
        result["type"] = "select";
        result["fieldSettings"] = {};
        result["fieldSettings"]["listValues"] = field.values;
        result.valueSources = ["values", "field"];
      }
    }
    if (field.function) {
      field.dbTableLocation = field.symbol;
      if (field.source && !field.dbTableLocation.includes(field.source.symbol)) field.dbTableLocation = `${field.source.symbol}:${field.dbTableLocation}`;

      result = {
        label: field.label ? field.label : field.symbol,
        type: this.fieldTypeToJqlType(field.function.returnValueType),
        dbTableLocation: field.dbTableLocation
      };
    }
    if (result) {
      result.operators = this.jqlTypeOperators(result.type, result.originalType);
      if (result.operators && result.operators.length > 0) result.defaultOperator = result.operators[0];
    }
    return result;
  };

  fieldTypeToJqlType = fieldType => {
    var type = "text";

    if (!fieldType || fieldType.toLowerCase().includes("string")) type = "text";
    else if (fieldType.toLowerCase().includes("jobject")) type = "json";
    // only contains
    else if (fieldType.toLowerCase().includes("datetime")) type = "date";
    else if (fieldType.toLowerCase().includes("jarray") || fieldType.toLowerCase().includes("select")) type = "select";
    else if (fieldType.toLowerCase().includes("bool") || fieldType.toLowerCase().includes("guid")) type = "boolean";
    else if (
      fieldType.toLowerCase().includes("int") ||
      fieldType.toLowerCase().includes("decimal") ||
      fieldType.toLowerCase().includes("double")
    )
      type = "number";
    else if (fieldType.toLowerCase().includes("bpfield")) type = "bpfield";

    return type;
  };

  jqlTypeOperators = (type, originalType = null) => {
    if (type === "text") return ["equal", "not_equal", "like", "not_like", "proximity", "is_empty", "is_not_empty"];
    else if (type === "json") return ["like", "not_like"];
    else if (type === "date" || type === "number")
      return [
        "equal",
        "not_equal",
        "greater_or_equal",
        "greater",
        "less",
        "less_or_equal",
        "between",
        "is_empty",
        "is_not_empty"
      ];
    else if (type === "select") return ["select_equals", "select_not_equals"];
    else if (type === "boolean" && originalType !== "guid") return ["equal", "not_equal"];
    else if (type === "boolean" && originalType === "guid") return ["is_null", "is_not_null"];
    else if (type === "bpfield") return ["is_current", "has_evidence"];
  };

  renderBuilder = props => (
    <div className="query-builder-container">
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );

  onChange = (immutableTree, config) => {
    this.immutableTree = immutableTree;
    this.updateResult();
    const jsonTree = getTree(immutableTree); //can be saved to backend
    this.props.updateQueryString(jsonTree);
  };

  updateResult = throttle(() => {
    this.setState({ tree: this.immutableTree, config: this.config });
    // if (this.props.page && this.props.match) {
    //   QueryBuilderStore.urlUpdate = {
    //     pathname: `${this.store.page}`,
    //     search: "?query=" + encodeURIComponent(JSON.stringify(getTree(this.immutableTree))),
    //   };
    //
    //
    //   QueryBuilderStore.urlUpdateDebounce();
    // }
  }, 200);

  updateUrl = () => {
    //
    //
    // this.props.history.push(QueryBuilderStore.urlUpdate, { shallow: true });
  };
}

export default withTranslation()(observer(QueryBuilderComponent));
