import React, { useEffect, useState } from "react";

import { Col, Row, Divider, Modal, Spin, Button, Card } from "antd";
import { observer } from "mobx-react";

import { forEach } from "lodash";

import { withTranslation } from "react-i18next";
import FormBuilderRow from "./rows/FormBuilderRow";
import { toJS } from "mobx";
import FormBuilderStore from "../FormBuilderStore";

const FormBuilderFormZone = props => {
  const renderStructure = structure => {
    let rows = [];
    forEach(structure.rows, (row, index) => {
      rows.push(<FormBuilderRow parentId={props.id} key={index} row={row} structure={structure} rowIndex={index} />);
    });
    return rows;
  };

  return (
    <Card>
      <h4>{props.t("collect.structure")}</h4>
      {props.structure && renderStructure(props.structure)}
      <div style={{ textAlign: "center", marginTop: 10 }}>
        <Button size="small" onClick={() => FormBuilderStore.addNewRow(props.id)}>
          <i className="fas fa-plus" style={{ marginRight: 5 }}></i> {props.t("collect.addNewRow")}
        </Button>
      </div>
    </Card>
  );
};

export default withTranslation()(observer(FormBuilderFormZone));
