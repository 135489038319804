import { useMemo } from "react";
import { matchPath } from "react-router-dom";
import routes from "../../../routes";
import componentsRegistry from "../../../componentsRegistry";
import UserStore from "../../../Common/Users/UserStore";

const useDynamicSections = (path, context) => {
  return useMemo(() => {
    const currentRoute = routes.find(route => matchPath(path, route.path));

    if (!currentRoute || !currentRoute.handle) return [];

    const { dynamicComponents } = currentRoute.handle;
    if (!dynamicComponents) {
      return;
    }
    return dynamicComponents
      .filter(config => {
        const isContextValid = !context || config.context === context;

        const hasPermission = !config.requiredPermission || UserStore.hasAccess(config.requiredPermission);

        return isContextValid && hasPermission;
      })
      .map(config => componentsRegistry[config.component]);
  }, [path, context]);
};

export default useDynamicSections;
