import { makeAutoObservable } from "mobx";
import QueryBuilderComponent from "./QueryBuilderComponent";

import { Utils } from "@react-awesome-query-builder/antd";
import queryBuilderConfig from "./config";

export class QueryBuilderStore {
    urlUpdateDebounce = undefined;
    urlUpdate = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    getQueryString = (fields, jqlString) => {
        const queryBuilder = new QueryBuilderComponent();
        const config = { ...queryBuilderConfig, fields: queryBuilder.processFields(fields) };
        const tree = Utils.checkTree(Utils.loadTree(jqlString, config), config);
        return JSON.stringify(Utils.queryString(tree, config, true), undefined, 2);
    }

}
export default new QueryBuilderStore();
