import React from "react";
import { Flex } from "antd";
import "../../../assets/scss/main.scss";

const WrapperSubHeader = ({ children }) => {
  return (
    <Flex className="wrapper-sub-header" justify="space-between" align="center">
      {children}
    </Flex>
  );
};

export default WrapperSubHeader;
