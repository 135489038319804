import { message } from "antd";
import i18next from "i18next";
import { makeAutoObservable } from "mobx";
import GraphQlService from "../../../Common/GraphQlService";

class DocumentCommentStore {
  graphQlService = new GraphQlService();

  isLoading = false;

  containerId;
  allComments = [];
  comments = [];

  replyTo = { id: undefined, content: undefined };
  editMode = { id: undefined, content: undefined };

  constructor() {
    makeAutoObservable(this);
  }

  defaultLoad = () => {
    // this.graphQlService.get(`{ comments(objectId: "${this.containerId}") { id content isResolved parentId createdDateTime createdBy { id username } payload }  }`).then((list) => {
    //   this.allComments = list.data.comments;
    //   this.comments = list.data.comments;
    //   this.isLoading = false;
    // });
  };

  filterList = (e) => (this.comments = this.allComments.filter((x) => x.content.includes(e)));

  resolve = (ids) => {
    this.isLoading = true;
    this.graphQlService.post(`mutation mutate($data: ResolveCommentCommand){ commentResolve(data: $data) }`, { data: { commentIds: ids } }).then((r) => {
      this.isLoading = false;
      if (r.errors) this.graphQlService.displayErrors(r.errors);
      else {
        message.success(i18next.t("comment.commentResolved"));
        this.defaultLoad();
      }
    });
  };

  update = ({ id, content, file, owner, deadline }) => {
    this.isLoading = true;
    this.graphQlService.post(`mutation mutate($data: UpdateCommentCommand){ commentUpdate(data: $data) }`, { data: { id: id, content: content, owner: owner, deadline: deadline, file } }).then((r) => {
      this.isLoading = false;
      if (r.errors) this.graphQlService.displayErrors(r.errors);
      else {
        message.success(i18next.t("comment.commentModified"));
        this.defaultLoad();
      }
    });
  };

  delete = (id) => {
    this.isLoading = true;
    this.graphQlService.post(`mutation mutate($data: Guid){ commentDelete(id: $data) }`, { data: id }).then((r) => {
      this.isLoading = false;
      if (r.errors) this.graphQlService.displayErrors(r.errors);
      else {
        message.success(i18next.t("comment.commentDeleted"));
        this.defaultLoad();
      }
    });
  };

  reply = (parentId, content, owner, deadline) => {
    this.isLoading = true;

    let data = { mainParentType: "Container", mainParentId: this.containerId, content: content, payload: JSON.stringify({ Deadline: deadline, Owner: owner }) };

    if (parentId) {
      data.targetObjectType = "Comments";
      data.targetObjectId = parentId;
    }
    this.graphQlService
      .post(`mutation mutate($data: CreateCommentCommand){ commentCreate(data: $data) }`, {
        data: data,
      })
      .then((r) => {
        this.isLoading = false;
        if (r.errors) this.graphQlService.displayErrors(r.errors);
        else this.defaultLoad();
      });
     
  };
}
export default new DocumentCommentStore();