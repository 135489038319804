import { gql } from "@apollo/client";
import { notification, message } from "antd";
import i18next from "i18next";
import { cloneDeep, debounce, uniq } from "lodash";
import { makeAutoObservable, toJS } from "mobx";
import BackendService from "../../Common/BackendService";
import GraphQlService from "../../Common/GraphQlService";

class BusinessProcessManagementStore {
  graphQlService = new GraphQlService();
  containersApi = new BackendService(`containers`);
  organizationsApi = new BackendService("organizations");

  isLoading = true;
  history;

  containers = [];
  project;

  fields = undefined;
  selectedJoinFields = [];
  selectedEndDefinition = undefined;

  assetMatchingVisible = false;
  assetMatchingConfig;
  assetMatchingAssetType;

  bpmnEditorStore;

  assetMatchingRunning = undefined;
  isAssetMatchingInProgress = false;

  assetDefinitions;

  modificationHistoryVisible = undefined;

  businessProcesses = undefined;
  successors = undefined;
  predecessors = undefined;

  constructor() {
    makeAutoObservable(this);
    if (localStorage.getItem(`jwtToken`)) this.loadAssetDefinitions();
  }

  loadList = () => {
    this.isLoading = true;
    this.graphQlService
      .client()
      .query({
        query: this.graphQlService.stringToGql(
          `{ businessProcesses(where: [{ path: "Type" comparison: EQUAL value: "WorldDefinitionBusinessProcess" }]) { id rootId name type payload modifiedDateTime modifiedBy{ id username } createdDateTime createdBy { id username }  } }`
        )
      })
      .then(r => {
        this.containers = uniq(r.data.businessProcesses).map(x => ({ ...x, payload: JSON.parse(x.payload || "{}") }));
        this.isLoading = false;
      });
  };

  loadAllBusinessProcesses = () => {
    this.graphQlService
      .get(
        `{ businessProcesses(where: [{ path: "Type" comparison: EQUAL value: "WorldDefinitionBusinessProcess" }]) { id rootId name type payload modifiedDateTime modifiedBy{ id username } createdDateTime createdBy { id username }  } }`
      )
      .then(r => {
        this.businessProcesses = uniq(r.data.businessProcesses).map(x => ({
          ...x,
          payload: JSON.parse(x.payload || "{}")
        }));
      });
  };

  loadCorporateRepository = () => {
    this.isLoading = true;

    this.graphQlService
      .get(`{ corporateRepositoryList { id rootId payload name type modifiedDateTime modifiedBy{ id username } } }`)
      .then(r => {
        this.containers = uniq(r.data.corporateRepositoryList).map(x => ({
          ...x,
          payload: JSON.parse(x.payload || "{}")
        }));
        this.isLoading = false;
      });
  };

  loadCorporateRepositoryById = id => {
    this.isLoading = true;
    this.graphQlService
      .get(`{ corporateRepositorySingle(id: "${id}") { id rootId payload name type bpmn bowtie } }`)
      .then(r => {
        this.project = r.data.corporateRepositorySingle;
        this.isLoading = false;
      });
  };

  loadProject(id) {
    this.isLoading = true;

    this.project = undefined;
    this.graphQlService
      .client()
      .query({
        query: this.graphQlService.stringToGql(
          `{ containers(where: [{ path: "RootId", comparison: EQUAL, value: "${id}" }]) { id, rootId, name, bpmn, payload } }`
        )
      })
      .then(r => {
        this.project = cloneDeep(r.data.containers[0]);

        if (this.project && this.project.payload) {
          var payload = JSON.parse(this.project.payload);
          this.selectedJoinFields = JSON.parse(payload.JoinDefinitionFields || "[]");
          if (payload.WorkflowEndCondition) this.selectedEndDefinition = JSON.parse(payload.WorkflowEndCondition);
          if (payload.AssetMatchingConfigAssetType) {
            this.assetMatchingAssetType = payload.AssetMatchingConfigAssetType;
            this.setFields();
          }

          this.predecessors = undefined;
          this.successors = undefined;
          this.assetMatchingConfig = undefined;

          if (payload.AssetMatchingConfig) this.assetMatchingConfig = JSON.parse(payload.AssetMatchingConfig);
          if (payload.Predecessors) this.predecessors = payload.Predecessors;
          if (payload.Successors) this.successors = payload.Successors;
          this.listenForDetectionUpdates();
        }
        setTimeout(() => {
          this.isLoading = false;
          this.toggleAssetMatchingVisibility(false);
        }, 500);
      });
  }

  loadLessonLearn(id) {
    this.isLoading = true;

    this.project = undefined;
    this.graphQlService
      .client()
      .query({
        query: this.graphQlService.stringToGql(
          `{ containers(where: [{ path: "RootId", comparison: EQUAL, value: "${id}" }]) { id, rootId, name, lessonLearn, bpmn, payload } }`
        )
      })
      .then(r => {
        this.project = cloneDeep(r.data.containers[0]);
        this.project.payload = JSON.parse(this.project.payload);
        this.project.lessonLearn = JSON.parse(this.project.lessonLearn || "{}");
        this.isLoading = false;
      });
  }

  listenForDetectionUpdates = () => {
    var watchRef = this.graphQlService.clientWs().watchQuery({
      fetchPolicy: "no-cache",
      query: gql`
        {
          __typename
        }
      `
    });

    watchRef.subscribeToMore({
      document: gql`subscription listener { asyncActionProgressUpdated(id: "${this.project.id}") { actionId steps{ stepId progress } } }`,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return;

        this.assetMatchingRunning = subscriptionData.data.asyncActionProgressUpdated.steps;
        this.isAssetMatchingInProgress = this.assetMatchingRunning[0].progress < 100;
        if (this.assetMatchingRunning[0].progress >= 100) this.assetMatchingRunning = undefined;
        else this.toggleAssetMatchingVisibility(true);
      }
    });
  };

  loadAssetDefinitions = () => {
    this.graphQlService
      .client()
      .query({
        query: this.graphQlService.stringToGql(
          `{ assetsDefinitions { id symbol value isVirtual isEvidence fields { symbol value type dbTableLocation label } } }`
        )
      })
      .then(r => {
        return (this.assetDefinitions = r.data.assetsDefinitions.filter(x => !x.isVirtual && !x.isEvidence));
      });
  };

  setFields = () => {
    if (!this.assetDefinitions) return;

    this.fields = undefined;
    setTimeout(() => {
      var assetDefinition = this.assetDefinitions.find(x => x.symbol === this.assetMatchingAssetType);
      this.fields = cloneDeep(assetDefinition.fields);
      this.fields.forEach(
        field => (field["source"] = { symbol: assetDefinition.symbol, value: assetDefinition.value })
      );
    }, 200);
  };

  toggleAssetMatchingVisibility = value => {
    this.assetMatchingVisible = value;
    if (this.bpmnEditorStore) {
      this.bpmnEditorStore.editorHeight = window.innerHeight * 0.74;
    }
  };

  delete(id) {
    this.isLoading = true;
    this.containersApi.delete(`${id}`).then(() => {
      this.loadList();
    });
  }

  saveAssetMatchingConfig = () => {
    this.isLoading = true;
    return this.organizationsApi
      .put(`business-process/asset-matching`, {
        containerId: this.project.id,
        config: JSON.stringify(this.assetMatchingConfig),
        assetType: this.assetMatchingAssetType,
        joinDefinitionFields: JSON.stringify(this.selectedJoinFields),
        workflowEndCondition: JSON.stringify(this.selectedEndDefinition),
        predecessors: this.predecessors,
        successors: this.successors
      })
      .then(() => {
        // message.success(`Editor saved`);
        this.isLoading = false;
      });
  };

  saveLessonLearn = lessonLearn => {
    this.project.lessonLearn = lessonLearn;
    this.containersApi
      .post(`lessonLearn`, {
        payload: JSON.stringify(lessonLearn),
        containerId: this.project.rootId
      })
      .then(() => {
        message.success("Saved");
      });
  };

  executeAssetMatching = async excecuteSuccessMessage => {
    this.assetMatchingRunning = undefined;
    await this.saveAssetMatchingConfig();
    this.organizationsApi.get(`business-process/asset-matching/execute?id=${this.project.id}`).then(() => {
      message.success(excecuteSuccessMessage);
      this.isLoading = false;
    });
  };

  cancelAssetMatching = cancelSuccessMessage => {
    this.organizationsApi.get(`business-process/asset-matching/cancel?id=${this.project.id}`).then(() => {
      message.success(cancelSuccessMessage);
      this.isLoading = false;
    });
  };

  clone = (id, clonedMessage) => {
    this.isLoading = true;
    this.organizationsApi.post(`business-process/clone`, id).then(() => {
      message.success(clonedMessage);
      this.loadList();
    });
  };

  rename = (id, name, renamedMessage) => {
    this.isLoading = true;
    this.containersApi.put(`rename`, { containerId: id, name: name }).then(() => {
      message.success(renamedMessage);
      this.loadList();
    });
  };

  makePublic = id => {
    this.isLoading = true;
    this.graphQlService
      .post(`mutation mutate($data: BusinessProcessMakePublicCommand){ businessProcessMakePublic(data: $data) }`, {
        data: { containerId: id }
      })
      .then(
        r => {
          if (r.errors) message.error(i18next.t("businessProcessMgmntList.errors." + r.errors[0].extensions.data.CODE));
          this.loadList();
        },
        () => {
          this.isLoading = false;
        }
      );
  };

  makePrivate = id => {
    this.isLoading = true;
    this.graphQlService
      .post(`mutation mutate($data: BusinessProcessMakePrivateCommand){ businessProcessMakePrivate(data: $data) }`, {
        data: { containerId: id }
      })
      .then(
        r => {
          if (r.errors) message.error(i18next.t("businessProcessMgmntList.errors." + r.errors[0].extensions.data.CODE));
          this.loadList();
        },
        () => {
          this.isLoading = false;
        }
      );
  };
}
export default new BusinessProcessManagementStore();
