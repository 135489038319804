import { makeAutoObservable, toJS } from "mobx";
import PdfAnnotatorSquareDrawer from "./shapes/PdfAnnotatorSquareDrawer";
import PdfAnnotatorCircleDraw from "./shapes/PdfAnnotatorCircleDraw";
import { AnnotationFactory } from "annotpdf";
import { clone, cloneDeep } from "lodash";
import classifyPoint from "robust-point-in-polygon";
import PdfAnnotatorPolygonDrawer from "./shapes/PdfAnnotatorPolygonDrawer";
import GraphQlService from "../../../Common/GraphQlService";
import { message } from "antd";

class PdfAnnotatorStore {
  canvas = undefined;
  canvasContext = undefined;

  isLoading = true;
  flag = false;

  annotationCreator = undefined;

  pdfInput = undefined;
  pdfFactory = undefined;
  originalPdfdata = undefined;
  panningState = undefined;

  scrollDetectionEnabled = true;

  scale = 3.0;
  pdfZoomScale = 1;
  scaleDiff = 0;
  movementState = { positionX: 0, positionY: 0 };
  preventClaim = false;

  page = 1;

  editorAvailable = false;
  editorPaused = false;
  editorPageElement = undefined;

  editorSelectedObjects = [];

  pointersEvCache = [];
  pointersPrevDiff = -1;

  annotations = undefined;
  submissionId = undefined;

  claimTextContent;

  lastClaimedObject = undefined;

  selectedAnnotation = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  base64ToUint8Array = base64 => {
    var raw = atob(base64);
    var uint8Array = new Uint8Array(raw.length);
    for (var i = 0; i < raw.length; i++) {
      uint8Array[i] = raw.charCodeAt(i);
    }
    return uint8Array;
  };

  bufferToBase64 = async buffer => {
    const base64url = await new Promise(r => {
      const reader = new FileReader();
      reader.onload = () => r(reader.result);
      reader.readAsDataURL(new Blob([buffer]));
    });
    return base64url.slice(base64url.indexOf(",") + 1);
  };

  setScale = scale => {
    //
    this.scrollDetectionEnabled = false;
    if (scale <= 0) scale = 0.2;
    this.scale = scale;
    this.pdfZoomScale = scale;
    this.editorSelectedObjects = [];
    // this.pdfInput = new AnnotationFactory(this.pdfFactory.write());
  };

  setPage = page => {
    if (page <= 0) page = 1;
    this.page = page;
    this.editorSelectedObjects = [];
    this.pdfInput = new AnnotationFactory(this.pdfFactory.write());
  };

  // = = = = Manage annotations
  deleteAnnotation = async item => {
    //
    this.scrollDetectionEnabled = false;

    this.pdfFactory.deleteAnnotation(item.id).then(async r => {
      const facDat = r[0].factory.write();
      this.pdfInput = new AnnotationFactory(facDat);

      this.annotations = this.annotations.filter(x => x.id !== item.id);
    });
  };

  startAnnotation = type => {
    //
    if (this.annotationCreator) return;
    this.annotationCreator = { type: type, hexColor: "#00ff00" };
    if (type === "polygon") PdfAnnotatorPolygonDrawer.points = [];
    this.editorPaused = false;
  };

  finishAnnotation = async input => {
    //
    if (!this.annotationCreator) return;

    this.scrollDetectionEnabled = false;

    input.page = this.page - 1;
    input.color = this.hexToRgb(this.annotationCreator.hexColor);
    // input.color.r = input.color.r - (input.color.r * 0.9);
    // input.color.g = input.color.g - (input.color.g * 0.9);
    // input.color.b = input.color.b - (input.color.b * 0.9);
    input.fill = this.hexToRgb(this.annotationCreator.hexColor);
    input.opacity = 0.2;
    input.border = {
      border_width: 2,
      cloudy: false
    };
    input["annotationFlags"] = { noRotate: false };
    input.id = this.annotationCreator.id;
    if (!input.id) input.id = `Beawre ${this.annotationCreator.type} ${new Date().getTime()}`;

    let newFactory = await this.finishAnnotationElement(this.annotationCreator.type, input, this.pdfFactory);
    if (!newFactory) return;
    this.annotations.push({ ...input, type: this.annotationCreator.type });

    this.pdfInput = newFactory;

    this.annotationCreator = undefined;
  };

  finishAnnotationElement = async (type, input, pdfFactory) => {
    //
    delete input.type;
    delete input.beforeClaim;

    let newFactory = undefined;
    if (type === "circle") newFactory = pdfFactory.createCircleAnnotation(input);
    else if (type === "square") newFactory = pdfFactory.createSquareAnnotation(input);
    else if (type === "polygon") {
      //
      // input.fill = { r: 0, g: 1, b: 1 };
      // input.color = { r: 0, g: 1, b: 0 };
      // input.opacity = 0.5;
      newFactory = pdfFactory.createPolygonAnnotation(input);
    }

    const facDat = newFactory.factory.write();
    return new AnnotationFactory(facDat);
  };

  // = = = = Utils functions
  hexToRgb = hex => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null;
  };

  getXYCoordinates = e => {
    const rect = this.editorPageElement.getBoundingClientRect();

    const newX = ((e.clientX - rect.left) / rect.width) * this.editorPageElement.offsetWidth;
    const newY = ((e.clientY - rect.top) / rect.height) * this.editorPageElement.offsetHeight;
    return { x: newX, y: newY };
  };

  getViewport = async page => {
    const pageOriginalScale = await page.getViewport({ scale: 1.0 });
    const rect = this.editorPageElement.getBoundingClientRect();
    return await page.getViewport({ scale: rect.width / pageOriginalScale.width });
  };

  detectObjectClick = async (event, page, returnOnly = false) => {
    //
    if (this.annotationCreator) return;

    let coordinates = this.getXYCoordinates(event);

    let annots = await this.pdfFactory.getAnnotations();
    //
    annots = _.orderBy(annots[this.page - 1], x => x.updateDate, "desc").filter(x => x.type);

    const viewport = await this.getViewport(page);
    coordinates = viewport.convertToPdfPoint(coordinates.x, coordinates.y);

    let selectedObject = undefined;
    for (let i = 0; i < annots.length; i++) {
      const annot = annots[i];

      const lowestX = annot.rect[0] < annot.rect[2] ? annot.rect[0] : annot.rect[2];
      const lowestY = annot.rect[1] < annot.rect[3] ? annot.rect[1] : annot.rect[3];

      if (annot.type.toLowerCase().includes(`square`)) {
        const width = Math.abs(annot.rect[0] - annot.rect[2]);
        const height = Math.abs(annot.rect[1] - annot.rect[3]);

        const rectEdges = [
          [lowestX, lowestY],
          [lowestX + width, lowestY],
          [lowestX + width, lowestY + height],
          [lowestX, lowestY + height]
        ];
        const inside = classifyPoint(rectEdges, coordinates);
        if (inside <= 0) {
          selectedObject = { type: "square", annot };
        }
      } else if (annot.type.toLowerCase().includes(`circle`)) {
        const diameter = Math.abs(annot.rect[0] - annot.rect[2]);
        const radius = diameter / 2;

        var distancesquared =
          (coordinates[0] - (lowestX + radius)) * (coordinates[0] - (lowestX + radius)) +
          (coordinates[1] - (lowestY + radius)) * (coordinates[1] - (lowestY + radius));
        if (distancesquared <= radius * radius == true) selectedObject = { type: "circle", annot };
      } else if (annot.type.toLowerCase().includes(`polygon`)) {
        const width = Math.abs(annot.rect[0] - annot.rect[2]);
        const height = Math.abs(annot.rect[1] - annot.rect[3]);

        const rectEdges = [
          [lowestX, lowestY],
          [lowestX + width, lowestY],
          [lowestX + width, lowestY + height],
          [lowestX, lowestY + height]
        ];
        const inside = classifyPoint(rectEdges, coordinates);
        if (inside <= 0) {
          selectedObject = { type: "polygon", annot };
        }
      }
    }
    if (!selectedObject) return;
    if (returnOnly) return selectedObject;

    if (!selectedObject.annot.id.includes("-claimed")) {
      this.editorSelectedObjects.push(selectedObject);
      this.pdfInput = await this.collectClaimSelectedAnnotations();
    } else this.pdfInput = await this.collectUnClaimSelectedAnnotations(selectedObject.annot.id);
  };

  detectObjectClickSelectAnnotation = async (page, selectedObject) => {
    if (!selectedObject) return null;
    if (selectedObject.annot.id.includes("claimed")) return;
    //

    const viewport = await this.getViewport(page);

    if (this.editorSelectedObjects.filter(x => x.annot.id === selectedObject.annot.id).length === 0)
      this.editorSelectedObjects.push(selectedObject);
    else this.editorSelectedObjects = this.editorSelectedObjects.filter(x => x.annot.id !== selectedObject.annot.id);

    this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.editorSelectedObjects.forEach(editorSelectedObject => {
      if (editorSelectedObject.type === "square") {
        PdfAnnotatorSquareDrawer.drawSelect(this.canvasContext, editorSelectedObject.annot, viewport);
      } else if (editorSelectedObject.type === "circle") {
        PdfAnnotatorCircleDraw.drawSelect(this.canvasContext, editorSelectedObject.annot, viewport);
      } else if (editorSelectedObject.type === "polygon") {
        PdfAnnotatorPolygonDrawer.drawSelect(this.canvasContext, editorSelectedObject.annot, viewport);
      }
    });
    return selectedObject;
  };

  collectUnClaimSelectedAnnotations = async id => {
    let pdfFactory = this.pdfFactory;

    const deleteResult = await pdfFactory.deleteAnnotation(id);
    const facDat = deleteResult[0].factory.write();
    pdfFactory = new AnnotationFactory(facDat);

    var annot = this.annotations.find(x => x.id === id);
    annot.color = annot.beforeClaim.color;
    annot.fill = annot.beforeClaim.fill;
    annot.id = annot.id.replace("-claimed", "");
    delete annot.author;
    delete annot.content;
    delete annot.beforeClaim;

    //

    this.lastClaimedObject = "unclaim";

    return await this.finishAnnotationElement(annot.type, cloneDeep(annot), pdfFactory);
  };

  collectClaimSelectedAnnotations = async () => {
    //
    var d = new Date(),
      dformat =
        [d.getDate(), d.getMonth() + 1, d.getFullYear()].join("/") +
        " " +
        [d.getHours(), d.getMinutes(), d.getSeconds()].join(":");

    let pdfFactory = this.pdfFactory;
    for (let i = 0; i < this.editorSelectedObjects.length; i++) {
      var annot = this.annotations.find(x => x.id === this.editorSelectedObjects[i].annot.id);
      if (annot) {
        annot.beforeClaim = {
          color: cloneDeep(annot.color),
          fill: cloneDeep(annot.fill)
        };

        annot.color["r"] = 0;
        annot.color["g"] = 0;
        annot.color["b"] = 0;
        annot.fill["r"] = 0;
        annot.fill["g"] = 0;
        annot.fill["b"] = 0;

        const deleteResult = await pdfFactory.deleteAnnotation(annot.id);
        const facDat = deleteResult[0].factory.write();
        pdfFactory = new AnnotationFactory(facDat);

        annot.id = annot.id + "-claimed";
        annot.author = localStorage.getItem(`username`);
        annot.contents = this.claimTextContent
          ? _.join(
              this.claimTextContent.map(x => x.value),
              "\n"
            )
          : dformat;

        pdfFactory = await this.finishAnnotationElement(annot.type, cloneDeep(annot), pdfFactory);
      }
    }

    if (this.editorSelectedObjects.length > 0)
      this.lastClaimedObject = this.editorSelectedObjects[this.editorSelectedObjects.length - 1].annot.id;

    this.editorSelectedObjects = [];
    return pdfFactory;
  };

  // = = = = Drawing
  onMouseDown = (event, page) => {
    if (!this.annotationCreator) return;
    var coordinates = this.getXYCoordinates(event);

    switch (this.annotationCreator.type) {
      case "square":
        PdfAnnotatorSquareDrawer.onMouseDown(coordinates.x, coordinates.y);
        break;
      case "circle":
        PdfAnnotatorCircleDraw.onMouseDown(coordinates.x, coordinates.y);
        break;
      case "polygon":
        PdfAnnotatorPolygonDrawer.onMouseDown(coordinates.x, coordinates.y, page);
        break;
    }
    this.flag = true;
  };

  onMouseUp = async (event, page) => {
    if (!this.annotationCreator) return;
    this.flag = false;

    const viewport = await this.getViewport(page);

    if (this.annotationCreator.type === "circle") {
      let startPoints = viewport.convertToPdfPoint(
        PdfAnnotatorCircleDraw.centerX - PdfAnnotatorCircleDraw.radius,
        PdfAnnotatorCircleDraw.centerY - PdfAnnotatorCircleDraw.radius
      );
      let endPoints = viewport.convertToPdfPoint(
        PdfAnnotatorCircleDraw.centerX + PdfAnnotatorCircleDraw.radius,
        PdfAnnotatorCircleDraw.centerY + PdfAnnotatorCircleDraw.radius
      );

      await this.finishAnnotation({ rect: [startPoints[0], startPoints[1], endPoints[0], endPoints[1]] });
    } else if (this.annotationCreator.type === "square") {
      let startPoints = viewport.convertToPdfPoint(PdfAnnotatorSquareDrawer.startX, PdfAnnotatorSquareDrawer.startY);
      let endPoints = viewport.convertToPdfPoint(PdfAnnotatorSquareDrawer.endX, PdfAnnotatorSquareDrawer.endY);

      await this.finishAnnotation({ rect: [startPoints[0], startPoints[1], endPoints[0], endPoints[1]] });
    } else if (this.annotationCreator.type === "polygon") {
      this.draw();
    }
  };

  onMouseMove = event => {
    if (!this.annotationCreator) return;
    var coordinates = this.getXYCoordinates(event);

    if (!this.flag) return;

    switch (this.annotationCreator.type) {
      case "square":
        PdfAnnotatorSquareDrawer.onMouseMove(coordinates.x, coordinates.y);
        break;
      case "circle":
        PdfAnnotatorCircleDraw.onMouseMove(coordinates.x, coordinates.y);
        break;
    }
    this.draw();
  };

  draw = () => {
    this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
    switch (this.annotationCreator.type) {
      case "square":
        PdfAnnotatorSquareDrawer.draw(this.canvasContext, this.annotationCreator);
        break;
      case "circle":
        PdfAnnotatorCircleDraw.draw(this.canvasContext, this.annotationCreator);
        break;
      case "polygon":
        PdfAnnotatorPolygonDrawer.draw(this.canvasContext, this.annotationCreator);
        break;
    }
  };
}

export default new PdfAnnotatorStore();
