import { Graphics } from "pixi.js";
import DxfEditorStore from "../DxfEditorStore";
import { v4 as uuidv4 } from "uuid";
import { makeAutoObservable, toJS } from "mobx";
import { observer } from "mobx-react";
import { Col, Input, Row } from "antd";
import { HuePicker } from "react-color";
import { SmoothGraphics } from "@pixi/graphics-smooth";

export const DxfEditorDrawLine = observer(({ store }) => {
  return (
    <div>
      <Row gutter={12}>
        <Col span={12}>
          Line width:
          <Input defaultValue={store.lineConfig.width} onChange={e => (store.lineConfig.width = +e.target.value)} />
        </Col>
        <Col span={12}>
          Color:
          <HuePicker width="100%" color={store.lineConfig.color} onChange={e => (store.lineConfig.color = e.hex)} />
        </Col>
      </Row>
    </div>
  );
});

class DxfEditorDrawLineStore {
  initPointer = undefined;
  newGraphic = undefined;

  lineConfig = {
    width: 2,
    color: "#ffff00"
  };

  exportConfig = {};

  constructor() {
    makeAutoObservable(this);
  }

  drawLine = (store, x1, y1, x2, y2, hexColor, lineWidth = 2) => {
    if (!hexColor) hexColor = "#ffffff";
    hexColor = hexColor.replace("#", "0x");

    const shape = new SmoothGraphics();
    shape.lineStyle(lineWidth, hexColor, 1);
    shape.moveTo(x1, -y1);
    shape.lineTo(x2, -y2);
    store.viewportRef.addChild(shape);

    this.exportConfig = {
      layer: "beawre_annotation",
      handle: "B1",
      ownerHandle: "Beawre",
      colorIndex: 7,
      vertices: [
        { x: x1, y: y1, z: 0 },
        { x: x2, y: y2, z: 0 }
      ],
      width: lineWidth
    };

    return shape;
  };

  delete(store, item) {
    store.annotations = [...store.annotations.filter(x => x.key != item.key)];
    item.instance.destroy();
  }

  onMouseMove = (store, e) => {
    this.newGraphic.clear();

    const mousePosRef = store.viewportRef.toLocal(e.global);
    this.newGraphic = this.drawLine(
      store,
      this.initPointer.x,
      -this.initPointer.y,
      mousePosRef.x,
      -mousePosRef.y,
      this.lineConfig.color,
      this.lineConfig.width
    );
  };

  onMouseDown = (store, e) => {
    this.initPointer = store.viewportRef.toLocal(e.global);

    this.newGraphic = this.drawLine(
      store,
      this.initPointer.x,
      -this.initPointer.y,
      this.initPointer.x,
      -this.initPointer.y,
      this.lineConfig.color,
      this.lineConfig.width
    );
    store.isMouseButtonDown = true;
  };

  onMouseUp = (store, e) => {
    store.annotations.push({
      key: uuidv4(),
      name: "Line",
      type: "line",
      instance: this.newGraphic,
      exportConfig: this.exportConfig
    });
    store.annotations = [...store.annotations];
  };
}

export default new DxfEditorDrawLineStore();
