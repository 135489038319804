import React, { useEffect } from "react";
import { Form, Checkbox } from 'antd-mobile';

const FormCheckboxMobile = (props) => {

  useEffect(() => {
    if (props.storeValue === undefined && props.value !== undefined && props.updateValue) props.updateValue(props.id, props.value)
  }, [props.value])

  return (
    <Form.Item name={props.id}>
      <Checkbox
        checked={props.storeValue !== undefined ? props.storeValue : props.value}
        disabled={props.disabled}
        onChange={(e) => props.updateValue && props.updateValue(props.id, e.target?.checked)}
        key={props.key}
      >
        {props.label}
      </Checkbox>
    </Form.Item>
  )
}

export default FormCheckboxMobile;