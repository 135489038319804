import React, { useState, useEffect } from "react";
import { Button, CascadePicker } from "antd-mobile";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { List } from "antd";

import UserStore from "../../Common/Users/UserStore.js";
import { SwitcherOutlined } from "@ant-design/icons";

const SelectProject = ({ t }) => {
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const projectPicker = localStorage.getItem("projectPicker");
    projectPicker && setValue(JSON.parse(projectPicker));

    setIsLoading(true);
    UserStore.loadProjects();
  }, []);

  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (UserStore.projects) {
      const tenants = UserStore.projects.flatMap(tenantGroup =>
        tenantGroup?.projects.map(tenant => {
          return {
            label: tenant.value,
            value: `tenant_${tenant.id}_project_${tenantGroup.id}`,
            tenantGroupId: tenantGroup.id,
            tenantId: tenant.id,
            projects: tenant.projects
          };
        })
      );

      setOptions(tenants);
      setIsLoading(false);
    }
  }, [UserStore.projects]);

  return (
    <>
      <List.Item
        onClick={() => {
          setVisible(true);
        }}
      >
        <List.Item.Meta title={t("mobileMenu.chosenProject")} avatar={<SwitcherOutlined />} />
      </List.Item>
      <CascadePicker
        // style={{ touchAction: "none" }}
        value={value}
        cancelText={t("mobileMenu.close")}
        confirmText={t("mobileMenu.select")}
        loading={isLoading}
        title={t("mobileMenu.projects")}
        options={options}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        onConfirm={(val, extend) => {
          const selectedValue = val[0];
          const [tenantId, projectId] = selectedValue.split("_");
          const tenantGroup = UserStore.projects.find(group =>
            group.projects.some(project => project.id === projectId)
          );

          if (!tenantGroup) return;

          const project = tenantGroup.projects.find(project => project.id === projectId);

          if (!project) return;
          const tenant = UserStore.projects.find(tenantGroup =>
            tenantGroup.projects.some(project => project?.id === projectId)
          );

          setValue(val);
          localStorage.setItem("projectPicker", JSON.stringify(val));

          UserStore.switchProject(tenant, project).then(async () => {
            localStorage.removeItem(`projectId`);
            localStorage.removeItem(`tenantId`);
            localStorage.removeItem(`projectName`);
            history.push(`/`);
          });
        }}
      />
    </>
  );
};
export default withTranslation()(observer(SelectProject));
