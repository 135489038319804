import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import * as _ from "lodash";
import { CloseOutlined, SaveOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Tag, Modal, Input, Checkbox } from "antd";

import QueryBuilderComponent from "../../components/queryBuilder/QueryBuilderComponent";
import SearchComponentStore from "./SearchComponentStore";
import { toJS } from "mobx";
import { cloneDeep } from "lodash";
import { withTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import i18next from "i18next";

const SearchComponentByJql = ({ t, fields, jqlQueryUpdated, toggleShowSearchDrawer, searchByJql, loadDefault }) => {
  const history = useHistory();
  const match = useRouteMatch();

  const [bookmarkConfig, setBookmarkConfig] = useState(undefined);

  useEffect(() => {
    SearchComponentStore.fields = toJS(fields);
    reloadSavedSearches();
  }, []);

  const reloadSavedSearches = () => {
    SearchComponentStore.savedSearches = [];
    _.forEach(SearchComponentStore.showSavedSearchesFromPages, page => {
      if (!localStorage.getItem(`savedSearch${page}`)) localStorage.setItem(`savedSearch${page}`, JSON.stringify([]));

      let savedSearches = JSON.parse(localStorage.getItem(`savedSearch${page}`));
      if (!savedSearches) savedSearches = [];

      SearchComponentStore.savedSearches = [...SearchComponentStore.savedSearches, ...savedSearches];
    });
  };

  const saveSearch = () => {
    if (!localStorage.getItem(`savedSearch${SearchComponentStore.page}`))
      localStorage.setItem(`savedSearch${SearchComponentStore.page}`, JSON.stringify([]));
    let savedSearches = JSON.parse(localStorage.getItem(`savedSearch${SearchComponentStore.page}`));
    if (bookmarkConfig.isChecked === true)
      _.forEach(savedSearches, save => {
        save.isDefault = false;
      });
    savedSearches.push({
      name: bookmarkConfig.searchName,
      isDefault: bookmarkConfig.isChecked,
      link: window.location.search
    });
    localStorage.setItem(`savedSearch${SearchComponentStore.page}`, JSON.stringify(savedSearches));

    setBookmarkConfig(undefined);
    reloadSavedSearches();
  };

  const deleteSearch = savedSearch => {
    let savedSearches = JSON.parse(localStorage.getItem(`savedSearch${SearchComponentStore.page}`)).filter(
      x => x.name !== savedSearch.name
    );
    localStorage.setItem(`savedSearch${SearchComponentStore.page}`, JSON.stringify(savedSearches));
    reloadSavedSearches();
  };

  const handleResetSearch = () => {
    const fields = SearchComponentStore.fields;

    SearchComponentStore.fields = undefined;
    SearchComponentStore.searchValue = undefined;
    SearchComponentStore.queryBuilderJson = undefined;

    if (toggleShowSearchDrawer) toggleShowSearchDrawer();

    SearchComponentStore.fields = fields;

    loadDefault();
  };

  return (
    <div>
      {SearchComponentStore.fields !== undefined && (
        <QueryBuilderComponent
          rawFields={cloneDeep(SearchComponentStore.fields)}
          queryString={SearchComponentStore.queryBuilderJson}
          updateQueryString={v => {
            SearchComponentStore.queryBuilderJson = v;
            jqlQueryUpdated(v);
          }}
          history={history}
          page={SearchComponentStore.page}
          match={match}
        ></QueryBuilderComponent>
      )}
      <Button size="small" type="link" icon={<SaveOutlined />} onClick={() => setBookmarkConfig({})}>
        {t("search.saveSearch")}
      </Button>
      {SearchComponentStore.savedSearches.map(savedSearch => (
        <Tag
          closable
          onClose={() => deleteSearch(savedSearch)}
          onClick={() => {
            const urlToSet = {
              pathname: `${SearchComponentStore.page}`,
              search: savedSearch.link
            };
            history.push(urlToSet, { shallow: true });
          }}
        >
          {savedSearch.name} {savedSearch.isDefault === true ? "(Default)" : ""}
        </Tag>
      ))}
      <Button.Group
        style={{
          float: "right",
          marginTop: 10,
          marginBottom: 20,
          marginRight: 20
        }}
      >
        <Button
          type="primary"
          icon={<SearchOutlined />}
          onClick={() => {
            searchByJql(SearchComponentStore.queryBuilderJson);

            if (toggleShowSearchDrawer) toggleShowSearchDrawer();
          }}
        >
          {t("search.search")}
        </Button>
        <Button icon={<CloseOutlined />} onClick={handleResetSearch}>
          {t("search.reset")}
        </Button>
      </Button.Group>

      {bookmarkConfig && (
        <Modal
          title={t("search.saveSearch")}
          centered
          open={bookmarkConfig}
          onCancel={() => setBookmarkConfig(undefined)}
          onOk={saveSearch}
        >
          <Input
            placeholder={t("search.searchName")}
            value={bookmarkConfig.searchName}
            onBlur={e => setBookmarkConfig(v => ({ ...v, searchName: e.target.value }))}
          />
          <Checkbox onChange={e => setBookmarkConfig(v => ({ ...v, isChecked: e.target.checked }))}>
            {t("search.defaultSearch")}
          </Checkbox>
        </Modal>
      )}
    </div>
  );
};

export default withTranslation()(observer(SearchComponentByJql));
