import React, { useEffect, useState } from "react";

import { Button, Col, Collapse, Divider, Input, List, Row, Select, Switch } from "antd";
import { NodeExpandOutlined, SyncOutlined } from "@ant-design/icons";

import _, { cloneDeep } from "lodash";
import { withTranslation } from "react-i18next";
import FormBuilderFormZone from "../../MyForms/form/FormBuilderFormZone";
import FormSubmissionViewRenderStruct from "../../Submissions/view/FormSubmissionViewRenderStruct";
import { observer } from "mobx-react-lite";
import { DeleteOutline } from "antd-mobile-icons";
import FormBuilderStore from "../../MyForms/FormBuilderStore";
import { v4 as uuidv4 } from "uuid";
import { toJS } from "mobx";
import FormSubmissionStore from "../../Submissions/FormSubmissionStore";

const CollectFormForEachComponent = props => {
  const [submissionStructure, setSubmissionStructure] = useState(undefined);

  useEffect(() => {
    //

    if (props.isSubmission) {
      var items = props.store.values.find(x => x.id === props.id)?.items;
      //

      var newStruct = { rows: [] };
      items.forEach(i => {
        var newItemStruct = cloneDeep(props.structure);
        newItemStruct.rows.forEach(row => {
          row.cols.forEach(col => {
            if (col.label && col.label.includes(`valueOfRel_`)) {
              col.label = i.containerValues[col.label.split("_")[2]];
            }
            col.id = `valueOfRel_${i.relationId}_${i.relationDefId}_${col.assetDefinitionFieldId.split("_")[1]}`;
          });
        });
        newStruct.rows = [...newStruct.rows, ...newItemStruct.rows];
      });
      setSubmissionStructure(newStruct);
      props.structure.rows = newStruct.rows;

      // = = = Remove loop from values after init lits
      props.store.values = props.store.values.filter(x => x.id !== props.id);
    }
  }, []);

  return (
    <>
      {props.isSidebarElement && (
        <>
          <div style={{ textAlign: "center" }}>
            <SyncOutlined />
          </div>
        </>
      )}

      {props.isBuilder && (
        <>
          <h4
            style={{
              textTransform: "uppercase",
              fontSize: "0.8em",
              color: "#90a8be",
              fontWeight: "bolder",
              textAlign: "left"
            }}
          >
            {props.label}
          </h4>

          <FormBuilderFormZone id={props.id} structure={props.structure} isLoop={true} />
        </>
      )}

      {props.isSubmission && submissionStructure && (
        <>
          <Divider>{props.label}</Divider>

          <FormSubmissionViewRenderStruct {...props.parentProps} structure={submissionStructure} isLoop={true} />
        </>
      )}
    </>
  );
};

export const CollectFormForEachComponentSettings = observer(props => {
  const inputStyle = { marginLeft: 5, width: "90%" };

  return (
    <>
      <Row gutter={4} style={{ marginTop: 5 }}>
        <Col span={6} style={{ textAlign: "right" }}>
          {props.t("collect.loopSource")}
        </Col>
        <Col span={18}>
          <Select
            size="small"
            style={inputStyle}
            options={FormBuilderStore.assetDefinitionRelations.map(x => ({ label: x.value, value: x.id }))}
            value={FormBuilderStore.modifyInputColumn.assetDefinitionFieldId}
            onChange={e => {
              props.setFieldValue("assetDefinitionFieldId", e);
            }}
            showSearch
            optionFilterProp="label"
          ></Select>
        </Col>
      </Row>
    </>
  );
});

export default withTranslation()(CollectFormForEachComponent);
