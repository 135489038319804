import React from "react";
import { AntdWidgets as Widgets, AntdConfig } from "@react-awesome-query-builder/antd";
// import en_US from "antd/lib/locale-provider/en_US";
import en_US from 'antd/es/locale/en_US';

// import AntdConfig from "react-awesome-query-builder/lib/config/antd";
import '@react-awesome-query-builder/antd/css/styles.css';

const { FieldDropdown } = Widgets;

const conjunctions = {
  ...AntdConfig.conjunctions,
};

const operators = {
  ...AntdConfig.operators,
  // examples of  overriding
  between: {
    ...AntdConfig.operators.between,
    valueLabels: ["Value from", "Value to"],
    textSeparators: ["from", "to"],
  },
  less_or_equal: {
    ...AntdConfig.operators.less_or_equal,
    label: "before",
  },
  greater_or_equal: {
    ...AntdConfig.operators.greater_or_equal,
    label: "after",
  },
  like: {
    ...AntdConfig.operators.like,
    label: "contains",
    valueSources: ["value", "field"]
  },
  not_like: {
    ...AntdConfig.operators.not_like,
    label: "not contains",
    valueSources: ["value", "field"]
  },
};

const widgets = {
  ...AntdConfig.widgets,
  // examples of  overriding
  text: {
    ...AntdConfig.widgets.text,
    validateValue: (val, fieldDef) => {
      return val.length < 10;
    },
  },
  date: {
    ...AntdConfig.widgets.date,
    dateFormat: "DD.MM.YYYY",
    valueFormat: "YYYY-MM-DD",
  },
  time: {
    ...AntdConfig.widgets.time,
    timeFormat: "HH:mm",
    valueFormat: "HH:mm:ss",
  },
  datetime: {
    ...AntdConfig.widgets.datetime,
    timeFormat: "HH:mm",
    dateFormat: "DD.MM.YYYY",
    valueFormat: "YYYY-MM-DD HH:mm:ss",
  },
  guid: {
    ...AntdConfig.widgets.boolean,
  },
  json: {
    ...AntdConfig.widgets.textarea,
    type: 'json'
  },
};

const types = {
  ...AntdConfig.types,
  // examples of  overriding
};

const localeSettings = {
  locale: {
    short: "en",
    full: "en-US",
    antd: en_US,
  },
  valueLabel: "Value",
  valuePlaceholder: "Value",
  fieldLabel: "Field",
  operatorLabel: "Operator",
  fieldPlaceholder: "Select field",
  operatorPlaceholder: "Select operator",
  deleteLabel: null,
  addGroupLabel: "Add group",
  addRuleLabel: "Add rule",
  delGroupLabel: null,
  notLabel: "Not",
  valueSourcesPopupTitle: "Select value source",
  removeRuleConfirmOptions: {
    title: "Are you sure delete this rule?",
    okText: "Yes",
    okType: "danger",
  },
  removeGroupConfirmOptions: {
    title: "Are you sure delete this group?",
    okText: "Yes",
    okType: "danger",
  },
};

const settings = {
  ...AntdConfig.settings,
  ...localeSettings,

  valueSourcesInfo: {
    value: {
      label: "Value",
    },
    field: {
      label: "Field",
      widget: "field",
    },
  },
  maxNesting: 3,
  canLeaveEmptyGroup: true, //after deletion
  renderOperator: (props) => <FieldDropdown {...props} />,
};

const InitialConfig = AntdConfig;

let fields = {};

export default {
  ...InitialConfig,
  conjunctions,
  operators,
  widgets,
  types,
  settings,
  fields,
};
