import React from "react";

const BaseLayoutContentMobile = ({ children, height = "86vh" }) => (
  <div
    style={{
      flexGrow: 1,
      border: "1px solid #ececec",
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      backgroundColor: "white",
      paddingTop: 15,
      paddingLeft: 15,
      paddingRight: 15,
      marginTop: 15,
      boxShadow: "0px 0px 3px #efefef"
    }}
  >
    {children}
  </div>
);

export default BaseLayoutContentMobile;
