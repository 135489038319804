import { makeAutoObservable, toJS } from "mobx";
import PdfAnnotatorStore from "../PdfAnnotatorStore";
import _ from "lodash";

class PdfAnnotatorPolygonDrawer {
  points = [];

  width = 0;
  height = 0;

  page = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  onMouseDown = (x, y, page) => {
    this.points.push({ x, y });
    this.page = page;
  };

  onMouseMove = (x, y) => {};

  draw = (context, annotationConfig) => {
    if (this.points.length < 2) return;

    context.beginPath();
    context.moveTo(this.points[0].x, this.points[0].y);

    for (let i = 1; i < this.points.length; i++) context.lineTo(this.points[i].x, this.points[i].y);

    context.closePath();

    const hexColor = PdfAnnotatorStore.hexToRgb(annotationConfig.hexColor);

    context.strokeStyle = `rgba(${hexColor.r}, ${hexColor.g}, ${hexColor.b}, 0.8)`;
    context.stroke();

    context.fillStyle = `rgba(${hexColor.r}, ${hexColor.g}, ${hexColor.b}, 0.3)`;
    context.fill();
  };

  prepareAnnotationData = (annotationConfig, viewport) => {
    for (let i = 0; i < this.points.length; i++) {
      const pdfPoints = viewport.convertToPdfPoint(this.points[i].x, this.points[i].y);
      this.points[i].x = pdfPoints[0];
      this.points[i].y = pdfPoints[1];
    }

    const xs = this.points.map(x => x.x);
    const ys = this.points.map(x => x.y);
    annotationConfig.rect = [_.min(xs), _.min(ys), _.max(xs), _.max(ys)];
    annotationConfig.vertices = [];
    this.points.forEach(p => {
      annotationConfig.vertices.push(p.x);
      annotationConfig.vertices.push(p.y);
    });
    annotationConfig.vertices.push(this.points[0].x);
    annotationConfig.vertices.push(this.points[0].y);
    return annotationConfig;
  };

  drawSelect = (context, annot, viewport) => {
    const rectStart = viewport.convertToViewportPoint(annot.rect[0], annot.rect[1]);
    const rectEnd = viewport.convertToViewportPoint(annot.rect[2], annot.rect[3]);

    const width = Math.abs(rectStart[0] - rectEnd[0]);
    const height = Math.abs(rectStart[1] - rectEnd[1]);

    const lowestX = rectStart[0] < rectEnd[0] ? rectStart[0] : rectEnd[0];
    const lowestY = rectStart[1] < rectEnd[1] ? rectStart[1] : rectEnd[1];

    context.beginPath();
    context.rect(lowestX, lowestY, width, height);
    context.fillStyle = "rgba(11, 11, 11, 0.3)";
    context.fill();
    context.stroke();
  };
}

export default new PdfAnnotatorPolygonDrawer();
