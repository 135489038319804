import React from "react";
import { observer } from "mobx-react";

import { Input } from "antd";
import SearchComponentStore from "./SearchComponentStore";
import { withTranslation } from "react-i18next";

const SearchComponentByText = ({ t, searchByValue }) => {

  return (
    <div>
      <Input.Search
        allowClear
        style={{ width: 350, margin: "0px" }}
        onChange={(value) => (SearchComponentStore.searchValue = value.target.value)}
        placeholder={t("search.searchPlaceholder")}
        defaultValue=""
        value={SearchComponentStore.searchValue}
        enterButton
        onSearch={(value) => searchByValue(value)}
        className="customSearch"
      />
    </div>
  );
}

export default withTranslation()(observer(SearchComponentByText));
