import React from "react";
import { useDrag } from "react-dnd";
import FormBuilderStore from "../../FormBuilderStore";

import { observer } from "mobx-react";

import "./../../../common/form-components/style.css";

const FormBuilderInput = ({ col, rowIndex, colIndex }) => {
  const store = FormBuilderStore;

  const [, drag] = useDrag(() => ({
    type: "input",
    item: { col, rowIndex, colIndex },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        // addInput(item.type, item.label, item.items, dropResult["rowIndex"], dropResult["colIndex"]);
        //
        store.moveInput(
          [col.parentId, item.rowIndex, item.colIndex],
          [dropResult["parentId"], dropResult["rowIndex"], dropResult["colIndex"]]
        );
      }
      store.moveObjectId = undefined;
    },
    collect: monitor => {
      setTimeout(() => {
        if (monitor.isDragging() === true) {
          store.moveObjectId = col.id;
          //
        }
      }, 200);
      return {
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId()
      };
    }
  }));

  return (
    <div ref={drag} style={{ cursor: "move" }}>
      {store.renderObject(col, rowIndex, colIndex)}
      <div>
        <small>
          {col.parentId} / {rowIndex} / {colIndex}
        </small>
      </div>
    </div>
  );
};
export default observer(FormBuilderInput);
