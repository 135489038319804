import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import CollectSubmissionStore, { CollectSubmissionStore as FormGeneratorStoreLocal } from "../FormSubmissionStore";
import { Col, Row } from "antd/lib/grid";
import { withTranslation } from "react-i18next";
import { forEach } from "lodash";
import FormInput from "./../../common/form-components/FormInput";
import FormTextArea from "./../../common/form-components/FormTextArea";
import FormSelect from "./../../common/form-components/FormSelect";
import FormCheckboxGroup from "./../../common/form-components/FormCheckboxGroup";
import FormCheckbox from "./../../common/form-components/FormCheckbox";
import FormRadio from "./../../common/form-components/FormRadio";
import FormFileUpload from "./../../common/form-components/FormFileUpload";
import FormFileUploadMobile from "../../common/form-components/FormFileUploadMobile";
import FormCamera from "./../../common/form-components/FormCamera";
import FormInputMobile from "../../common/form-components/FormInputMobile";
import FormCheckboxMobile from "../../common/form-components/FormCheckboxMobile";
import FormCheckboxGroupMobile from "../../common/form-components/FormCheckboxGroupMobile";
import FormDateTime from "../../common/form-components/FormDateTime";
import FormOpenLayersMap from "../../common/form-components/FormOpenLayersMap";
import FormSwitch from "../../common/form-components/FormSwitch";
import FormStarRate from "../../common/form-components/FormStarRate";
import FormStarRateMobile from "../../common/form-components/FormStarRateMobile";
import FormTitle from "../../common/form-components/FormTitle";
import CollectFormDxfEditor from "../../common/form-components/CollectFormDxfEditor";
import JqlStore from "../../../Common/JqlStore";
import FormNumericInput from "../../common/form-components/FormNumericInput";
import FormSign from "../../common/form-components/FormSign";
import CollectFormTable from "../../common/form-components/CollectFormTable";
import CollectFormCollapse from "../../common/form-components/CollectFormCollapse";
import CollectFormParentView from "../../common/form-components/CollectFormParentView";
import CollectFormPdfAnnotatorClaim from "../../common/form-components/CollectFormPdfAnnotatorClaim";
import FormText from "../../common/form-components/FormText";
import CollectFormForEachComponent from "../../common/form-components/CollectFormForEachComponent";
import FormImageAnnotator from "../../common/form-components/FormImageAnnotator";
import { toJS } from "mobx";

const FormGeneratorViewRenderStruct = props => {
  const currentPlatform = localStorage.getItem("platform");

  const [hints, setHints] = useState([]);

  const renderStructure = structure => {
    let rows = [];
    forEach(structure.rows, row => {
      rows.push(renderRow(row));
    });
    rows.push(<Row style={{ height: 60 }} />);
    return rows;
  };

  const renderRow = row => {
    let cols = [];
    forEach(row.cols, col => {
      prepareCol(col);
      if (col.conditionalLogicType) {
        if (
          col.conditionalLogicType === "show" &&
          col.conditionaLogicJql &&
          JqlStore.compare(CollectSubmissionStore.values, JSON.parse(col.conditionaLogicJql))
        )
          cols.push(renderCol(col, row.cols.length));
        if (
          col.conditionalLogicType === "hide" &&
          col.conditionaLogicJql &&
          !JqlStore.compare(CollectSubmissionStore.values, JSON.parse(col.conditionaLogicJql))
        )
          cols.push(renderCol(col, row.cols.length));
      } else {
        cols.push(renderCol(col, row.cols.length));
      }
    });

    if (cols.length === 0) return <Row style={{ height: 0 }} />;

    return (
      <Row
        key={row.cols[0].id}
        gutter={row.gutter ? row.gutter : 0}
        style={
          currentPlatform === "mobile"
            ? { ...row.style, marginTop: 10, textAlign: "left" }
            : { ...row.style, marginTop: 10, textAlign: "center" }
        }
      >
        {cols}
      </Row>
    );
  };

  const prepareCol = col => {
    if (col.hints) {
      col.hints
        .filter(h => h.config)
        .forEach(h => {
          h.displayHint = JqlStore.compare(CollectSubmissionStore.values, JSON.parse(h.config));
        });
    }

    if (col.dynamicHintAssetDefinitionFieldId) {
      var selectId = col.dynamicHintAssetDefinitionFieldId.split("_")[0];
      var fieldId = col.dynamicHintAssetDefinitionFieldId.split("_")[1];
      //
      var select = props.store.values.find(x => x.id === selectId);
      var selectDef = props.store.getField(selectId, props.store.structure);
      if (!select || !select.value || select.value.length === 0) return;

      if (typeof select.value === "object") select.value = select.value.key ? select.value.key : select.value.value;
      var containerId = select.value.split("_")[2];

      var dynamicHintAssetDefinitionId = selectDef.externalItemsSourceConfig.assetDefinition.id;
      if (!CollectSubmissionStore.externalDataValues[dynamicHintAssetDefinitionId]) return;

      var hintValue = CollectSubmissionStore.externalDataValues[dynamicHintAssetDefinitionId].find(
        x => x.id.includes(containerId) && x.id.includes(fieldId)
      );
      if (hintValue && !col.hints.find(x => x.id == hintValue.id)) {
        col.hints = [{ id: hintValue.id, value: hintValue.label, displayHint: true }];
      }
    }
  };

  const renderCol = (col, rowColsLength) => {
    return (
      <Col span={currentPlatform === "mobile" ? 24 : col.span} style={col.style}>
        {/* onClick={() => col.displayHint = true} */}
        {renderObject(col, rowColsLength)}

        {col.type !== "switch" &&
          col.hints &&
          col.hints
            .filter(x => x.alwaysVisible || col.displayHint || x.displayHint)
            .map(hint => (
              <h5>
                <b>{hint.value}</b>
              </h5>
            ))}
      </Col>
    );
  };

  const renderObject = (object, rowColsLength) => {
    const value = props.store.getValue(object.id);
    const disabled =
      !props.state.canModify ||
      object.readOnly ||
      CollectSubmissionStore.disabledInputs.filter(x => x.fieldId === object.id).length > 0;

    object.isSubmission = true;

    switch (object.type) {
      case "input":
        return currentPlatform === "mobile" ? (
          <FormInputMobile
            {...object}
            disabled={disabled}
            storeValue={value}
            updateValue={props.store.updateValue}
            dupes={props.state.dupes}
            t={props.t}
            setValidityStatus={props.store.setValidityStatus}
          />
        ) : (
          <FormInput
            {...object}
            disabled={disabled}
            storeValue={value}
            updateValue={props.store.updateValue}
            dupes={props.state.dupes}
            t={props.t}
            setValidityStatus={props.store.setValidityStatus}
          />
        );

      case "text":
        return <FormText {...object} storeValue={value} />;
      case "numericInput":
        return (
          <FormNumericInput
            {...object}
            disabled={disabled}
            storeValue={value}
            updateValue={props.store.updateValue}
            dupes={props.state.dupes}
            t={props.t}
            setValidityStatus={props.store.setValidityStatus}
          />
        );
      case "textarea":
        return (
          <FormTextArea {...object} disabled={disabled} storeValue={value} updateValue={props.store.updateValue} />
        );
      case "select":
        return (
          <FormSelect
            {...object}
            disabled={disabled}
            storeValue={value}
            updateValue={props.store.updateValue}
            setValidityStatus={props.store.setValidityStatus}
          />
        );
      case "checkbox":
        return currentPlatform === "mobile" ? (
          <FormCheckboxMobile
            {...object}
            disabled={disabled}
            storeValue={value}
            updateValue={props.store.updateValue}
          />
        ) : (
          <FormCheckbox {...object} disabled={disabled} storeValue={value} updateValue={props.store.updateValue} />
        );
      case "checkbox-group":
        return currentPlatform === "mobile" ? (
          <FormCheckboxGroupMobile
            {...object}
            disabled={disabled}
            storeValue={value}
            updateValue={props.store.updateValue}
          />
        ) : (
          <FormCheckboxGroup {...object} disabled={disabled} storeValue={value} updateValue={props.store.updateValue} />
        );
      case "radio":
        return <FormRadio {...object} disabled={disabled} storeValue={value} updateValue={props.store.updateValue} />;
      case "file-upload": // TODO: disabled={!props.state.canModify} - make this other way?
        return currentPlatform === "mobile" ? (
          <FormFileUploadMobile
            {...object}
            viewOnly={props.state.viewOnly}
            disabled={disabled}
            submissionId={props.store.submissionId}
            canUpload={true}
            updateValue={props.store.updateValue}
            values={props.store.GetValues(object.id)}
            save={props.store.update}
          />
        ) : (
          <FormFileUpload
            {...object}
            viewOnly={props.state.viewOnly}
            disabled={disabled}
            submissionId={props.store.submissionId}
            canUpload={true}
            updateValue={props.store.updateValue}
            values={props.store.GetValues(object.id)}
            save={props.store.update}
          />
        );
      case "dxf-editor": // TODO: disabled={!props.state.canModify} - make this other way?
        return (
          <CollectFormDxfEditor
            {...object}
            disabled={props.state.viewOnly}
            submissionId={props.store.submissionId}
            canUpload={true}
            updateValue={props.store.updateValue}
            values={props.store.GetValues(object.id)}
            save={props.store.update}
          />
        );
      case "image-annotator":
        return (
          <FormImageAnnotator
            {...object}
            viewOnly={false}
            disabled={disabled}
            submissionId={props.store.submissionId}
            updateValue={props.store.updateValue}
            values={props.store.GetValues(object.id)}
            save={props.store.update}
            storeValue={value}
          />
        );
      case "camera":
        return (
          <FormCamera
            {...object}
            disabled={disabled}
            submissionId={props.store.submissionId}
            canUpload={true}
            updateValue={props.store.updateValue}
            values={props.store.GetValues(object.id)}
            save={props.store.update}
          />
        );
      case "datetime":
        return (
          <FormDateTime {...object} disabled={disabled} storeValue={value} updateValue={props.store.updateValue} />
        );
      case "open-layers-map":
        return (
          <FormOpenLayersMap {...object} disabled={disabled} value={value} updateValue={props.store.updateValue} />
        );
      case "switch":
        return (
          <FormSwitch
            rowColsLength={rowColsLength}
            {...object}
            disabled={disabled}
            storeValue={value}
            updateValue={props.store.updateValue}
          />
        );
      case "rate":
        return currentPlatform === "mobile" ? (
          <FormStarRateMobile
            {...object}
            disabled={disabled}
            storeValue={value}
            updateValue={props.store.updateValue}
          />
        ) : (
          <FormStarRate {...object} disabled={disabled} storeValue={value} updateValue={props.store.updateValue} />
        );
      case "title":
        return <FormTitle {...object} value={value} updateValue={props.store.updateValue} />;
      case "form-sign":
        return (
          <FormSign
            {...object}
            value={value}
            store={props.store}
            updateValue={props.store.updateValue}
            disabled={disabled}
          />
        );
      case "table":
        return (
          <CollectFormTable
            {...object}
            value={value}
            updateValue={props.store.updateValue}
            disabled={disabled}
            submissionId={props.store.submissionId}
          />
        );
      case "collapse":
        return (
          <CollectFormCollapse {...object} value={value} updateValue={props.store.updateValue} parentProps={props} />
        );
      case "loop":
        return (
          <CollectFormForEachComponent
            {...object}
            value={value}
            updateValue={props.store.updateValue}
            parentProps={props}
            store={props.store}
          />
        );
      case "pdf-annotator-selected-claim":
        return (
          <CollectFormPdfAnnotatorClaim
            {...object}
            value={value}
            updateValue={props.store.updateValue}
            parentProps={props}
          />
        );
      case "parent-view":
        return (
          <CollectFormParentView
            {...object}
            disabled={disabled}
            submissionId={props.store.parentSubmission?.id}
            canModify={false}
            isParent={true}
          />
        );
      default:
        return !object.type ? <span /> : <div>Undefined</div>;
    }
  };
  return renderStructure(props.structure);
};

export default withTranslation()(observer(FormGeneratorViewRenderStruct));
