import { makeAutoObservable } from "mobx";
import { Text } from "pixi.js";
import DxfEditorStore from "../DxfEditorStore";

class DxfEditorDrawMTextStore {
  constructor() {
    makeAutoObservable(this);
  }

  parseFromDxf = entity => {
    var object = {
      hexColor: entity.hexColor,
      content: entity.text,
      x: entity.position.x,
      y: entity.position.y,
      fontSize: entity.height
    };
    return object;
  };

  drawText = entity => {
    if (!entity.hexColor) entity.hexColor = "#ffffff";
    entity.hexColor = entity.hexColor.replace("#", "0x");

    let text = new Text(entity.content, {
      fontSize: entity.fontSize,
      fill: entity.hexColor
    });
    text.x = entity.x;
    text.y = -entity.y;

    return text;
  };

  exportToDxf = (store, results, entity) => {
    results = store.exportInsert(results, 0, "MTEXT");
    if (entity.handle) results = store.exportInsert(results, 5, entity.handle);
    if (entity.ownerHandle) results = store.exportInsert(results, 330, entity.ownerHandle);
    results = store.exportInsert(results, 100, "AcDbEntity");
    if (entity.layer) results = store.exportInsert(results, 8, entity.layer);
    if (entity.lineType) results = store.exportInsert(results, 6, entity.lineType);
    if (entity.colorIndex) results = store.exportInsert(results, 62, entity.colorIndex);
    if (entity.lineweight) results = store.exportInsert(results, 370, entity.lineweight);
    results = store.exportInsert(results, 100, "AcDbMText");
    if (entity.position) {
      results = store.exportInsert(results, 10, entity.position.x);
      results = store.exportInsert(results, 20, entity.position.y);
      results = store.exportInsert(results, 30, entity.position.z);
    }
    if (entity.height) results = store.exportInsert(results, 40, entity.height);
    if (entity.width) results = store.exportInsert(results, 41, entity.width);
    if (entity.attachmentPoint) results = store.exportInsert(results, 71, entity.attachmentPoint);
    if (entity.drawingDirection) results = store.exportInsert(results, 72, entity.drawingDirection);
    if (entity.text) results = store.exportInsert(results, 1, entity.text);
    return results;
  };
}
export default new DxfEditorDrawMTextStore();
