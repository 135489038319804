import React, { useEffect } from 'react';
import { Rate, Divider } from 'antd-mobile'
import { Row } from 'antd';


const FormStarRateMobile = (props) => {

    useEffect(() => {
        if (props.storeValue === undefined && props.value !== undefined && props.updateValue) props.updateValue(props.id, props.value)
    }, [props.value])

    return (
        <Row align='middle' justify='space-between' style={{ width: "90%", marginLeft: 20 }}>
            <p style={{ marginRight: 10, paddingTop: 20 }}>{<>{props.label} {props.isRequired && <span style={{ color: 'red', marginLeft: 2 }}>(*)</span>}</>}</p>
            <Rate
                onChange={(newValue) => props.updateValue && props.updateValue(props.id, newValue)}
                // value={props.value}
                // defaultValue={props.defaultValue}
                value={props.storeValue !== undefined ? props.storeValue : props.value}
                readOnly={props.disabled}
            />
        </Row>
    )
}

export default FormStarRateMobile;
