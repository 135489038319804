import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useDrop } from "react-dnd";
import { withTranslation } from "react-i18next";
import { themeConfig } from "../../../../Common/Layout/config";

const FormBuilderDropZone = ({ parentId, rowIndex, colIndex, text, t }) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "input",
    drop: () => ({ rowIndex, colIndex, parentId }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));
  const { currentTheme } = themeConfig();
  const isActive = canDrop && isOver;
  let backgroundColor = currentTheme === "light" ? "#EBEBEB" : "#141414";
  if (isActive) {
    backgroundColor = "#DEB100";
  } else if (canDrop) {
    backgroundColor = "#FFCC00";
  }

  if (!text) text = t("collect.noInputAssigned");
  return (
    <div ref={drop} role={"Dustbin"} style={{ border: "1px dotted grey", padding: 5, marginTop: 5, backgroundColor }}>
      {text} - {parentId} / {rowIndex} / {colIndex}
      <br />
      {t("collect.dragDrop")}
    </div>
  );
};
export default withTranslation()(observer(FormBuilderDropZone));