import { makeAutoObservable, toJS } from "mobx";
import { Application, Graphics, Text, Container, TilingSprite, Texture } from "pixi.js";
import { Stage, Layer } from "@pixi/layers";
import { Viewport } from "pixi-viewport";
import DxfParser from "dxf-parser";
import DxfEditorDrawLineStore from "./components/DxfEditorDrawLine";
import DxfEditorDrawTextStore from "./components/DxfEditorDrawText";
import DxfEditorDrawRectangleStore from "./components/DxfEditorDrawRectangle";
import DxfEditorDrawCircleStore from "./components/DxfEditorDrawCircle";
import dxfColors from "./components/DxfEditorColors.json";
import DxfEditorDrawRectangle from "./components/DxfEditorDrawRectangle";
import DxfEditorDrawMTextStore from "./components/DxfEditorDrawMText";
import DxfEditorDrawArcStore from "./components/DxfEditorDrawArc";

class DxfEditorStore {
  isLoading = true;

  app = undefined;
  appLayers = [];
  __instance;
  layers = [];

  divId;

  viewportRef = undefined;
  editLayer = undefined;
  gridRef = undefined;

  mode = "view";
  backgroundColor = "#000000";
  gridOn = true;
  editShapeSelected = undefined;

  // Drop and draw
  initPointer = undefined;
  isMouseButtonDown = false;

  dxfJson = undefined;

  annotations = [];
  actionsHistory = [];

  dxfFileContent = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  loadDxfFile = (fileContent, divId) => {
    this.divId = divId;
    const parser = new DxfParser();
    let dxf = undefined;
    try {
      this.dxfFileContent = fileContent;
      dxf = parser.parseSync(fileContent);
      this.dxfJson = dxf;
    } catch (err) {
      return console.error(err.stack);
    }

    // this.initEditor(divId);
    // this.drawGrid();
    // this.initViewport();

    this.layers = _.orderBy(
      _.keys(dxf.tables.layer.layers).map(key => {
        var item = dxf.tables.layer.layers[key];
        var rgbColors = this.decimalToRgb(item.color);
        var hexColor = this.rgbToHex(rgbColors);
        if (hexColor === "#ffffff") {
          hexColor = "#000000";
        }
        return { ...item, rgbColor: rgbColors, hexColor, visible: true };
      }),
      x => x.name,
      "asc"
    );

    // this.drawEntities();
    this.isLoading = false;
  };

  download = (filename, text) => {
    var element = document.createElement("a");
    element.setAttribute("href", "data:application/octet-stream;charset=utf-8," + encodeURIComponent(text));
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  drawEntities = () => {
    for (var i = this.viewportRef.children.length - 1; i >= 0; i--)
      this.viewportRef.removeChild(this.viewportRef.children[i]);

    var visibleLayers = this.layers.filter(x => x.visible === true).map(x => x.name);

    this.dxfJson.entities.forEach(entity => {
      if (visibleLayers.includes(entity.layer)) {
        var layer = this.layers.find(x => x.name === entity.layer);
        if (layer === undefined)
          entity.hexColor = dxfColors.find(x => x.aci === entity.colorIndex - 1).hex ?? "#ffffff";
        else entity.hexColor = dxfColors.find(x => x.aci === layer.colorIndex).hex ?? "#ffffff";

        if (entity.type === "LINE")
          DxfEditorDrawLineStore.drawLine(
            this,
            entity.vertices[0].x,
            entity.vertices[0].y,
            entity.vertices[1].x,
            entity.vertices[1].y,
            entity.hexColor
          );

        if (entity.type === "CIRCLE") {
          this.drawCircle(entity.center.x, entity.center.y, entity.radius, entity.hexColor);
        }

        if (entity.type === "ARC") {
          const arc = DxfEditorDrawArcStore.draw(DxfEditorDrawArcStore.parseFromDxf(entity));
          this.viewportRef.addChild(arc);
        }

        if (entity.type === "LWPOLYLINE") {
          const shape = DxfEditorDrawRectangle.draw(this, DxfEditorDrawRectangle.parseFromDxf(entity));
          this.viewportRef.addChild(shape);
        }

        if (entity.type === "TEXT") {
          DxfEditorDrawTextStore.drawText(
            this,
            entity.text,
            entity.textHeight,
            entity.startPoint.x,
            entity.startPoint.y,
            entity.hexColor
          );
        }
        if (entity.type === "MTEXT") {
          const mtext = DxfEditorDrawMTextStore.drawText(DxfEditorDrawMTextStore.parseFromDxf(entity));
          this.viewportRef.addChild(mtext);
        }
      }
    });
  };

  toggleEditShape = type => {
    if (this.editShapeSelected === type) this.editShapeSelected = undefined;
    else this.editShapeSelected = type;
  };

  toggleLayerVisibility = name => {
    this.layers.find(x => x.name === name).visible = !this.layers.find(x => x.name === name).visible;
    this.drawEntities();
  };

  toggleGridOn = () => {
    if (!this.gridOn) {
      this.gridOn = true;
      this.drawGrid();
    } else {
      this.gridOn = false;
      this.gridRef.destroy();
      this.gridRef = undefined;
    }
  };

  drawCircle = (x, y, radius, hexColor) => {
    if (!hexColor) hexColor = "#ffffff";
    hexColor = hexColor.replace("#", "0x");

    const shape = new Graphics();
    // shape.beginFill(hexColor);
    shape.lineStyle(2, hexColor);
    shape.drawCircle(x, -y, radius);
    shape.endFill();
    this.viewportRef.addChild(shape);
  };

  decimalToRgb = color => {
    var r = Math.floor(color / (256 * 256));
    var g = Math.floor(color / 256) % 256;
    var b = color % 256;
    return [r, g, b];
  };

  rgbToHex = rgbArray => {
    return `#${this.componentToHex(rgbArray[0])}${this.componentToHex(rgbArray[1])}${this.componentToHex(rgbArray[2])}`;
  };

  decimalToHex = color => {
    var rgbColors = this.decimalToRgb(color);
    return this.rgbToHex(rgbColors);
  };

  hexToDecimal = color => {
    return parseInt(color.replace("#", "0x"), 16);
  };

  componentToHex = c => {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  };

  initEditor = divId => {
    const container = document.getElementById(divId);
    if (this.app) {
      container.removeChild(this.app.view);
      this.app.destroy();
    }

    this.app = new Application({
      width: window.innerWidth - 95,
      height: window.innerHeight - 280,
      transparent: true,
      backgroundColor: this.backgroundColor,
      autoDensity: true,
      antialias: false,
      resolution: window.devicePixelRatio
    });

    container.appendChild(this.app.view);

    this.app.stage = new Stage();
  };

  drawGrid = () => {
    const canvas = document.createElement("canvas");
    canvas.width = 28;
    canvas.height = 28;

    const context = canvas.getContext("2d");
    context.beginPath();
    context.moveTo(28, 0);
    context.lineTo(0, 0);
    context.lineTo(0, 28);
    context.lineWidth = 0.2;
    context.strokeStyle = "#cccccc";
    context.stroke();

    const tileTexture = Texture.from(canvas);
    this.gridRef = new TilingSprite(tileTexture, innerWidth, innerHeight);

    this.app.stage.addChild(this.gridRef);
  };

  initViewport = () => {
    this.viewportRef = new Viewport({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      worldWidth: 2000,
      worldHeight: 2000
      // interaction: this.app.renderer.plugins.interaction // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
    });

    this.viewportRef.on("mousemove", event => this.onMouseMove(event));
    this.viewportRef.on("mousedown", event => this.onMouseDown(event));
    this.viewportRef.on("mouseup", event => this.onMouseUp(event));

    this.viewportRef
      .drag({ mouseButtons: "middle" }) // { mouseButtons: 'middle' }
      .pinch()
      .wheel()
      .decelerate();

    this.app.stage.addChild(this.viewportRef);
  };

  changeMode = type => {
    this.mode = type;
    if (this.mode === "view") this.editShapeSelected = undefined;
    // this.viewportRef.pause = type === 'view' ? false : true;
  };

  changeBackgroundColor = (color, divId) => {
    this.backgroundColor = color;
    this.app.renderer.backgroundColor = this.hexToDecimal(color);
    this.layers = this.layers.map(layer => {
      let hexColor = layer.hexColor;
      let colorIndex = layer.colorIndex;

      if (layer.hexColor.toLowerCase() === "#ffffff" && color === "#ffffff") {
        hexColor = "#000000";
        colorIndex = 0;
      } else if (layer.hexColor.toLowerCase() === "#111111" && color === "#111111") {
        hexColor = "#ffffff";
        colorIndex = 7;
      }
      return {
        ...layer,
        hexColor,
        colorIndex
      };
    });
    this.drawEntities();
  };

  deleteAnnotation = item => {
    if (item.type === "line") DxfEditorDrawLineStore.delete(this, item);
    if (item.type === "text") DxfEditorDrawTextStore.delete(this, item);
    if (item.type === "rectangle") DxfEditorDrawRectangleStore.delete(this, item);
    if (item.type === "circle") DxfEditorDrawCircleStore.delete(this, item);
  };

  onMouseMove = e => {
    if (!this.isMouseButtonDown) {
      return;
    }
    if (this.mode !== "edit") return;
    if (this.editShapeSelected === "line") DxfEditorDrawLineStore.onMouseMove(this, e);
    if (this.editShapeSelected === "text") DxfEditorDrawTextStore.onMouseMove(this, e);
    if (this.editShapeSelected === "rectangle") DxfEditorDrawRectangleStore.onMouseMove(this, e);
    if (this.editShapeSelected === "circle") DxfEditorDrawCircleStore.onMouseMove(this, e);
  };

  onMouseDown = e => {
    if (this.mode !== "edit") return;
    if (this.editShapeSelected === "line") DxfEditorDrawLineStore.onMouseDown(this, e);
    if (this.editShapeSelected === "text") DxfEditorDrawTextStore.onMouseDown(this, e);
    if (this.editShapeSelected === "rectangle") DxfEditorDrawRectangleStore.onMouseDown(this, e);
    if (this.editShapeSelected === "circle") DxfEditorDrawCircleStore.onMouseDown(this, e);
  };

  onMouseUp = e => {
    if (!this.isMouseButtonDown) return;
    if (this.editShapeSelected === "line") DxfEditorDrawLineStore.onMouseUp(this, e);
    if (this.editShapeSelected === "text") DxfEditorDrawTextStore.onMouseUp(this, e);
    if (this.editShapeSelected === "rectangle") DxfEditorDrawRectangleStore.onMouseUp(this, e);
    if (this.editShapeSelected === "circle") DxfEditorDrawCircleStore.onMouseUp(this, e);

    this.isMouseButtonDown = false;
  };

  exportLine = (results, entity) => {
    results = this.exportInsert(results, 0, "LINE");
    if (entity.handle) results = this.exportInsert(results, 5, entity.handle);
    if (entity.ownerHandle) results = this.exportInsert(results, 330, entity.ownerHandle);
    results = this.exportInsert(results, 100, "AcDbEntity");
    if (entity.layer) results = this.exportInsert(results, 8, entity.layer);
    if (entity.colorIndex) results = this.exportInsert(results, 62, entity.colorIndex);
    results = this.exportInsert(results, 100, "AcDbLine");
    if (entity.width) this.exportInsert(results, 39, entity.width);
    entity.vertices.forEach((pos, index) => {
      results += `1${index}\n${pos.x || 0}\n2${index}\n${pos.y || 0}\n3${index}\n${pos.z || 0}\n`;
    });
    return results;
  };

  exportData = () => {
    // = = = Replace entries
    var results = "ENTITIES\n";
    this.dxfJson.entities.forEach(entity => {
      if (entity.type === "LINE") results = this.exportLine(results, entity);
      if (entity.type === "TEXT") results = DxfEditorDrawTextStore.exportToDxf(this, results, entity);
      if (entity.type === "MTEXT") results = DxfEditorDrawMTextStore.exportToDxf(this, results, entity);
      if (entity.type === "ARC") results = DxfEditorDrawArcStore.exportToDxf(this, results, entity);
      if (entity.type === "CIRCLE") results = DxfEditorDrawCircleStore.exportToDxf(this, results, entity);
      if (entity.type === "LWPOLYLINE") results = DxfEditorDrawRectangleStore.exportToDxf(this, results, entity);
    });

    this.annotations.forEach(entity => {
      if (entity.type === "line") results = this.exportLine(results, entity.exportConfig);
      if (entity.type === "text") results = DxfEditorDrawTextStore.exportToDxf(this, results, entity.exportConfig);
      if (entity.type === "circle") results = DxfEditorDrawCircleStore.exportToDxf(this, results, entity.exportConfig);
      if (entity.type === "rectangle")
        results = DxfEditorDrawRectangleStore.exportToDxf(this, results, entity.exportConfig);
    });

    results += "0\nENDSEC";

    var startIndex = this.dxfFileContent.indexOf("ENTITIES");
    var endIndex = this.dxfFileContent.indexOf("ENDSEC", startIndex);

    let data = this.dxfFileContent.replace(this.dxfFileContent.substring(startIndex, endIndex + 6), results);
    // = = = Replace layers
    if (this.layers.filter(x => x.name === "beawre_annotation").length === 0) {
      let startIndex = data.indexOf("\nLAYER");
      let endIndex = data.indexOf("\nENDTAB", startIndex);

      let layers = data.substring(startIndex, endIndex);
      layers += "\nLAYER\n";
      layers += "100\nAcDbSymbolTableRecord\n100\nAcDbLayerTableRecord\n";
      layers += "2\nbeawre_annotation\n70\n0\n";
      layers += "62\n40\n6\nCONTINUOUS\n370\n0\n390\nF\n0";

      data = data.replace(data.substring(startIndex, endIndex), layers);
    }
    this.download("test.dxf", data);

    return data;
  };

  exportInsert = (result, code, value) => result + `${code}\n${value}\n`;
}
export default DxfEditorStore;
