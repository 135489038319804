import React from "react";

import { Button, Col, Collapse, Input, List, Row, Switch } from "antd";
import { NodeExpandOutlined } from "@ant-design/icons";

import _ from "lodash";
import { withTranslation } from "react-i18next";
import FormBuilderFormZone from "../../MyForms/form/FormBuilderFormZone";
import FormSubmissionViewRenderStruct from "../../Submissions/view/FormSubmissionViewRenderStruct";
import { observer } from "mobx-react-lite";
import { DeleteOutline } from "antd-mobile-icons";
import FormBuilderStore from "../../MyForms/FormBuilderStore";
import { v4 as uuidv4 } from "uuid";

const CollectFormCollapse = props => {
  return (
    <>
      {props.isSidebarElement && (
        <>
          <div style={{ textAlign: "center" }}>
            <NodeExpandOutlined />
          </div>
        </>
      )}

      {props.isBuilder && (
        <>
          <Collapse
            defaultActiveKey={props.items.filter(x => x.expandedByDefault).map(x => x.id)}
            items={props.items.map(item => {
              return {
                key: item.id,
                label: (
                  <h4
                    style={{
                      textTransform: "uppercase",
                      fontSize: "0.8em",
                      color: "#90a8be",
                      fontWeight: "bolder",
                      textAlign: "left"
                    }}
                  >
                    {item.value}
                  </h4>
                ),
                children: <FormBuilderFormZone {...item} />
              };
            })}
          />
        </>
      )}

      {props.isSubmission && (
        <>
          <Collapse
            defaultActiveKey={props.items.filter(x => x.expandedByDefault).map(x => x.id)}
            items={props.items.map(item => ({
              key: item.id,
              label: (
                <h4
                  style={{
                    textTransform: "uppercase",
                    fontSize: "0.8em",
                    color: "#90a8be",
                    fontWeight: "bolder",
                    textAlign: "left"
                  }}
                >
                  {item.value}
                </h4>
              ),
              children: <FormSubmissionViewRenderStruct {...props.parentProps} structure={item.structure} />
            }))}
          />
        </>
      )}
    </>
  );
};

export const CollectFormCollapseSettings = observer(props => {
  const inputStyle = { marginLeft: 5, width: "90%" };

  return (
    <>
      <Row gutter={4} style={{ marginTop: 5 }}>
        <Col span={6} style={{ textAlign: "right" }}>
          {props.t("collect.areas")}
        </Col>
        <Col span={18}>
          <List
            size="small"
            footer={
              <div style={{ textAlign: "center" }}>
                <Button
                  type="default"
                  disabled={props.disabled}
                  onClick={() => {
                    FormBuilderStore.modifyInputColumn.items = [
                      ...FormBuilderStore.modifyInputColumn.items,
                      { id: uuidv4(), structure: { rows: [] } }
                    ];
                  }}
                  size="small"
                >
                  Add new area
                </Button>
              </div>
            }
            bordered
            dataSource={FormBuilderStore.modifyInputColumn.items || []}
            renderItem={item => (
              <List.Item
                actions={[
                  <Switch
                    checkedChildren="Expanded"
                    unCheckedChildren="Folded"
                    style={{ marginLeft: 5 }}
                    checked={item.expandedByDefault}
                    onChange={checked => {
                      item.expandedByDefault = checked;
                      FormBuilderStore.modifyInputColumn.items.find(x => x.id === item.id).expandedByDefault = checked;
                    }}
                  />,
                  <DeleteOutline
                    onClick={() =>
                      (FormBuilderStore.modifyInputColumn.items = [
                        ...FormBuilderStore.modifyInputColumn.items.filter(x => x.id !== item.id)
                      ])
                    }
                  />
                ]}
              >
                <Input
                  size="small"
                  style={inputStyle}
                  defaultValue={item.value}
                  onBlur={() =>
                    (FormBuilderStore.modifyInputColumn.items = [...FormBuilderStore.modifyInputColumn.items])
                  }
                  onChange={e => {
                    item.value = e.target.value;
                    FormBuilderStore.modifyInputColumn.items.find(x => x.id === item.id).value = e.target.value;
                  }}
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
});

export default withTranslation()(CollectFormCollapse);
