import React from 'react';

const FormTitle = (props) => {
  return (
    <h4
      style={{
        textTransform: 'uppercase',
        fontSize: '0.8em',
        color: '#90a8be',
        fontWeight: 'bolder',
        textAlign: 'left',
      }}
    >
      {props.label}
    </h4>
  );
};

export default FormTitle;
